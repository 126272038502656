/**
 * 臨床経過図記述生成
 */

import {
  PMedicine,
  PTest,
  PEvent,
  FindingsText,
  Chart,
  Line,
} from "../app/appSlice";
import dayjs from "dayjs";
import lodash from "lodash";
import { DateMatcher, NengoEntry, nengoTable } from "./dateMatcher";
require("dayjs/locale/ja");
const arraySupport = require("dayjs/plugin/arraySupport");

interface Stage {
  /**
   * 絶対日付
   */
  datetime: dayjs.Dayjs;

  /**
   * 検出された薬剤情報の配列
   */
  medicines: PMedicine[];

  /**
   * 検出された検査情報の配列
   */
  tests: PTest[];

  /**
   * 検出されたイベント情報の配列
   */
  events: PEvent[];
}

class ChartGenerator {
  constructor() {
    dayjs.locale("ja");
    dayjs.extend(arraySupport);
  }

  private YM_PATTERNS: RegExp[] = [
    RegExp("^(\\d{4})/(\\d{1,2})$"),
    RegExp("^(\\d{4})-(\\d{1,2})$"),
    RegExp("^(\\d{4})年(\\d{1,2})月?$"),
  ];

  private YMD_PATTERNS: RegExp[] = [
    RegExp("^(\\d{4})/(\\d{1,2})/(\\d{1,2})$"),
    RegExp("^(\\d{4})-(\\d{1,2})-(\\d{1,2})$"),
    RegExp("^(\\d{4})年(\\d{1,2})月(\\d{1,2})日?$"),
  ];

  private MD_PATTERNS: RegExp[] = [
    RegExp("^(\\d{1,2})/(\\d{1,2})$"),
    RegExp("^(\\d{1,2})-(\\d{1,2})$"),
    RegExp("^(\\d{1,2})月(\\d{1,2})日?$"),
  ];

  private reTm = DateMatcher.timestampMatchReg;

  /**
   *
   * @param time パース対象のタイムスタンプ
   * @param patterns 解析パターン配列
   * @return マッチするパターンがあった場合にはマッチした結果。そうでなければnull
   */
  private parseTimeByPatterns(
    time: string,
    patterns: RegExp[]
  ): number[] | null {
    for (const pattern of patterns) {
      const match = time.match(pattern);
      if (match) {
        // 部分一致配列を返す
        return match.slice(1).map((t) => parseInt(t));
      }
    }
    return null;
  }

  private makeDayjs = (
    y: number,
    m: number,
    d: number,
    nengo: string | null
  ): dayjs.Dayjs | null => {
    const ts = dayjs(new Date(y, m - 1, d));
    const matched = this.parseTimeByPatterns(
      ts.format("YYYY/MM/DD"),
      this.YMD_PATTERNS
    );
    if (nengo) {
      const nengoEntry = nengoTable[nengo];
      const entryDate =
        ("0000" + y).slice(-4) + ("00" + m).slice(-2) + ("00" + d).slice(-2);
      const fromDate =
        ("0000" + nengoEntry.from[0]).slice(-4) +
        ("00" + nengoEntry.from[1]).slice(-2) +
        ("00" + nengoEntry.from[2]).slice(-2);
      const toDate =
        ("0000" + nengoEntry.to[0]).slice(-4) +
        ("00" + nengoEntry.to[1]).slice(-2) +
        ("00" + nengoEntry.to[2]).slice(-2);
      if (entryDate < fromDate) return null;
      if (toDate < entryDate) return null;
      if (matched && matched[0] === y && matched[1] === m && matched[2] === d) {
        return ts;
      } else {
        return null;
      }
    } else if (
      matched &&
      matched[0] === y &&
      matched[1] === m &&
      matched[2] === d
    ) {
      return ts;
    } else {
      return null;
    }
  };

  /**
   * タイムスタンプをパースする
   * @param prevTime 直近の絶対時間
   * @param time パース対象のタイムスタンプ
   * @returns パース結果の絶対時間。パースできない場合はnull
   */
  private parseTime(
    prevTime: dayjs.Dayjs | null,
    prevNengo: NengoEntry | null,
    time: string,
    text: string
  ): dayjs.Dayjs | null {
    // 絶対日付としてパースする
    //TODO: 複数日付対応必要
    let matched = this.parseTimeByPatterns(time, this.YMD_PATTERNS);
    let ts: dayjs.Dayjs | null = null;
    const nengoEntry =
      nengoTable[text.trim()[0]] ||
      nengoTable[text.trim()[0] + text.trim()[1]] ||
      null;
    if (matched) {
      // 年月日として認識
      ts = this.makeDayjs(
        matched[0],
        matched[1],
        matched[2],
        nengoEntry ? nengoEntry.nengo : null
      );
    } else {
      // 日が省略された年月としてパースする
      matched = this.parseTimeByPatterns(time, this.YM_PATTERNS);
      if (matched) {
        // 年月の1日として認識
        ts = this.makeDayjs(
          matched[0],
          matched[1],
          1,
          nengoEntry ? nengoEntry.nengo : null
        );
      } else {
        if (prevTime !== null) {
          // 直近の絶対時間がある場合に限り月日としてパースする
          matched = this.parseTimeByPatterns(time, this.MD_PATTERNS);
          if (matched) {
            // 直近の年 + 月日として認識
            ts = this.makeDayjs(
              prevTime.year(),
              matched[0],
              matched[1],
              prevNengo?.nengo || null
            );
          }
        }
      }
    }
    if (ts !== null && ts.isValid()) {
      return ts;
    } else {
      return null;
    }
  }

  /**
   * 絶対日付の照準に並ぶ中間構造に変換する
   * @param findingsText 解析された所見テキスト
   * @return 日付順の中間構造。変換対象が存在しなければ空配列。
   */
  private transform(findingsText: FindingsText, chart: Chart): Stage[] {
    const stages: Stage[] = [];
    let prevTime: dayjs.Dayjs | null = null;
    let prevNengo: NengoEntry | null = null;
    if (findingsText.text) {
      for (const block of findingsText.text.blocks) {
        // Note: parsedに該当キーがない行は継続行として前の行と結合して処理されている
        if (findingsText.parsed && findingsText.parsed[block.key]) {
          const parsedBlock = findingsText.parsed[block.key];
          //エラーメッセージをchartに格納する
          parsedBlock.errormessage?.forEach((e) => {
            chart.error_message.push(e);
          });
          for (const [i, time] of parsedBlock.time.entries()) {
            const absTime = this.parseTime(
              prevTime,
              prevNengo,
              time,
              parsedBlock.text
            );
            if (absTime) {
              stages.push({
                datetime: absTime,
                medicines: parsedBlock.medicines[i],
                tests: parsedBlock.tests[i],
                events: parsedBlock.events[i],
              });
              prevNengo =
                nengoTable[parsedBlock.text.trim()[0]] ||
                nengoTable[
                  parsedBlock.text.trim()[0] + parsedBlock.text.trim()[1]
                ] ||
                null;
              prevTime = absTime;
              //投薬終了日対応
              let timeUntil = parsedBlock.timeUntil[i];
              if (/^\d{1,2}$/.test(timeUntil))
                timeUntil = `${absTime.month() + 1}/${timeUntil}`;
              const absEndTime = this.parseTime(
                prevTime,
                prevNengo,
                timeUntil,
                parsedBlock.text
              );
              if (absEndTime) {
                stages.push({
                  datetime: absEndTime.add(1, "day"),
                  medicines: parsedBlock.medicines[i].map((medicine) => ({
                    name: medicine.name,
                    label: medicine.label,
                    amount: "0",
                    unitedName: medicine.unitedName,
                    useSingle: medicine.useSingle === true ? true : undefined,
                    groupName: medicine.groupName,
                    backgroundColor: medicine.backgroundColor,
                  })),
                  tests: [],
                  events: [],
                });
              }
            } else {
              const match = parsedBlock.text.match(this.reTm);
              chart.error_message.push(
                "Unexpected time:" +
                  (match
                    ? match[i][0] === ":"
                      ? match[i].slice(1).trim()
                      : match[i].trim()
                    : "")
              );
            }
          }
        }
      }
    }

    // 絶対日付順にソートする
    stages.sort((a, b) => (a.datetime.isBefore(b.datetime) ? -1 : 1));
    // 単一薬剤の自動終了
    let currentSingleMedicine: PMedicine | null = null;
    for (const stage of stages) {
      const newMedicines: PMedicine[] = [];
      for (const medicine of stage.medicines) {
        if (medicine.useSingle && medicine.useSingle === true) {
          if (
            currentSingleMedicine !== null &&
            currentSingleMedicine.name !== medicine.name
          ) {
            if (
              !currentSingleMedicine.amount ||
              currentSingleMedicine.amount !== "0"
            ) {
              const name = currentSingleMedicine.name;
              const pos = newMedicines.findIndex((m) => m.name === name);
              if (pos === -1) {
                newMedicines.push({
                  ...(currentSingleMedicine as PMedicine),
                  amount: "0",
                });
              } else {
                newMedicines.splice(pos, 1);
              }
            }
          }
          currentSingleMedicine = medicine;
        }
        newMedicines.push(medicine);
      }
      stage.medicines = newMedicines;
    }
    return stages;
  }

  /**
   * ClinicalChartのテンプレートを返す
   * @returns テンプレート（変更可）
   */
  private clinicalChartTemplate(): Chart {
    const chart: Chart = {
      time_range: {
        from: "",
        to: "",
      },
      stack_graph: {
        boxes: [],
      },
      line_graph: {
        lines: [],
      },
      event_graph: {
        events: [],
      },
      error_message: [],
    };
    return lodash.cloneDeep(chart);
  }

  /**
   * 指定タイムスタンプが指す日の始まりを返す
   * @param day タイムスタンプ
   * @returns dayの0時0分0秒
   */
  private daybegin(day: dayjs.Dayjs): dayjs.Dayjs {
    return dayjs(day).hour(0).minute(0).second(0).millisecond(0);
  }

  /**
   * 今日の始まりのタイムスタンプを返す
   * @returns 今日の始まり
   */
  private today(): dayjs.Dayjs {
    return this.daybegin(dayjs());
  }

  /**
   *
   * @param day 指定日
   * @param inc 加算する日数。省略時は1
   * @returns 指定日にinc日数を加算した日の始まり
   */
  private nextday(day: dayjs.Dayjs, inc?: number): dayjs.Dayjs {
    inc = inc === undefined ? 1 : inc;
    return this.daybegin(day.add(inc, "day"));
  }

  /**
   * 時間範囲を設定する
   * @param stages 中間データ
   * @param chart 時間範囲を設定するClinicalChart
   */
  private generateTimeRange(stages: Stage[], chart: Chart): void {
    let first: dayjs.Dayjs;
    let last: dayjs.Dayjs;
    if (stages.length === 0) {
      // データなしの場合は、[今日,明日) の範囲とする
      first = this.today();
      last = this.nextday(first);
    } else if (stages.length === 1) {
      // 一日分のデータの場合は [先頭の日付, 先頭の日付の翌日) の範囲とする
      first = this.daybegin(stages[0].datetime);
      last = this.nextday(first);
    } else {
      // 複数の日付の場合は、[先頭の日付 , 末尾の日付の翌日)の範囲とする
      first = this.daybegin(stages[0].datetime);
      last = this.nextday(stages[stages.length - 1].datetime);
    }
    chart.time_range.from = first.format();
    chart.time_range.to = last.format();

    // 最初にデータが現れる日をfromに設定する
    for (const stage of stages) {
      if (
        stage.events.length > 0 ||
        stage.tests.length > 0 ||
        stage.medicines.length > 0
      ) {
        chart.time_range.from = this.daybegin(stage.datetime).format();
        break;
      }
    }
    // 最後にデータが現れる日をtoに設定する
    for (const stage of stages.slice().reverse()) {
      if (
        stage.events.length > 0 ||
        stage.tests.length > 0 ||
        stage.medicines.length > 0
      ) {
        chart.time_range.to = this.daybegin(
          this.nextday(stage.datetime)
        ).format();
        break;
      }
    }
  }

  /**
   * stack_graphsを生成する
   * @param stages 中間データ
   * @param chart line_graphsを設定するClinicalChart
   */
  private generateStackGraph(stages: Stage[], chart: Chart): void {
    // 薬剤ごとの値を集める
    const medicine_hash: {
      [key: string]: ({ dt: dayjs.Dayjs; medicine: PMedicine } | null)[];
    } = {};
    for (const item of stages) {
      const dt = this.daybegin(item.datetime);
      for (const medicine of item.medicines) {
        const name = medicine.unitedName || medicine.name;
        if (!medicine_hash[name]) {
          medicine_hash[name] = [];
        }
        medicine_hash[name].push({ dt: dt, medicine: medicine });
        // 使用量省略時、前回（中止時はさらに前回）の使用量を引き継ぐ
        if (medicine.amount === "") {
          for (const med of medicine_hash[name].slice().reverse()) {
            const amount = med?.medicine.amount;
            if (amount != null && amount !== "0" && amount !== "") {
              medicine.amount = amount;
              break;
            }
          }
        }
        if (!medicine.interval) {
          for (const med of medicine_hash[name].slice().reverse()) {
            const interval = med?.medicine.interval;
            if (interval != null && interval !== "") {
              medicine.interval = interval;
              medicine.convertRate = med?.medicine.convertRate;
              break;
            }
          }
        }
        const label = [
          (name || "").trim(),
          (medicine.amount || "")?.trim() === "0"
            ? null
            : (medicine.amount || "")?.trim(),
          (medicine.interval || "")?.trim(),
        ].filter((n) => n != null && n !== "");
        medicine.label = label.join(" ");
      }
    }

    // TODO 薬剤ごとの高さと高さを算出する係数を求める
    for (const key in medicine_hash) {
      //薬剤内の最小量を求める
      const minAmount = medicine_hash[key].reduce((memo, e) => {
        const amount =
          e?.medicine.amount === undefined ? "" : e.medicine.amount;
        const convertRate = e?.medicine.convertRate || 1;
        switch (amount) {
          case "":
            if (e?.medicine.convertRate !== undefined)
              memo = Math.min(memo, convertRate);
            break;
          case "0":
            break;
          default:
            memo = Math.min(memo, Number(amount) * convertRate);
            break;
        }
        return memo;
      }, Infinity);
      // nameが薬剤名
      for (let i = 0; i < medicine_hash[key].length; i++) {
        const medicine_datepair = medicine_hash[key][i];
        let bContinue = false;

        // 同一の薬剤指定がいつまで続くかを判定する
        // null値が設定されている場合は、既に同じ用量の連続として取り込み済みを意味する
        if (medicine_datepair !== null) {
          const from = medicine_datepair.dt;
          let to: dayjs.Dayjs | null = null;
          const tommorow = dayjs()
            .add(1, "d")
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .format("YYYY-MM-DDTHH:mm:ss+09:00");
          for (let j = i + 1; j < medicine_hash[key].length; j++) {
            const nmed = medicine_hash[key][j];
            if (nmed === null) {
              chart.error_message.push(
                "Assertion failed: unexpected null value"
              );
              throw new Error("Assertion failed: unexpected null value");
            }
            //1日量 or 期間が変更になれば新規帯描画
            if (medicine_datepair.medicine.name !== nmed.medicine.name) {
              // 薬剤スイッチ
              to = nmed.dt;
              break;
            } else if (
              medicine_datepair.medicine.amount !== nmed.medicine.amount ||
              medicine_datepair.medicine.interval !== nmed.medicine.interval
            ) {
              // 用量変更
              to = nmed.dt;
              break;
            } else {
              // 用量同じなため取り込み済みとして消去する
              medicine_hash[key][j] = null;
            }
          }
          if (to === null) {
            // 最後まで同一用量であれば、このチャートの範囲終端まで継続とみなす
            to = dayjs(
              tommorow < chart.time_range.to ? tommorow : chart.time_range.to
            );
            bContinue = true;
          } else {
            bContinue = false;
          }

          // TODO 用量と係数から箱の高さを計算する
          //　ボックス基準高さ（最小高さ）
          const box_defaultheight = 4;
          //　ボックス相対高さ
          let box_height = 5;
          let temp_a = 0;
          //最小高さと比較して比率を求める
          //数字があり（設定してない同士も対象）、且つ、薬品の積み上げ図が2つ以上できる場合
          const amount = medicine_hash[key][i]?.medicine.amount;
          const convertRate = medicine_hash[key][i]?.medicine.convertRate;
          if (amount === "" && convertRate === undefined) {
            box_height = 6;
          } else if (amount === "0") {
            box_height = 0;
          } else if (amount !== null && medicine_hash[key].length > 1) {
            temp_a = (amount !== "" ? Number(amount) : 1) * (convertRate || 1);
            box_height =
              Math.round(100 * box_defaultheight * (temp_a / minAmount)) / 100;
            //高さの最大値は80 ただし、40以上は、logN *10を加算
            if (box_height > 40) {
              box_height =
                Math.round(
                  100 * (40 + Math.log10((box_height - 40) / 4) * 10)
                ) / 100;
            }
          } else {
            box_height = 4;
          }
          // from、toから箱を作成する
          chart.stack_graph.boxes.push({
            from: from.format(),
            to: to.format(),
            isContinue: bContinue,
            name: key,
            label: medicine_datepair.medicine.label,
            height: box_height,
            useSingle: medicine_datepair.medicine.useSingle,
            groupName: medicine_datepair.medicine.groupName,
            backgroundColor: medicine_datepair.medicine.backgroundColor,
          });
        }
      }
    }
  }

  /**
   * line_graphsを生成する
   * @param stages 中間データ
   * @param chart line_graphsを設定するClinicalChart
   */
  private generateLineGraph(stages: Stage[], chart: Chart): void {
    // 検査ごとの値を集める
    const tests: {
      [key: string]: { datetime: dayjs.Dayjs; test: PTest }[];
    } = {};
    for (const stage of stages) {
      for (const test of stage.tests) {
        const name = test.unitedName || test.name;
        if (!tests[name]) {
          tests[name] = [];
        }
        tests[name].push({
          datetime: this.daybegin(stage.datetime),
          test: test,
        });
      }
    }

    // 検査ごとのグラフを作成する
    for (const testKey in tests) {
      const line: Line = {
        label: testKey,
        values: [],
      };
      const maxDimension = tests[testKey].reduce((memo, test) => {
        if (test.test.value && test.test.value!.length > memo)
          memo = test.test.value!.length;
        return memo;
      }, 0);
      if (maxDimension === 0) continue;
      for (let series = 0; series < maxDimension; series++) {
        line.values.push([]);
      }
      for (const test of tests[testKey]) {
        for (let series = 0; series < maxDimension; series++) {
          if (!test.test.value) continue;
          const value = parseFloat(test.test.value![series]);
          if (!isNaN(value)) {
            line.values[series].push({
              time: test.datetime.format(),
              value: value,
            });
          }
        }
      }
      if (line.values.length > 0) chart.line_graph.lines.push(line);
    }
  }

  /**
   * event_graphを設定する
   * @param stages 中間データ
   * @param chart event_graphを設定するClinicalChart
   */
  private generateEvents(stages: Stage[], chart: Chart): void {
    for (const stage of stages) {
      const dt = this.daybegin(stage.datetime);
      const diff = dt.diff(this.daybegin(this.today())) / (24 * 60 * 60 * 1000);
      for (const event of stage.events) {
        chart.event_graph.events.push({
          time: dt.format(),
          name: diff > 0 ? `${event.label}(${diff})` : event.label,
        });
      }
    }
  }

  /**
   * 解析された所見テキストから臨床経過図記述を生成する
   * @param findingsText 解析された所見テキスト
   * @returns 臨床経過図記述
   */
  public generate(findingsText: FindingsText): Chart | null {
    const chart = this.clinicalChartTemplate();

    // 時間順の中間構造に変換する
    const stages = this.transform(findingsText, chart);

    // 時間範囲を認識する
    this.generateTimeRange(stages, chart);

    // stack_graphを生成する
    this.generateStackGraph(stages, chart);

    // line_graphを設定する
    this.generateLineGraph(stages, chart);

    // event_graphを設定する
    this.generateEvents(stages, chart);
    return chart;
  }
}

export default new ChartGenerator();
