import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { EditorState } from "draft-js";
import decorator from "../components/noteEditor/decorator";

export interface MyeditorState {
  editorState: EditorState;
}

const initialState: MyeditorState = {
  editorState: EditorState.createEmpty(decorator),
};

export const myeditorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setEditorState: (states, action) => {
      states.editorState = action.payload;
    },
  },
});

// Exports actions
export const { setEditorState } = myeditorSlice.actions;

// Exports selectors
export const selectEditorState = (state: RootState) => state.editor.editorState;

// Exports reducer
export default myeditorSlice.reducer;
