import React from "react";
import { FindingsText } from "../app/appSlice";
import { ModalOpenClose } from "./main";

export default function FidingsTextCopy(findingsText: FindingsText | null) {
  let temp: string = "";
  findingsText?.text?.blocks.forEach((e, i, arr) => {
    if (i !== arr.length - 1) {
      temp = temp + e.text + "\n";
    } else temp = temp + e.text;
  });
  navigator.clipboard.writeText(temp);
  setTimeout(ModalOpenClose("copysuccess"), 0);
}
