export const dbName = "FindingsIDB";
export const dbVersion = 3;
export const FINDING = "FINDING";
export const EVENT = "EVENT";
export const MUTE = "MUTE";
export const TIMESTAMP = "TIMESTAMP";
export type DictKind = "MEDICINE" | "TESTITEM";
export const MEDICINE: DictKind = "MEDICINE";
export const TESTITEM: DictKind = "TESTITEM";
export type SortDirection = "ASC" | "DESC";
export const ASC: SortDirection = "ASC";
export const DESC: SortDirection = "DESC";
export const MEDICINE_BGCOLOR_DEFAULT = { backgroundColor: "#ffffff" };
