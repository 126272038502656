import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./appSlice";
import dictReducer from "./dictSlice";
import editorReducer from "./editorSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    dict: dictReducer,
    editor: editorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
