import { Chart, FindingsText } from "../../app/appSlice";
import { dbName, DictKind, MEDICINE_BGCOLOR_DEFAULT } from "../../app/define";
import Dexie from "dexie";
import { Dict } from "../../app/dictSlice";
export type DexieDatabase = { [P in keyof Dexie]: Dexie[P] };

export interface FindingsIDB extends DexieDatabase {
  FINDING: Dexie.Table<Outputdata, number>;
  MEDICINE: Dexie.Table<Dict, string>;
  TESTITEM: Dexie.Table<Dict, string>;
}

export interface Inputdata {
  No?: number;
  title: string;
  text: FindingsText;
  memo: string;
  save_date: string;
  create_date: string;
  chartData: Chart | null;
}

export interface Outputdata {
  No: number;
  title: string;
  text: FindingsText;
  memo: string;
  save_date: string;
  create_date: string;
  chartData: Chart | null;
}

export const openDB = async (versionupOnly?: boolean) => {
  const db = new Dexie(dbName) as FindingsIDB;
  db.version(1)
    .stores({
      FINDING: "No",
      MEDICINE: "word",
      TESTITEM: "word",
    })
    .upgrade((trans) => {
      console.log("upgrade 1");
    });
  db.version(2)
    .stores({
      FINDING: "No",
      MEDICINE: "word",
      TESTITEM: "word",
    })
    .upgrade((trans) => {
      console.log("upgrade 2");
    });
  db.version(3)
    .stores({
      FINDING: "No",
      MEDICINE: "word",
      TESTITEM: "word",
    })
    .upgrade(async (trans) => {
      const medicines = await db.MEDICINE.toArray();
      const testitems = await db.TESTITEM.toArray();
      await db.MEDICINE.clear();
      await db.TESTITEM.clear();
      await db.MEDICINE.bulkAdd(
        medicines.map((medicine) => ({ ...medicine, aliases: [] }))
      );
      await db.TESTITEM.bulkAdd(
        testitems.map((testitem) => ({ ...testitem, aliases: [] }))
      );
    });
  db.version(4)
    .stores({
      FINDING: "No",
      MEDICINE: "word",
      TESTITEM: "word",
    })
    .upgrade(async (trans) => {
      const medicines = await db.MEDICINE.toArray();
      await db.MEDICINE.clear();
      await db.MEDICINE.bulkAdd(
        medicines.map((medicine) => ({
          ...medicine,
          useSingle: medicine.useSingle || false,
        }))
      );
      const testitem = await db.TESTITEM.toArray();
      await db.TESTITEM.clear();
      await db.TESTITEM.bulkAdd(
        testitem.map((testitem) => ({
          ...testitem,
          useSingle: testitem.useSingle || false,
        }))
      );
    });
  db.version(5)
    .stores({
      FINDING: "No",
      MEDICINE: "word",
      TESTITEM: "word",
    })
    .upgrade(async (trans) => {
      const medicines = await db.MEDICINE.toArray();
      await db.MEDICINE.clear();
      await db.MEDICINE.bulkAdd(
        medicines.map((medicine) => ({
          ...medicine,
          bgColorStyle: MEDICINE_BGCOLOR_DEFAULT,
        }))
      );
      const testitem = await db.TESTITEM.toArray();
      await db.TESTITEM.clear();
      await db.TESTITEM.bulkAdd(
        testitem.map((testitem) => ({
          ...testitem,
          useSingle: testitem.useSingle || false,
          bgColorStyle: MEDICINE_BGCOLOR_DEFAULT,
        }))
      );
    });
  db.version(6)
    .stores({
      FINDING: "No",
      MEDICINE: "word",
      TESTITEM: "word",
    })
    .upgrade(async (trans) => {
      const medicines = await db.MEDICINE.toArray();
      await db.MEDICINE.clear();
      await db.MEDICINE.bulkAdd(
        medicines.map((medicine) => ({
          ...medicine,
          groupName: "",
        }))
      );
      const testitem = await db.TESTITEM.toArray();
      await db.TESTITEM.clear();
      await db.TESTITEM.bulkAdd(
        testitem.map((testitem) => ({
          ...testitem,
          groupName: "",
        }))
      );
    });
  if (!versionupOnly) await db.open();
  return db;
};

export const addDictToIdb = async (dict: Dict, kind: DictKind) => {
  const db = await openDB();
  await db[kind].add(dict);
  db.close();
};

export const deleteIdb = () => {
  Dexie.delete(dbName);
};

export const deleteFindingsFromIdb = async (n: number) => {
  const db = await openDB();
  await db.FINDING.delete(n);
  db.close();
};

export const getFindingsFromIdb = async (): Promise<Outputdata[]> => {
  const db = await openDB();
  const result = await db.FINDING.toArray();
  db.close();
  return result;
};

export const addFindingsToIdb = async (inputdata: Inputdata) => {
  const db = await openDB();
  const outputdataList = await db.FINDING.reverse().limit(1).sortBy("No");
  const newNo = outputdataList.length === 0 ? 1 : outputdataList[0].No + 1;
  const outputdata: Outputdata = {
    No: newNo,
    title: inputdata.title,
    text: inputdata.text,
    memo: inputdata.memo,
    save_date: inputdata.save_date,
    create_date: inputdata.create_date,
    chartData: inputdata.chartData,
  };
  const findingsText: FindingsText = {
    No: newNo.toString(),
    title: inputdata.title,
    memo: inputdata.memo,
    text: inputdata.text.text,
  };
  await db.FINDING.add(outputdata);
  db.close();
  return findingsText;
};

export const putFindingsToIdb = async (inputdata: Inputdata) => {
  const db = await openDB();
  if (!inputdata.No) {
    throw Error("inputdata.No missing.");
  }
  const outputdata: Outputdata = {
    No: inputdata.No,
    title: inputdata.title,
    text: inputdata.text,
    memo: inputdata.memo,
    save_date: inputdata.save_date,
    create_date: inputdata.create_date,
    chartData: inputdata.chartData,
  };
  const findingsText: FindingsText = {
    No: inputdata.No.toString(),
    title: inputdata.title,
    memo: inputdata.memo,
    text: inputdata.text.text,
  };
  await db.FINDING.put(outputdata);
  db.close();
  return findingsText;
};

export const getDictFromIdb = async (kind: DictKind) => {
  const db = await openDB();
  let result: Dict[];
  result = await db[kind].toArray();
  db.close();
  return result;
};

export const putDictToIdb = async (dict: Dict, kind: DictKind) => {
  const db = await openDB();
  await db[kind].put(dict);
  db.close();
};

export const deleteDictFromIdb = async (word: string, kind: DictKind) => {
  const db = await openDB();
  await db[kind].delete(word);
  db.close();
};
