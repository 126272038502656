/**
 * 日付認識クラス
 */
export class DateMatcher {
  static readonly timestampMatchReg = (() => {
    const createRegExp = (
      str: TemplateStringsArray,
      re_y: string,
      re_m: string,
      re_d: string
    ) =>
      new RegExp(
        str.raw[0]
          .replace(/\s/gm, "")
          .replaceAll("[RE_Y]", re_y)
          .replaceAll("[RE_M]", re_m)
          .replaceAll("[RE_D]", re_d),
        "g"
      );
    const convertRE = (str: TemplateStringsArray) =>
      str.raw[0].replace(/\s/gm, "");
    //年識別正規表現
    const RE_Y = convertRE`(?:((?:(?:[1１][9９]|[2２][0０])[0-9０-９]{2})|(?:(?:R[.．]?|H[.．]?|S[.．]?|T[.．]?|M[.．]?|Ｒ[.．]?|Ｈ[.．]?|Ｓ[.．]?|Ｔ[.．]?|Ｍ[.．]?|令和|平成|昭和|大正|明治|令|平|昭|大|明)[0-9０-９]{1,2}))年?)`;
    //月識別正規表現（終了日が日単独の場合、月が採用されることがあるため、「上旬 etc.」「日」の文字も追加している）
    const RE_M = convertRE`(?:(?:(?:[/_\.／＿．]?(?:((?:[0-9０-９]{1,2})|(?:[*＊]{2})|上旬|中旬|下旬)))[月日]?))`;
    //日識別正規表現
    const RE_D = convertRE`(?:(?:[/_\.／＿．]?((?:(?:[0-9０-９]{1,2})|(?:[*＊]{2}))|上旬|中旬|下旬))日?)`;
    //日付文字列識別正規表現（ 接頭文字列 + 開始日付YYYYMMDD - 終了日付YYYYMMDD + 接尾文字列 ）
    const anotherLongRE = createRegExp`
      (?:(?:^[\s　]*[※・]?)|(?:[:]))
      (?: (?: [RE_Y] (?: [RE_M][RE_D]? )? ) | (?: [RE_M][RE_D]? ) )
      (?:- (?: (?: [RE_Y] (?: [RE_M][RE_D]? )? ) | (?: [RE_M][RE_D]? ) )? )?
      (?:\s|　|,|:|頃|、|より)
      ${RE_Y}${RE_M}${RE_D}`;
    return anotherLongRE;
  })();
}

export interface NengoEntry {
  nengo: string;
  offset: number;
  from: number[];
  to: number[];
}
export interface NengoTable {
  [key: string]: NengoEntry;
}

export const nengoTable: NengoTable = {
  R: { nengo: "R", offset: 2018, from: [2019, 1, 1], to: [2099, 12, 31] },
  Ｒ: { nengo: "R", offset: 2018, from: [2019, 1, 1], to: [2099, 12, 31] },
  令: { nengo: "R", offset: 2018, from: [2019, 1, 1], to: [2099, 12, 31] },
  令和: { nengo: "R", offset: 2018, from: [2019, 1, 1], to: [2099, 12, 31] },
  H: { nengo: "H", offset: 1988, from: [1989, 1, 1], to: [2019, 4, 30] },
  Ｈ: { nengo: "H", offset: 1988, from: [1989, 1, 1], to: [2019, 4, 30] },
  平: { nengo: "H", offset: 1988, from: [1989, 1, 1], to: [2019, 4, 30] },
  平成: { nengo: "H", offset: 1988, from: [1989, 1, 1], to: [2019, 4, 30] },
  S: { nengo: "S", offset: 1925, from: [1926, 1, 1], to: [1989, 1, 7] },
  Ｓ: { nengo: "S", offset: 1925, from: [1926, 1, 1], to: [1989, 1, 7] },
  昭: { nengo: "S", offset: 1925, from: [1926, 1, 1], to: [1989, 1, 7] },
  昭和: { nengo: "S", offset: 1925, from: [1926, 1, 1], to: [1989, 1, 7] },
  T: { nengo: "T", offset: 1911, from: [1912, 1, 1], to: [1926, 12, 26] },
  Ｔ: { nengo: "T", offset: 1911, from: [1912, 1, 1], to: [1926, 12, 26] },
  大: { nengo: "T", offset: 1911, from: [1912, 1, 1], to: [1926, 12, 26] },
  大正: { nengo: "T", offset: 1911, from: [1912, 1, 1], to: [1926, 12, 26] },
  M: { nengo: "M", offset: 1867, from: [1868, 1, 1], to: [1912, 7, 30] },
  Ｍ: { nengo: "M", offset: 1867, from: [1868, 1, 1], to: [1912, 7, 30] },
  明: { nengo: "M", offset: 1867, from: [1868, 1, 1], to: [1912, 7, 30] },
  明治: { nengo: "M", offset: 1867, from: [1868, 1, 1], to: [1912, 7, 30] },
};

const normalizeDateCore = (
  yyyy1: string | undefined,
  mm1: string | undefined,
  dd1: string | undefined,
  mm2: string | undefined,
  dd2: string | undefined
): string | undefined => {
  if (!yyyy1 && !mm1 && !dd1 && !mm2 && !dd2) return undefined;
  let yyyy: string | null = null;
  if (yyyy1) {
    const yearNumber = yyyy1.match(/\d+/);
    const offset =
      nengoTable[yyyy1[0] + yyyy1[1]]?.offset ||
      nengoTable[yyyy1[0]]?.offset ||
      0;
    if (yearNumber) {
      yyyy = String(parseInt(yearNumber[0]) + offset);
    }
  }
  let mm = mm1 || mm2;
  if (mm === "**") mm = "1";
  let dd = dd1 || dd2;
  switch (dd) {
    case "**":
    case "上旬":
      dd = "1";
      break;
    case "中旬":
      dd = "11";
      break;
    case "下旬":
      dd = "21";
      break;
  }
  if (yyyy) {
    return `${yyyy}/${mm ? mm : 1}/${dd ? dd : 1}`;
  } else if (mm) {
    return `${mm}/${dd ? dd : 1}`;
  } else {
    return `${dd}`;
  }
};

export const normalizeDate = (
  dateString: string,
  regexp: RegExp
): (string | undefined)[] => {
  regexp.lastIndex = 0;
  const match = regexp.exec(dateString);
  if (match) {
    // yyyy/mm/dd-dd or mm/dd-dd 形式の日付は、正規表現上は、終了日付が月認識されるため調整する。
    if ((match[1] && match[2] && match[3]) || (match[4] && match[5])) {
      //mm2のみ入力されている場合。
      if (!match[6] && !match[7] && !match[8] && !match[10] && !match[11]) {
        return [
          normalizeDateCore(match[1], match[2], match[3], match[4], match[5]),
          normalizeDateCore(
            undefined,
            undefined,
            undefined,
            undefined,
            match[9]
          ),
        ];
      } else if (
        !match[6] &&
        !match[7] &&
        !match[8] &&
        !match[9] &&
        !match[10]
      ) {
        //dd2のみ入力されている場合。
        return [
          normalizeDateCore(match[1], match[2], match[3], match[4], match[5]),
          normalizeDateCore(
            undefined,
            undefined,
            undefined,
            undefined,
            match[11]
          ),
        ];
      }
      return [
        normalizeDateCore(match[1], match[2], match[3], match[4], match[5]),
        normalizeDateCore(match[6], match[7], match[8], match[9], match[10]),
      ];
    } else {
      return [
        normalizeDateCore(match[1], match[2], match[3], match[4], match[5]),
        normalizeDateCore(match[6], match[7], match[8], match[9], match[10]),
      ];
    }
  } else {
    return [dateString, undefined];
  }
};
