import React, { ReactSVGElement, useCallback, useRef } from "react";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import Measure from "react-measure";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CheckLine,
  Chart,
  mergeCheckLines,
  selectChartData,
  selectChartSize,
  selectCheckLines,
  setChartSize,
  setCheckLineFlg,
} from "../../app/appSlice";
import dayjs from "dayjs";
import lodash from "lodash";
import { SizeCalc, Medicine, Mark, DateCheck } from "./Charttypes";
import { Evaluate_cmap } from "./colorMap/js-colormaps/js-colormaps";
import "../../pages/mystyle.scss";
import { isMobile, ModalOpenClose } from "../../pages/main";

//空の値として扱う
const initialSvg: React.ReactSVGElement = React.createElement(
  "text",
  { x: 0, y: 0, key: "initialsvg" },
  []
);

//svg描画範囲幅
let svgX: number = 0;
//svg描画範囲高さ(固定値)
let svgY: number = 0;
//viewbox設定
let svgView: string = "";
//svg描画領域を囲むpadding
const padding: number = 20;
//svg描画幅比率
const svgDefaultArea: number = 140;
//svg描画範囲最左辺
const svgMarginLeft: number = 32;
//svg描画範囲最右辺
const svgMarginRight: number = 32;
//縦線TopMargin
const verticalLineTopMargin: number = 10;

////////////
//グラフ基本描画
////////////
//グラフ範囲の最初と最後の日付
let termFrom: dayjs.Dayjs;
let termTo: dayjs.Dayjs;
let term = 0;
// x軸ラインより下のy軸幅（固定値）
const svgUnderline: number = 50;
// 日付ラベル用y軸幅（固定値）
const dateLabelArea: number = 30;
// x軸ライン描画座標
let svgLinePoint: number = 0;
//一時間あたりのpx数
let hourWidthPx: number = 0;
//グラフを描画するx軸幅
let graphWidth: number = 0;
//日付ラベル表示y座標(最下辺)
let graphDateLabelY: number = 0;
//フォントサイズ基準値
let defaultFontsize: number = 10;
//X軸横線
let xline: React.ReactSVGElement = initialSvg;
//本日縦線
let verticalLines: React.ReactSVGElement[];
//ラベルsvg格納
let labelSvg: React.ReactSVGElement[] = [];

////////////
//積み上げ図
////////////
//積み上げ図開始y座標基準値
let boxStartY: number = 0;
//積み上げ図ブロック高さ基準値
let boxHeight: number = 3.5;
//積み上げ図ラベル用調整値
const labelValue: number = 3;
//積み上げ図用フォントサイズ
let boxFontsize = defaultFontsize;
//積み上げ図最上辺の最大y座標
let boxTopY: number = 10;
//積み上げ図の最大y軸幅
let boxMaxHeight: number = 0;
//積み上げ図の最上辺のy座標
let boxAdjust: number = 0;
//積み上げ図整理
let medicines: Medicine[] = [];
//ボックスSVG
let stack: React.ReactSVGElement[] = [];
//ボックス内に記載するテキスト
let stackText: React.ReactSVGElement[] = [];

////////////
//イベント図
////////////
//イベント図描画開始y座標（x軸ライン-default_fontsize）
let eventStartY: number = 0;
//イベント図最大y軸幅
let eventMaxHeight: number = 0;
//イベント図用フォントサイズ
let eventFontsize = defaultFontsize;
//イベントsvg格納
let eventText: React.ReactSVGElement[] = [];

////////////
//折れ線グラフ
////////////
//折れ線グラフ描画最上辺y座標
let lineTop: number = 0;
//折れ線グラフ描画y座標範囲
let lineArea: number = 80;
//グラフごとにidを割り振る
let lineGroup: ReactSVGElement[] = [];

//ウィンドウサイズからグラフエリアを設定する(main.tsxから呼び出し)
export function setSize(state: SizeCalc) {
  //svg描画範囲幅(左右のpadding合計40を引く)
  svgX = Math.max(state.width - padding * 2, 0);
  //viewbox設定
  svgView = "0 0 " + svgX + " " + svgY;

  //x軸ライン描画座標
  svgLinePoint = svgY - svgUnderline;
  //グラフを描画するx軸幅
  graphWidth = svgX - svgDefaultArea;
  //日付ラベル表示y座標(最下辺)
  graphDateLabelY = svgY - dateLabelArea;

  //イベント図描画開始y座標（x軸ライン-default_fontsize）
  eventStartY = svgLinePoint - defaultFontsize;
}

function calcPxTerm(chart: Chart) {
  //期間の絶対値を時間単位で求める
  //最古日〜最新日の総時間を求める
  termFrom = dayjs(chart.time_range.from);
  termTo = dayjs(chart.time_range.to);
  term = termTo.diff(termFrom, "hour");

  //一時間あたりのpx数
  hourWidthPx = graphWidth / term;

  //イベント図を描画がsvgエリアを突き抜けないように調整
  for (const event of chart.event_graph.events) {
    //イベント図座標位置+文字列幅を計算し、超過分を求める
    const temp = setPoint(event.time) + calcWidth(`▲${event.name}`);
    //イベント図文字列が最右辺
    if (svgX - svgMarginRight < temp) {
      graphWidth = graphWidth - (temp - (svgX - svgMarginRight));
      hourWidthPx = graphWidth / term;
    }
  }

  //折れ線グラフの座標ラベルがsvgエリアを突き抜けないように調整
  for (const line of chart.line_graph.lines) {
    for (const value of line.values) {
      if (value.length === 0) continue;
      //日付順にソートする
      value.sort((a, b) => (a.time > b.time ? 1 : -1));

      //折れ線グラフ座標ラベル位置+文字列幅を計算し、超過分を求める
      const temp =
        setPoint(value.slice(-1)[0].time) +
        calcWidth(value.slice(-1)[0].value.toString());
      //イベント図文字列が最右辺
      if (svgX - svgMarginRight < temp) {
        graphWidth = graphWidth - (temp - (svgX - svgMarginRight));
        hourWidthPx = graphWidth / term;
      }
    }
  }
}

//原点〜x軸座標を算出する
//引数・日付（ISO8601の形で受け取る）
function setPoint(datepoint: string) {
  const xpoint =
    dayjs(datepoint).diff(termFrom, "hour") * hourWidthPx + svgMarginLeft;
  return xpoint;
}

//svg上の文字列幅を算出する
function calcWidth(str: string) {
  return calcHeightWidth(str)[1];
}

function calcHeightWidth(str: string) {
  //一時的にsvg領域をbodyに作成する
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")!;
  svg.setAttribute("width", "0px");
  svg.setAttribute("height", "0px");
  svg.setAttribute("viewBox", "0 0 0 0");
  document.body.appendChild(svg);

  //文字列幅を調べる
  const text = document.createElementNS("http://www.w3.org/2000/svg", "text")!;
  text.setAttribute("x", "0");
  text.setAttribute("y", "0");
  text.setAttribute("font-size", "10");
  text.innerHTML = str;
  svg.appendChild(text);
  const height = text.getBBox().height;
  const width = text.getBBox().width;

  //処理が完了したらsvg領域を削除する
  document.body.removeChild(svg);
  return [height, width];
}

//日付ラベルを作成する
export function setLineLabel(chart: Chart) {
  //グラフ描画用の値を取得する
  calcPxTerm(chart);

  //x軸ラインを引く
  xline = React.createElement(
    "line",
    {
      x1: svgMarginLeft,
      y1: svgLinePoint,
      x2: svgX - svgMarginRight,
      y2: svgLinePoint,
      stroke: "black",
      strokeWidth: 1,
      key: "xline",
    },
    []
  );
  //日付を格納する
  const dateChecks: DateCheck[] = [];
  //ラベルsvg格納初期化
  labelSvg = [];
  verticalLines = [];

  //文字を傾けるための調整値
  const adjustValue = defaultFontsize * Math.sin(Math.PI / 4);
  const adjustValueYear = defaultFontsize * 0.8;

  //臨床経過記述から日付データを全て取得する
  //time_range以外何もデータがなかったら処理をしない
  if (
    (chart.stack_graph.boxes.length === 0 &&
      chart.line_graph.lines.length === 0 &&
      chart.event_graph.events.length === 0) !== true
  ) {
    //boxes取得
    chart.stack_graph.boxes.forEach((e) => {
      if (
        dateChecks.findIndex((obj) => obj.date === e.from) === -1 &&
        e.from !== chart.time_range.to
      ) {
        dateChecks.push({
          date: e.from,
          over: 0,
          x: setPoint(e.from) - adjustValue,
          width:
            calcWidth(dayjs(e.from).format("MM/DD")) * Math.sin(Math.PI / 4),
        });
      }
      if (
        dateChecks.findIndex((obj) => obj.date === e.to) === -1 &&
        e.to !== chart.time_range.to
      ) {
        dateChecks.push({
          date: e.to,
          over: 0,
          x: setPoint(e.to) - adjustValue,
          width: calcWidth(dayjs(e.to).format("MM/DD")) * Math.sin(Math.PI / 4),
        });
      }
    });

    //line_graph取得
    chart.line_graph.lines.forEach((line) => {
      line.values.forEach((series) => {
        series.forEach((value) => {
          //time_rangeのfrom値と同じ場合（テキスト入力日に+1されている日付）は除く
          if (
            dateChecks.findIndex(
              (dateCheck) => dateCheck.date === value.time
            ) === -1 &&
            value.time !== chart.time_range.to
          ) {
            dateChecks.push({
              date: value.time,
              over: 0,
              x: setPoint(value.time) - adjustValue,
              width:
                calcWidth(dayjs(value.time).format("MM/DD")) *
                Math.sin(Math.PI / 4),
            });
          }
        });
      });
    });

    //event_graph取得
    chart.event_graph.events.forEach((e) => {
      if (
        dateChecks.findIndex((obj) => obj.date === e.time) === -1 &&
        e.time !== chart.time_range.to
      ) {
        dateChecks.push({
          date: e.time,
          over: 0,
          x: setPoint(e.time) - adjustValue,
          width:
            calcWidth(dayjs(e.time).format("MM/DD")) * Math.sin(Math.PI / 4),
        });
      }
    });

    //本日が期間内の場合は日付を追加する。
    const today = dayjs()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .format("YYYY-MM-DDTHH:mm:ss+09:00");
    const minDay = dateChecks.reduce((memo, day) => {
      return memo > day.date ? day.date : memo;
    }, "9999-12-31");
    const maxDay = dateChecks.reduce((memo, day) => {
      return memo < day.date ? day.date : memo;
    }, "1000-01-10");
    const isWithinPeriod = minDay <= today && today <= maxDay;
    const datalabelOfToday = dateChecks.find((day) => {
      return day.date === today;
    });
    if (!datalabelOfToday && isWithinPeriod) {
      const datelabelToday: DateCheck = {
        date: today,
        over: 0,
        x: setPoint(today) - adjustValue,
        width: calcWidth(dayjs(today).format("MM/DD")) * Math.sin(Math.PI / 4),
      };
      dateChecks.push(datelabelToday);
    }

    //昇順で並び替え
    dateChecks.sort((a, b) => (a.date > b.date ? 1 : -1));

    //年を跨いでいる場合、overのフラグを立てる（=1にする）
    for (let i = 0; i < dateChecks.length - 1; i++) {
      if (
        dateChecks[i].date.substring(0, 4) !==
        dateChecks[i + 1].date.substring(0, 4)
      ) {
        dateChecks[i + 1].over = 1;
      }
    }

    //重なる範囲を指定する
    const tempLength = defaultFontsize / Math.sin(Math.PI / 4);

    //日付ラベルの重なりチェック
    dateChecks.forEach((e, i, arr) => {
      //最初と最後の日付ラベルは必ず表示する
      //西暦が変わった日付のラベルも必ず表示する
      let overlappingFlg: boolean = true;
      if (i < arr.length - 1) {
        const tempOver = i + 1;
        //重なりが消えるまで(overlapping_flgがfalseになるまで)重複チェックを行う
        while (tempOver < arr.length && overlappingFlg === true) {
          //日付ラベルが重なった場合、右隣（未来日）の日付ラベルを非表示にする
          //パターン1:自身のラベルがMM/DDの場合
          if (e.over === 0 && e.x + tempLength - arr[tempOver].x > 0) {
            //ただし右隣のラベルがYYYY/MM/DD形式のラベルの場合、自身の日付ラベルを非表示にする
            if (arr[tempOver].over === 1) {
              arr.splice(i, 1);
            } else arr.splice(tempOver, 1);

            //パターン2:自身のラベルがYYYY/MM/DDの場合、右隣のラベルを非表示にする
            //右隣のラベルもYYYY/MM/DDの場合、右側のラベルが最新日以外はこれを適用する
          } else if (
            e.over !== 0 &&
            e.x + tempLength * 2 - arr[tempOver].x > 0
          ) {
            //ただし右隣のラベルが最新日のラベルの場合、自身の日付ラベルを非表示にする
            if (tempOver === arr.length - 1) {
              arr.splice(i, 1);
            } else arr.splice(tempOver, 1);
          } else overlappingFlg = false;
        }
      }
    });

    //月日ラベル描画
    dateChecks.forEach((e, i, arr) => {
      labelSvg.push(
        React.createElement(
          "text",
          {
            x: e.x,
            y: graphDateLabelY + adjustValue,
            className: "CreateLabel",
            fontSize: defaultFontsize,
            transform:
              "rotate(45 " + e.x + "," + (graphDateLabelY + adjustValue) + ")",
            key: "labelsvg1" + i,
          },
          [dayjs(e.date).format("MM/DD")]
        )
      );
    });
    //先頭年ラベル描画
    const minYear = minDay.slice(0, 4);
    const minYearMonth = parseInt(minDay.slice(5, 7));
    const maxYear = maxDay.slice(0, 4);
    if (minYear === maxYear || (minYearMonth <= 6 && minYear !== maxYear)) {
      labelSvg.push(
        React.createElement(
          "text",
          {
            x: svgMarginLeft + defaultFontsize * 0.2,
            y: graphDateLabelY - adjustValueYear,
            className: "CreateLabel",
            fontSize: defaultFontsize,
            key: "labelYear" + minYear,
          },
          [minYear]
        )
      );
    }
    //毎年の年ラベル、縦線を描画
    for (let year = parseInt(minYear); year <= parseInt(maxYear); year++) {
      const targetYear = `${year}-01-01T00:00:00+09:00`;
      const x = setPoint(targetYear);
      //先頭が1月1日のものは上記で描画済みのためmindayを含まずに描画する。
      if (minDay < targetYear) {
        labelSvg.push(
          React.createElement(
            "text",
            {
              x: x + defaultFontsize * 0.2,
              y: graphDateLabelY - adjustValueYear,
              className: "CreateLabel",
              fontSize: defaultFontsize,
              key: "labelYear" + year,
            },
            [year.toString()]
          )
        );
      }
      for (let month = 1; month <= 12; month++) {
        const monthString = month.toString().padStart(2, "0");
        const target = `${year}-${monthString}-01T00:00:00+09:00`;
        if (minDay <= target && target <= maxDay) {
          const lineX = setPoint(target);
          const lineY =
            svgLinePoint + (month === 1 ? defaultFontsize * 1.8 : 0);
          const dashArray: string = "1 1";
          verticalLines.push(
            React.createElement(
              "line",
              {
                x1: lineX,
                y1: verticalLineTopMargin,
                x2: lineX,
                y2: lineY,
                stroke: "black",
                strokeWidth: (month - 1) % 3 === 0 ? 0.6 : 0.2,
                strokeDasharray: dashArray,
                key: "yline" + year + month,
              },
              []
            )
          );
        }
      }
    }
    //本日線描画
    if (isWithinPeriod) {
      verticalLines.push(
        React.createElement(
          "line",
          {
            x1: setPoint(today),
            y1: verticalLineTopMargin,
            x2: setPoint(today),
            y2: svgLinePoint,
            stroke: "red",
            strokeWidth: 1,
            key: "today_yline",
          },
          []
        )
      );
    }
  }
}

//チャート描画
export function drawGraph(chart: Chart) {
  //////////////////////
  //イベント図描画データ計算
  //////////////////////
  //イベントsvg格納
  eventText = [];
  //イベント座標整理
  let marksList: Mark[][] = [];
  //イベント図の計算
  drawEvent(chart);

  //////////////////////
  //積み上げ図描画データ計算
  //////////////////////
  //積み上げ図整理
  medicines = [];
  //ボックスSVG
  stack = [];
  //ボックス内に記載するテキスト
  stackText = [];
  //積み上げ図の計算
  //積み上げ図データがない場合、boxes_top_yを10、boxesstart_yを100として設定
  if (chart.stack_graph.boxes.length === 0) {
    boxTopY = 10;
    boxStartY = 100;
  } else drawStack(chart);

  const regexp = /#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})/;
  function getTextColor(backgroundColor: string) {
    const match =
      regexp
        .exec(backgroundColor)
        ?.slice(1)
        .reduce((memo, hex) => (memo += parseInt(hex, 16)), 0) || 0;
    return match > 320 ? "#000000" : "#ffffff";
  }

  //積み上げ図
  function drawStack(chart: Chart) {
    //初期化
    //積み上げ図整理
    medicines = [];
    //ボックスSVG
    stack = [];
    //ボックス内に記載するテキスト
    stackText = [];
    //積み上げ図最上辺の最大y座標（余白として10を設定）
    boxTopY = 10;
    //積み上げ図開始y座標基準値
    boxStartY = 0;

    //日付が古い順に下から上へ積んでいくため、boxesをソートする
    //単独薬を上、その他の薬を下に並べる。
    chart.stack_graph.boxes.sort((a, b) => {
      const keyA = `${
        a.useSingle !== undefined && a.useSingle === true ? "1" : "0"
      }_${a.groupName ? a.groupName : ""}_${a.from}`;
      const keyB = `${
        b.useSingle !== undefined && b.useSingle === true ? "1" : "0"
      }_${b.groupName ? b.groupName : ""}_${b.from}`;
      return keyA > keyB ? 1 : -1;
    });

    //↓ソート完了後、medicine_blockに値を格納する↓
    //y座標の基準値
    let nowY: number = boxStartY;
    //重複カウンター
    let count: number = 0;

    chart.stack_graph.boxes.forEach((e) => {
      //boxes内のnameを取得し、初出、既出、重複済の名前かを検索する
      let name: string = e["name"];

      //いくつ重複があるか(xxx_tmpのmedichinenameがいくつあるか)算出する
      const temp = medicines.filter((element) => {
        if (element.name.indexOf(name + "_tmp") === 0) {
          return element.name;
        }
        return 0;
      });

      //重複した場合、nameを「xxx_tmp(1)」の名前でmedicine_blockを検索する。()内の数字は重複数
      if (temp.length > 0) {
        name = e["name"] + "_tmp" + temp.length;
      }

      //再度重複した場合に備えてcountを更新する
      count = temp.length;

      //初出の場合
      if (medicines.findIndex((obj) => obj.name === name) === -1) {
        //y座標を算出する
        nowY = nowY - e["height"] * boxHeight;

        //medicine_blockに追加する
        medicines.push({
          name: name,
          items: [
            {
              from: e["from"],
              to: e["to"],
              height: e["height"],
              label: e["label"],
              x: setPoint(e["from"]),
              y: nowY,
              backgroundColor: e["backgroundColor"],
            },
          ],
        });
      }

      //重複した場合
      else if (medicines.findIndex((obj) => obj.name === name) !== -1) {
        //y座標を算出する
        nowY = nowY - e["height"] * boxHeight;
        //重複した名前が入っているmedicine_blockのインデックスを求める
        const index = medicines.findIndex(
          ({ name: medichinename }) => medichinename === name
        );

        //to-fromの日付が連続している場合、対象のインデックスのitemsにデータを追加する
        if (
          medicines[index].items.findIndex((obj) => obj.to === e["from"]) !== -1
        ) {
          medicines[index].items.push({
            from: e["from"],
            to: e["to"],
            height: e["height"],
            label: e["label"],
            x: setPoint(e["from"]),
            y: nowY,
            backgroundColor: e["backgroundColor"],
          });
        }
        //連続していない場合、新しく名前をつけ、別名でmedicine_blockに追加する。
        else if (
          medicines[index].items.findIndex((obj) => obj.to === name) === -1
        ) {
          //別名用にカウントアップ
          count++;

          //medicine_blockに追加する
          medicines.push({
            name: e["name"] + "_tmp" + count,
            items: [
              {
                from: e["from"],
                to: e["to"],
                height: e["height"],
                label: e["label"],
                x: setPoint(e["from"]),
                y: nowY,
                backgroundColor: e["backgroundColor"],
              },
            ],
          });
        }
        //重複カウント初期化
        count = 0;
      }
      return e;
    });

    //薬品ごとのデータを算出する
    //y座標基準値
    let tempy1: number = boxStartY;
    for (const medicine of medicines) {
      //ブロックの高さ(height)
      medicine.height = Math.max(...medicine.items.map((item) => item.height));
      //ブロックの長さ(width)
      medicine.width =
        setPoint(medicine.items.slice(-1)[0].to) -
        setPoint(medicine.items[0].from);
      //ブロックのx座標(block_x)
      medicine.x = medicine.items[0].x;
      //ブロックのy座標(block_y)
      tempy1 = tempy1 - medicine.height! * boxHeight;
      medicine.y = tempy1;
    }

    //↓隙間埋めSTART↓
    for (let i = 0; i < medicines.length; i++) {
      //詰められる場合
      //比較対象のインデックスを用意（自身の一つ前の要素から0に向かって比較）
      let value = i - 1;
      //要素番号1からスタート
      while (value >= 0) {
        //もし自身と、それより前のx軸が重複していなかったら
        if (
          medicines[value].x! + medicines[value].width! - medicines[i].x! <=
          0
        ) {
          let tempindex = i;
          for (tempindex; tempindex < medicines.length; tempindex++) {
            medicines[tempindex].y =
              medicines[tempindex].y! + medicines[value].height! * boxHeight!;
          }
        } else break;
        value--;
      }
    }
    //↑隙間埋めGOAL↑
    let tempy = 0;
    const tempmax: number[] = [];
    //y座標の（イベント図描画最上辺boxes_top_y）を求める
    for (const medicine of medicines) {
      for (const item of medicine.items) {
        //y座標調整（ブロック一つずつの高さを求める）
        tempy = medicine.y! + (medicine.height! - item.height) * boxHeight;
        tempmax.push(tempy);
      }
    }
    boxTopY = Math.min(...tempmax);
    //積み上げ図の全体高さが100より小さい場合、高さを100に調整する
    if (boxTopY > -100) {
      boxTopY = -100;
    }
  }

  //イベント図
  function drawEvent(chart: Chart) {
    //初期化
    //イベントsvg格納
    eventText = [];
    //イベント座標整理
    marksList = [];
    //日付順にソートする
    chart.event_graph.events.sort((a, b) => (a.time > b.time ? 1 : -1));
    chart.event_graph.events.forEach((e) => {
      // 文字先頭のx座標
      const xstart = setPoint(e["time"]);
      // 文字の幅
      const width = calcWidth(`▲${e["name"]}`);
      //　文末のx座標
      const xend = xstart + width;
      //　重複チェック
      let flg: boolean = false;
      //配列[0]から順に、重複する日付があるか確認する。
      //重複しない場合は[0]に、重複する場合は[1]、[2]に入れる
      for (const marks of marksList)
        if (
          marks.findIndex((obj) => obj.date === e["time"]) === -1 &&
          flg !== true
        ) {
          marks.push({
            date: e["time"],
            name: e["name"],
            start: xstart,
            width: width,
            end: xend,
          });
          //どこにも重複がなかったらフラグをtrueにする
          flg = true;
        }
      //どの配列でも([0]でも[1]でも)重複していたら、新しく[3]を作りそこに格納する
      if (flg !== true) {
        marksList.push([
          {
            date: e["time"],
            name: e["name"],
            start: xstart,
            width: width,
            end: xend,
          },
        ]);
        flg = true;
      }
      return 0;
    });
    //x座標の重複チェックを行う
    for (let i = 0; i < marksList.length; i++) {
      //日付順にソートする
      marksList[i].sort((a, b) => (a.date > b.date ? 1 : -1));
      for (let j = 0; j < marksList[i].length; j++) {
        for (let k = marksList[i].length - 1; k > j; k--) {
          //x座標が重複していた場合
          if (marksList[i][j].end - marksList[i][k].start > 0) {
            //上の配列が無い場合
            if (i + 1 === marksList.length) {
              marksList.push([marksList[i][k]]);
            } else marksList[i + 1].push(marksList[i][k]);
            marksList[i].splice(k, 1);
          }
        }
      }
    }
  }

  /////////////////////
  //svg領域の高さを設定する
  /////////////////////
  //イベント図の最大y軸幅を求める。x軸ラインとの余白は、イベント図開始y座標で取得済み
  eventMaxHeight = marksList.length * eventFontsize;
  //積み上げ図の最大y軸幅を求める。（boxesstart_yは最初は100として設定している）
  boxMaxHeight = boxStartY - boxTopY;
  //折れ線グラフ描画エリアは、積み上げ図の最大y軸幅
  //グラフ数が増えるにつれ、このエリア幅がy軸正方向（下側）にずれる
  lineArea = 120;
  const lineVariableArea =
    (chart.line_graph.lines.length - 1) * defaultFontsize * 2;

  //イベント図と折れ線グラフの間、折れ線グラフと積み上げ図の間(ここは2倍の余白)、積み上げ図とsvg描画エリア最上辺にdefault_fontsizeの余白をつける
  //加えてイベント図とx軸ラインの間に余白、x軸ライン以下の幅50を加算する
  //また、折れ線グラフは検査ごとに最大値-最小値エリアを下にずらすため、(検査数-1)*(default_fontsize*2)を加算
  svgY =
    eventMaxHeight +
    boxMaxHeight +
    defaultFontsize * 4 +
    defaultFontsize +
    50 +
    lineArea +
    lineVariableArea;
  //イベント図の開始y座標は、x軸ライン+default_fontsizeなので既に設定済み
  //積み上げ図の開始y座標（最下辺）は、svg描画エリアトップ(=0)+余白+積み上げ図の最大高さ
  boxStartY = defaultFontsize + boxMaxHeight;
  //折れ線グラフの開始y座標(line_top)を設定する
  //積み上げ図開始y座標+余白
  lineTop = boxStartY + defaultFontsize * 2;

  //////////////////////
  //イベント図描画
  //////////////////////
  for (const [i, marks] of marksList.entries()) {
    for (const [j, mark] of marks.entries()) {
      eventText.push(
        React.createElement(
          "text",
          {
            x: (mark.start - defaultFontsize / 2).toString(),
            y: (eventStartY - 10 - eventFontsize * (i - 1)).toString(),
            fontSize: eventFontsize,
            key: `eventtext${i}:${j}`,
          },
          [`▲${mark.name}`]
        )
      );
    }
  }

  //////////////////////
  //積み上げ図描画
  //////////////////////
  for (const [i, medicine] of medicines.entries()) {
    for (const [j, item] of medicine.items.entries()) {
      //横幅を算出
      let stackwidth = Math.max(setPoint(item.to) - setPoint(item.from), 0);
      //過去日かつ、to日付が最新日の場合、svg描画エリアいっぱいまで伸ばす
      const today = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format("YYYY-MM-DDTHH:mm:ss+09:00");
      if (
        item.to < today &&
        dayjs(item.to).format("YYYYMMDD") === termTo.format("YYYYMMDD")
      ) {
        stackwidth = Math.max(svgX - svgMarginRight - setPoint(item.from), 0);
      }
      //終了日が明日（継続処方薬）の幅を見やすく大きくする。
      const tommorow = dayjs()
        .add(1, "d")
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format("YYYY-MM-DDTHH:mm:ss+09:00");
      if (item.to === tommorow) {
        stackwidth += svgX / 100;
      }
      //y座標調整
      //ブロック図の最大高さのy座標が描画範囲の最上辺になるように調整する
      //算出したボックス図スタートライン（boxesstart_y）も加える
      const tempY =
        medicine.y! +
        boxStartY +
        (medicine.height! - item.height) * boxHeight -
        boxAdjust;

      //ブロック図svg作成
      stack.push(
        React.createElement(
          "rect",
          {
            x: item.x.toString(),
            y: tempY.toString(),
            width: stackwidth.toString(),
            height: (item.height * boxHeight).toString(),
            fill: item.backgroundColor,
            stroke: "#888",
            key: `blockrect${i}:${j}`,
          },
          []
        )
      );

      //ブロック図ラベル作成
      stackText.push(
        React.createElement(
          "text",
          {
            x: (item.x + stackwidth / 2).toString(),
            y: (tempY + (item.height! * boxHeight) / 2 + labelValue).toString(),
            fontSize: boxFontsize,
            textAnchor: "middle",
            key: `blocktext${i}:${j}`,
            fill: getTextColor(item.backgroundColor),
          },
          item.height === 0 ? [""] : [item.label]
        )
      );
    }
  }

  //////////////////////
  //折れ線描画データ計算
  //////////////////////
  lineGroup = []; //折れ線グラフのグループ
  drawLine(chart);
  interface Pos {
    x: number;
    y: number;
    width: number;
    height: number;
  }
  // 折れ線図
  function drawLine(chart: Chart) {
    const maxY = lineTop; //折れ線グラフ描画最上辺y座標
    const areaY = lineArea; //折れ線グラフ描画y軸幅
    const valueLabelPositions: Pos[] = [];
    const labelPositions: Pos[] = [];
    //labelの衝突判定
    const isDuplicate = (t: Pos, positions: Pos[]): boolean => {
      for (const c of positions) {
        if (
          Math.abs(t.x - c.x) < t.width / 2 + c.width / 2 &&
          Math.abs(t.y - c.y) < t.height / 2 + c.height / 2
        )
          return true;
      }
      return false;
    };
    const searchValueLabelPos = (t: Pos): Pos | null => {
      const recomendedPos: Pos = {
        x: t.x,
        y: t.y,
        width: t.width,
        height: t.height,
      };
      //右上
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;
      recomendedPos.x -= t.width + 5; //左上
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;
      recomendedPos.y += t.height + 5; //左下
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;
      recomendedPos.x = t.x; //右下
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;

      recomendedPos.y = t.y - t.height * 1.1; //右上の上
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;
      recomendedPos.x -= t.width + 5; //左上の上
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;
      recomendedPos.y = t.y + t.height * 2.2 + 9; //左下の下
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;
      recomendedPos.x = t.x; //右下の下
      if (isDuplicate(recomendedPos, valueLabelPositions) === false)
        return recomendedPos;

      return null;
    };
    const searchLabelPos = (t: Pos): Pos | null => {
      const recomendedPos: Pos = {
        x: t.x,
        y: t.y,
        width: t.width,
        height: t.height,
      };
      //右側
      if (isDuplicate(recomendedPos, labelPositions) === false)
        return recomendedPos;
      recomendedPos.x -= t.width + 10; //左側
      if (isDuplicate(recomendedPos, labelPositions) === false)
        return recomendedPos;
      return null;
    };

    for (const [j, line] of chart.line_graph.lines.entries()) {
      if (line.values[0].length === 0) continue;
      const lineDots = []; //座標目印用
      const lines = []; //折れ線
      const lineLabels = []; //グラフ名のラベル
      const linePointLabels = []; //グラフ各座標のラベル
      //折れ線グラフの最大値と最小値から絶対値を求める
      let valueRange =
        Math.max(...line.values.flat().map((v) => v.value)) -
        Math.min(...line.values.flat().map((v) => v.value));
      //グラフデータが1つしか無い場合、絶対値を1とする
      if (valueRange === 0) {
        valueRange = 1;
      }
      const valueMin = Math.min(...line.values.flat().map((v) => v.value));
      for (const [k, series] of line.values.entries()) {
        let polylinePoints = ""; //グラフごとに初期化
        for (const [i, value] of series.entries()) {
          //y座標の最大値から比率を出す
          const valueRelativeY = Number(
            ((value.value - valueMin) / valueRange).toFixed(3)
          );
          //座標ラベルは右上に描画する
          const valueLabelX = setPoint(value.time) + 2;
          const valueLabelY =
            maxY +
            (areaY - areaY * valueRelativeY) -
            4 +
            j * defaultFontsize * 2 -
            1;
          const [valueLabelHeight, valueLabelWidth] = calcHeightWidth(
            value.value.toString()
          );
          const recomendedPos = searchValueLabelPos({
            x: valueLabelX,
            y: valueLabelY,
            width: valueLabelWidth,
            height: valueLabelHeight,
          });
          if (recomendedPos !== null) {
            //各座標表示
            linePointLabels.push(
              React.createElement(
                "g",
                { id: `point${i}`, key: `point${i}:${j}:${k}` },
                [
                  React.createElement(
                    "text",
                    {
                      x: recomendedPos.x.toString(),
                      y: recomendedPos.y.toString(),
                      fontSize: defaultFontsize,
                      fill: getTab10Color(j),
                      key: `point${i}:${j}:${k}-text2`,
                    },
                    [value.value]
                  ),
                ]
              )
            );
            valueLabelPositions.push(recomendedPos);
          }
          //折れ線グラフの座標群
          polylinePoints =
            polylinePoints +
            setPoint(value.time).toString() +
            "," +
            (
              maxY +
              (areaY - areaY * valueRelativeY) +
              j * defaultFontsize * 2
            ).toString() +
            " ";
          //linedot
          lineDots.push(
            React.createElement(
              "circle",
              {
                cx: setPoint(value.time),
                cy: (
                  maxY +
                  (areaY - areaY * valueRelativeY) +
                  j * defaultFontsize * 2
                ).toString(),
                r: 3,
                fill: getTab10Color(j),
                key: `circle${i}:${j}:${k}`,
              },
              []
            )
          );
        }
        //グラフ描画
        lines.push(
          React.createElement(
            "polyline",
            {
              points: polylinePoints.trimEnd(),
              fill: "none",
              stroke: getTab10Color(j),
              key: `polyline${j}:${k}`,
            },
            [null]
          )
        );
      }

      //グラフ名
      const labelRelativeY = Number(
        ((line.values[0].slice(-1)[0].value - valueMin) / valueRange).toFixed(3)
      );
      const [labelHeight, labelWidth] = calcHeightWidth(line.label);
      const labelX = setPoint(line.values[0].slice(-1)[0].time) + 5;
      const labelY =
        maxY + (areaY - areaY * labelRelativeY) + j * defaultFontsize * 2 + 4;
      const recomendedPos = searchLabelPos({
        x: labelX,
        y: labelY,
        width: labelWidth,
        height: labelHeight,
      });
      if (recomendedPos !== null) {
        lineLabels.push(
          React.createElement(
            "g",
            {
              id: "linelabel" + line.label,
              key: "linelabel" + line.label,
            },
            [
              React.createElement(
                "text",
                {
                  x: recomendedPos.x,
                  y: recomendedPos.y,
                  fontSize: defaultFontsize,
                  fill: "rgb(0, 0, 0)",
                  key: "linelabel" + line.label + "text2",
                },
                [line.label]
              ),
            ]
          )
        );
        labelPositions.push(recomendedPos);
      }
      //一つにまとめる
      lineGroup.push(
        React.createElement(
          "g",
          {
            id: line.label,
            key: line.label,
          },
          [linePointLabels, lineDots, lines, lineLabels]
        )
      );
    }
  }
}

export function getTab10Color(i: number): string {
  //グラフを色分けする
  const color_v = i % 10;
  //数値に対応する色を返す。1*15は10色を返すための調整値
  const t: number[] = Evaluate_cmap(
    (1 * 15 + color_v * 25.6) / 256,
    "tab10",
    false
  );
  return "rgb(" + t[0] + "," + t[1] + "," + t[2] + ")";
}

export function getTab10Color75(i: number): string {
  const color_v = i % 10;
  const t: number[] = Evaluate_cmap(
    (1 * 15 + color_v * 25.6) / 256,
    "tab10",
    false
  );
  return "rgba(" + t[0] + "," + t[1] + "," + t[2] + ", 0.75)";
}

export function SetCheckbox(chart: Chart) {
  const dispatch = useAppDispatch();
  const checkLines = useAppSelector(selectCheckLines);
  //チェックボックスを作成する
  const checkprop: CheckLine[] = [];

  if (chart.line_graph !== null && chart.line_graph !== undefined) {
    chart.line_graph.lines.forEach((e, i, arr) => {
      checkprop.push({ setNo: i, labelname: e.label, flg: true });
    });
  }

  if (checkprop !== null && checkprop !== undefined) {
    if (checkprop.length !== 0) {
      dispatch(mergeCheckLines(checkprop));
    }
  }

  checkLines.forEach((x, i, arr) => {
    disp_none(x.flg, x.labelname);
  });

  function onoff(
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    label: string
  ) {
    dispatch(setCheckLineFlg({ index: i, flg: e.target.checked }));
    disp_none(e.target.checked, label);
  }

  function disp_none(d: boolean, labelname: string) {
    const element = document.getElementById(labelname);
    if (element !== null && element !== undefined) {
      if (!d) {
        element!.style.display = "none";
      } else if (d && element!.style !== null) {
        element!.style.removeProperty("display");
      }
    }
  }

  return (
    <>
      <aside>
        <ul className="menu-list">
          {[...checkLines]
            .sort((a, b) => a.setNo - b.setNo)
            .map((a) => (
              <label
                className="checkbox "
                style={{
                  color: getTab10Color(a.setNo),
                  fontSize: 16,
                  paddingRight: "1em",
                }}
                key={`${a.setNo}_${a.labelname}label`}
              >
                <input
                  type="checkbox"
                  style={{ marginRight: "0.2em" }}
                  id={`${a.setNo}_${a.labelname}`}
                  key={`${a.setNo}_${a.labelname}`}
                  checked={a.flg}
                  onChange={(e) => onoff(e, a.setNo, a.labelname)}
                />
                {a.labelname}
              </label>
            ))}
        </ul>
      </aside>
    </>
  );
}

function downloadBlob(blob: Blob, filename: string) {
  const fileReader = new FileReader();
  fileReader.onload = function () {
    var dataURI = this.result?.toString() || "";
    const link = document.createElement("a");
    link.href = dataURI;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  fileReader.readAsDataURL(blob);
}

export const GraphArea = () => {
  const dispatch = useAppDispatch();
  const chartData = useAppSelector(selectChartData);
  const chartSize = useAppSelector(selectChartSize);

  let propscheck = false;
  let linecheck = false;
  // Note: チャート描画処理でソート処理などを行うため、破壊可能なようにチャートデータをクローンする
  const chartDataClone =
    chartData === null ? null : lodash.cloneDeep(chartData);

  if (chartData !== null && chartData !== undefined) {
    setSize({ ...chartSize });
    setLineLabel(chartDataClone!);
    drawGraph(chartDataClone!);

    if (
      chartDataClone!.line_graph !== null &&
      chartDataClone!.line_graph !== undefined
    ) {
      if (chartDataClone!.line_graph.lines.length > 0) {
        linecheck = true;
      }
    }
    propscheck = true;
  }
  const svgRef = useRef<HTMLDivElement>(null);
  const downloadSVG = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => {
      if (e) e.preventDefault();
      const svg = svgRef.current?.innerHTML;
      const blob = new Blob([svg ? svg : ""], { type: "image/svg+xml" });
      const filename = dayjs().format("YYYYMMDD_HHmmss").toString() + ".svg";
      downloadBlob(blob, filename);
      if (isMobile()) {
        setTimeout(ModalOpenClose("svggenerated"), 0);
      }
    },
    []
  );
  window.doc2chartFunc.ClickDownloadSVG = () => {
    downloadSVG(null);
  };
  const zoomIn = () => {
    dispatch(
      setChartSize({
        contentHeight: chartSize.contentHeight,
        contentWidth: chartSize.contentWidth,
        zoomRate: Math.min(35.52713678800501, chartSize.zoomRate * 1.25),
      })
    );
  };

  const zoomOut = () => {
    dispatch(
      setChartSize({
        contentHeight: chartSize.contentHeight,
        contentWidth: chartSize.contentWidth,
        zoomRate: Math.max(0.32768, chartSize.zoomRate / 1.25),
      })
    );
  };

  return (
    <>
      <Measure
        bounds
        onResize={(contentRect) => {
          //submenuの幅（120）、boxの左右のpadding（12*2）を引いた値を描画エリアとする
          dispatch(
            setChartSize({
              contentWidth: contentRect.bounds!.width,
              contentHeight: contentRect.bounds!.height,
              zoomRate: chartSize.zoomRate,
            })
          );
        }}
      >
        {({ measureRef }) => (
          <div
            className="columns is-marginless is-radiusless is-mobile"
            ref={measureRef}
          >
            <div className="column ">
              <div className="box is-marginless is-radiusless p-0">
                {chartDataClone?.error_message.map((e, i) => (
                  <p key={`error_message_${i}`}>{e}</p>
                ))}
                <div
                  style={{
                    overflowX: "auto",
                    width: chartSize.screenWidth,
                  }}
                >
                  <div ref={svgRef} style={{ width: chartSize.width }}>
                    {propscheck ? (
                      <svg
                        viewBox={svgView}
                        id="svgarea"
                        key="svgarea"
                        data-testid="chartSvg"
                      >
                        <g id="chartdraw" key="chartdraw">
                          {xline}
                          {verticalLines ? verticalLines : null}
                          {labelSvg}
                          {eventText}
                          {stack}
                          {stackText}
                          {lineGroup}
                        </g>
                      </svg>
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  backgroundColor: "white",
                }}
              >
                <div style={{ marginRight: "auto", marginLeft: "1.4em" }}>
                  {linecheck && <SetCheckbox {...chartDataClone!} />}
                </div>
                <div
                  style={{
                    position: "relative",
                    top: "-46px",
                    marginRight: "16px",
                  }}
                >
                  <button
                    className="submenu"
                    key="submenu4"
                    onClick={zoomIn}
                    data-testid="zoomIn"
                  >
                    <p>
                      <AiOutlineZoomIn size={30} />
                    </p>
                  </button>
                </div>
                <div
                  style={{
                    position: "relative",
                    top: "-46px",
                    marginRight: "24px",
                  }}
                >
                  <button
                    className="submenu"
                    key="submenu5"
                    onClick={zoomOut}
                    data-testid="zoomOut"
                  >
                    <p>
                      <AiOutlineZoomOut size={30} />
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Measure>
    </>
  );
};

export default GraphArea;
