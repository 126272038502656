import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useAppSelector, useAppDispatch } from "../app/hooks";

import {
  selectFindingsText,
  FindingsText,
  addFindingsToIdbThunk,
  selectChartData,
} from "../app/appSlice";
import dayjs from "dayjs";
import lodash from "lodash";
import { Inputdata } from "../components/IDB/IndexDb";

const Save = () => {
  const dispatch = useAppDispatch();
  const findingsText = useAppSelector(selectFindingsText);
  const chartData = useAppSelector(selectChartData);
  const titleRef: any = useRef();
  const memoRef: any = useRef();

  function InputTextData(tit: any, mem: any, findingsText: FindingsText) {
    const ft = lodash.cloneDeep(findingsText);
    if (ft === null) {
      return;
    }
    const checkdate: Inputdata = {
      title: tit.current.value,
      text: ft,
      memo: mem.current.value,
      save_date: dayjs().format("YYYYMMDDTHHmmss+0900"),
      create_date: dayjs().format("YYYYMMDDTHHmmss+0900"),
      chartData: chartData,
    };
    //IDBに追加
    dispatch(addFindingsToIdbThunk(checkdate));
  }
  return (
    <>
      <main>
        <nav
          className="navbar is-light is-mobile"
          role="navigation"
          aria-label="main navigation mobile"
        >
          <div id="menubar" style={{ height: "70px" }}>
            <div className="navbar-start " id="navbar-size">
              <p className="navbar-item is-size-7 has-text-centered">
                <Link to="/">
                  <AiOutlineArrowLeft size={30} />
                  <br />
                  戻る
                </Link>
              </p>
            </div>
          </div>
        </nav>
        <div className="columns is-marginless is-flex-tablet-only">
          <div className="column ">
            <div className="box">
              <div className="field is-horizontal">
                <div className="field-label is-normal ">
                  <label className="label">保存日時</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded ">
                      <input
                        className="input"
                        value={dayjs().format("YYYY-MM-DD hh:mm:ss")}
                        readOnly
                        style={{ width: "200px" }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">タイトル</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control  ">
                      <input
                        className="input"
                        ref={titleRef}
                        placeholder="タイトルを入力してください"
                        style={{ width: "300px" }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">メモ</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="メモ"
                        ref={memoRef}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body has-text-right">
                  <div className="field">
                    <div className="control">
                      <Link
                        to={{ pathname: "/" }}
                        onClick={(e) =>
                          InputTextData(titleRef, memoRef, findingsText!)
                        }
                      >
                        <button className="button is-light">保存</button>
                      </Link>
                      <Link to="/">
                        <button className="button is-light">キャンセル</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Save;
