import { Chart } from "../app/appSlice";
import dayjs from "dayjs";
import { ModalOpenClose } from "./main";

/**
 * CSV文字列を作成します。
 * @param chartData グラフ出力用state
 * @returns CSVテキスト
 */
export default function generateCSV(chartData: Chart | null) {
  const csv = generateCSVCore(chartData);
  const blob = new Blob([csv], { type: "text/csv" });
  const filename = dayjs().format("YYYYMMDD_HHmmss").toString() + ".csv";
  const fileReader = new FileReader();
  fileReader.onload = function () {
    var dataURI = this.result?.toString() || "";
    const link = document.createElement("a");
    link.href = dataURI;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(ModalOpenClose("copysuccess"), 0);
  };
  fileReader.readAsDataURL(blob);
}

const getMedicineQuantity = (name: string, text: string) => {
  return text.replace(name, "").trim();
};

const formatDate = (isodate: string) => {
  return dayjs(isodate).format("YYYY/MM/DD");
};

export function generateCSVCore(chartData: Chart | null) {
  let csvText: string = "from,to,type,name,group,value\n";
  if (chartData === null) return csvText;

  //検査データ
  for (const line of chartData.line_graph.lines) {
    const maxSeriesNo = line.values.length;
    for (const [sno, series] of line.values.entries()) {
      const labelName =
        maxSeriesNo > 1 ? `${line.label}_${sno + 1}` : line.label;
      for (const result of series) {
        csvText += `${formatDate(result.time)},,test,${labelName},,${
          result.value
        }\n`;
      }
    }
  }
  //薬剤データ
  for (const box of chartData.stack_graph.boxes) {
    csvText += `${formatDate(box.from)},${
      box.isContinue ? "" : formatDate(box.to)
    },medicine,${box.name},${box.groupName || ""},${
      box.height === 0 ? 0 : getMedicineQuantity(box.name, box.label)
    }\n`;
  }
  //イベントデータ
  for (const event of chartData.event_graph.events) {
    let csvColValue = event.name.replaceAll('"', '""');
    if (csvColValue.indexOf(",") >= 0) {
      csvColValue = '"' + csvColValue + '"';
    }
    csvText += `${formatDate(event.time)},,event,${csvColValue},,\n`;
  }
  return csvText;
}
