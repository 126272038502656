import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineArrowLeft,
  AiOutlineClose,
  AiFillCaretDown,
  AiFillCaretUp,
} from "react-icons/ai";
import { IconType } from "react-icons/lib";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  updateFindingsText,
  updateParsed,
  selectFindingsText,
  FindingsText,
} from "../app/appSlice";
import { deleteDictAll } from "../app/dictSlice";
import { setEditorState } from "../app/editorSlice";
import { EditorState, convertFromRaw } from "draft-js";
import decorator from "../components/noteEditor/decorator";
import dayjs from "dayjs";
import "./mystyle.scss";
import {
  deleteFindingsFromIdb,
  deleteIdb,
  getFindingsFromIdb,
} from "../components/IDB/IndexDb";
import { ASC, DESC, SortDirection } from "../app/define";

interface Content {
  index: number; //表示上のインデックス
  No: number; //IDB上のインデックス
  title: string; //保存タイトル
  text: FindingsText;
  memo: string;
  save_date: string; //保存日付
}

function modalOpenClose() {
  if (window.confirm("データベースをすべて初期化します。\nよろしいですか？")) {
    let d = document.getElementById("idb_delete");
    if (d !== null && d !== undefined) {
      if (d!.className === "modal") {
        d!.classList.add("is-active");
      } else if (d!.className === "modal is-active") {
        d!.classList.remove("is-active");
      }
    }
  }
}

const Open = () => {
  const dispatch = useAppDispatch();
  const findingsText = useAppSelector(selectFindingsText);
  const setting: sorttypes = { type: ASC, icon: AiFillCaretUp };
  const [sorttype, setSorttype] = useState<sorttypes>(setting);
  const [contents, setContents] = useState<Content[]>([]);

  function setData(idx: number) {
    const newFindingsText = {
      No: contents[idx].No.toString(),
      title: contents[idx].title,
      memo: contents[idx].memo,
      text: contents[idx].text.text!,
    };
    dispatch(updateParsed(null));
    dispatch(updateFindingsText(newFindingsText));

    // Redux toolkit化に伴い、NoteEditor.tsxの初期化ロジックより移動
    // Editor状態を保持するStateを定義
    let newState: EditorState;
    if (newFindingsText && newFindingsText.text) {
      newState = EditorState.createWithContent(
        convertFromRaw(newFindingsText.text),
        decorator
      );
    } else {
      // 初期値なし
      newState = EditorState.createEmpty(decorator);
    }
    dispatch(setEditorState(newState));
  }

  function deleteAll() {
    //store内も初期化する
    dispatch(
      updateFindingsText({ No: "", title: "<Untitled>", memo: "", text: null })
    );
    dispatch(updateParsed(null));
    dispatch(deleteDictAll());
    deleteIdb();
  }

  function sendDelete(n: number, idx: number, t: string) {
    if (window.confirm(`「${t}」を削除します。\nよろしいですか？`)) {
      //表示上のインデックス(finding内の要素番号)
      let i_disp: number = idx;
      const newFinding = [...contents];
      newFinding.splice(i_disp, 1);
      setContents(newFinding);
      //削除対象のデータをメインページで開いていた場合、メインページを初期化する
      if (findingsText && t === findingsText!.title) {
        dispatch(
          updateFindingsText({
            No: "",
            title: "<Untitled>",
            memo: "",
            text: null,
          })
        );
        dispatch(updateParsed(null));
      }
      //IDB上のインデックスを渡す
      deleteFindingsFromIdb(n);
    }
  }

  //昇順・降順入れ替え用のソートタイプ
  interface sorttypes {
    type: SortDirection;
    icon: IconType;
  }

  //↑ソート用サンプルデータ↑
  /**
   * dataSort:IDB内のテキストデータを並べ替える
   * @param -
   * @return -
   */
  const getCompareFunc = (order: SortDirection) => (a: Content, b: Content) =>
    order === ASC
      ? a.save_date > b.save_date
        ? -1
        : 1
      : a.save_date > b.save_date
      ? 1
      : -1;

  function dataSort() {
    setContents([...contents.sort(getCompareFunc(sorttype.type))]);
    setSorttype({
      ...sorttype,
      type: sorttype.type === ASC ? DESC : ASC,
      icon: sorttype.type === ASC ? AiFillCaretDown : AiFillCaretUp,
    });
  }

  useEffect(() => {
    (async () => {
      const dataset = await getFindingsFromIdb();
      if (dataset !== undefined && dataset !== null && dataset.length !== 0) {
        let setindex: Content[] = [];
        dataset.forEach((e, i) => {
          setindex.push({
            No: e.No,
            index: i,
            title: e.title,
            save_date: e.save_date,
            memo: e.memo,
            text: e.text,
          });
        });
        if (setindex.length !== 0) {
          setindex.sort(getCompareFunc(ASC));
          setSorttype({ type: DESC, icon: AiFillCaretDown });
        }
        setContents(setindex);
      }
    })();
  }, []);
  return (
    <>
      <main>
        <nav
          className="navbar is-light is-mobile"
          role="navigation"
          aria-label="main navigation mobile"
        >
          <div id="menubar" style={{ height: "70px" }}>
            <div className="navbar-start " id="navbar-size">
              <p className="navbar-item is-size-7 has-text-centered">
                <Link to="/">
                  <AiOutlineArrowLeft size={30} />
                  <br />
                  戻る
                </Link>
              </p>
            </div>
          </div>
        </nav>
        <div className="columns is-marginless is-flex-tablet-only">
          <div className="column ">
            <div className="box has-text-centered table-container ">
              <table className="table is-bordered is-narrow is-hoverable is-fullwidth is-striped has-text-centered tablesorter ">
                <thead>
                  <tr>
                    <th style={{ textAlign: "left", borderRight: "none" }}>
                      タイトル
                    </th>
                    <th style={{ width: "12em" }}>
                      保存日時{" "}
                      <button
                        className="link-button"
                        style={{ border: "none" }}
                        onClick={dataSort}
                      >
                        <sorttype.icon style={{ verticalAlign: "text-top" }} />
                      </button>
                    </th>
                    <th
                      style={{
                        width: "4em",
                        textAlign: "center",
                        borderLeft: "none",
                      }}
                    >
                      削除
                    </th>
                  </tr>
                </thead>
                <tbody className="is-scrollable">
                  {contents.map((data: Content, idx, arr) => (
                    <tr id={data.No.toString()} key={data.No.toString()}>
                      <td style={{ textAlign: "left" }}>
                        <Link
                          className="clickableArea"
                          to={{ pathname: "/" }}
                          onClick={(e) => setData(idx)}
                        >
                          {data.title}
                        </Link>
                      </td>

                      <td>
                        <Link
                          className="clickableArea"
                          to={{ pathname: "/" }}
                          onClick={(e) => setData(idx)}
                        >
                          {dayjs(data.save_date.substr(0, 15)).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </Link>
                      </td>
                      <td>
                        <button
                          className="iconbutton"
                          onClick={(e) => sendDelete(data.No, idx, data.title)}
                        >
                          <AiOutlineClose
                            size={25}
                            style={{ verticalAlign: "sub" }}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="modal" id="idb_delete">
                <div className="modal-background"></div>
                <div className="modal-content">
                  <div className="box">
                    <p>IDBを削除しデータを初期化します。</p>
                    <p>
                      編集中のテキスト、辞書登録した単語データも全て失われます。
                    </p>
                    <p>よろしいですか？</p>
                    <div className="field is-horizontal ">
                      <div className="field-body has-text-center">
                        <div className="field">
                          <div className="control">
                            <Link
                              to={{ pathname: "/" }}
                              onClick={() => deleteAll()}
                            >
                              <button
                                className="button is-light"
                                style={{ color: "rgb(173, 33, 33)" }}
                              >
                                初期化する
                              </button>
                            </Link>
                            <button
                              className="button is-light"
                              style={{ color: "rgb(173, 33, 33)" }}
                              onClick={(e) => modalOpenClose()}
                            >
                              キャンセル
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal ">
                <div className="field-body has-text-left">
                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-light"
                        style={{ color: "rgb(173, 33, 33)" }}
                        onClick={(e) => modalOpenClose()}
                      >
                        IDB初期化
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Open;
