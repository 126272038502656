/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { GiMedicines } from "react-icons/gi";
import { VscGraphLine } from "react-icons/vsc";
import { BsCalendar2Event, BsExclude } from "react-icons/bs";
import { GoDiffRemoved } from "react-icons/go";
import { EVENT, MEDICINE, TESTITEM, MUTE, DictKind } from "../../app/define";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectFindingsText,
  updateParsed,
  updateText,
} from "../../app/appSlice";
import {
  selectMedicineDict,
  selectTestDict,
  DictObj,
  addDictToIdbThunk,
  deleteDictFromIdbThunk,
  Dict,
  putDictToIdbThunk,
} from "../../app/dictSlice";
import { selectEditorState, setEditorState } from "../../app/editorSlice";
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  SelectionState,
  getDefaultKeyBinding,
  DraftHandleValue,
} from "draft-js";
import decorator from "./decorator";
import {
  FindingsTextParser,
  h2z_kana,
  z2h,
  z2h_kana,
} from "./findingsTextParser";
import {
  checkDup,
  checkReplaceMessage,
  emptyDict,
} from "../../common/dictUtil";
import dayjs from "dayjs";

function usePrevious<T>(props: T | null): T | null {
  const previousValue = React.useRef<T | null>(null);
  React.useEffect(() => {
    previousValue.current = props;
  });
  return previousValue.current;
}

enum ParseRequest {
  /**
   * 次のchangeイベントで変更差分の行のみパース
   */
  PARSE_CHANGED = "CHANGED",
  /**
   * 次のchangeイベントで全ての行をパース
   */
  PARSE_ALL = "ALL",
  /**
   * 次のchangeイベントではパース処理を行わない
   */
  PARSE_NONE = "NONE",
}

export const NoteEditor = () => {
  const dispatch = useAppDispatch();
  const medicineDict = useAppSelector(selectMedicineDict);
  const testitemDict = useAppSelector(selectTestDict);
  const findingsText = useAppSelector(selectFindingsText);
  const editorState = useAppSelector(selectEditorState);

  const prevMedicineDict = usePrevious(medicineDict);
  const prevTestDict = usePrevious(testitemDict);

  /**
   * trueで次のchangeイベントでテキスト解析を実行する
   */
  const requestParseOnNextChange = React.useRef(ParseRequest.PARSE_NONE);

  useEffect(() => {
    if (
      findingsText &&
      findingsText.text === null &&
      findingsText.parsed === null
    ) {
      // テキストエディタを初期化
      requestParseOnNextChange.current = ParseRequest.PARSE_ALL;
      onChange(EditorState.createEmpty(decorator));
    }
  });

  // Keybindカスタマイズ用のハンドラ
  // キーボード処理のフック関数として使用している
  const keyBindingFn = (e: React.KeyboardEvent): string | null => {
    switch (e.key) {
      case "ArrowUp":
      case "ArrowDown":
        requestParseOnNextChange.current = ParseRequest.PARSE_CHANGED;
        break;
      default:
        break;
    }
    return getDefaultKeyBinding(e);
  };

  // changeイベントのハンドラ
  const onChange = (changed: EditorState) => {
    dispatch(setEditorState(changed));
    // 変更テキストを通知
    dispatch(updateText(convertToRaw(changed.getCurrentContent())));
    if (requestParseOnNextChange.current !== ParseRequest.PARSE_NONE) {
      // 保留されているパース処理を実行する
      const parseAll =
        requestParseOnNextChange.current === ParseRequest.PARSE_ALL;
      requestParseOnNextChange.current = ParseRequest.PARSE_NONE;
      triggerParse(changed, parseAll);
    }
  };

  // コマンド実行のハンドラ。Enterのような標準コマンドもここで認識できる
  const handleKeyCommand = (command: string, currentState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(currentState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    if (command === "split-block") {
      // Enterを検出したのでパース処理をトリガーする
      // ただし not-handle で fallback（デフォルト処理が行われた後) 処理が実行された後に
      // パース処理を実行したいので実行を次のonChangeまで保留する
      requestParseOnNextChange.current = ParseRequest.PARSE_CHANGED;
    } else if (command === "delete") {
      // 削除を検出したので 次の onChange で変更分のパースを行う
      requestParseOnNextChange.current = ParseRequest.PARSE_CHANGED;
    }
    return "not-handled";
  };

  // Returnキー押下のハンドラ
  const handleReturn = (
    e: any,
    currentState: EditorState
  ): DraftHandleValue => {
    return "not-handled";
  };

  // テキストペーストのハンドラ
  const handlePastedText = (
    text: string,
    _: string,
    state: EditorState
  ): DraftHandleValue => {
    //全て再描画する。
    requestParseOnNextChange.current = ParseRequest.PARSE_ALL;
    //選択範囲を消去する。
    let content = Modifier.removeRange(
      state.getCurrentContent(),
      state.getSelection(),
      "forward"
    );
    let modState = EditorState.push(state, content, "remove-range");
    //ペーストされたテキストを貼り付ける。
    content = Modifier.insertText(
      modState.getCurrentContent(),
      modState.getSelection(),
      text
    );
    modState = EditorState.push(modState, content, "insert-characters");
    //ブロックに分割する。
    let splitted = true;
    while (splitted) {
      splitted = false;
      let modContent = modState.getCurrentContent();
      const blocks = modContent.getBlocksAsArray();
      for (const block of blocks) {
        const blockText = block.getText();
        const newLinePos = blockText.search(/[\n\v]/);
        if (newLinePos >= 0) {
          const selState = SelectionState.createEmpty(block.getKey()).merge({
            anchorOffset: newLinePos,
            focusOffset: newLinePos + 1,
          });
          modContent = Modifier.splitBlock(modContent, selState);
          modState = EditorState.push(state, modContent, "split-block");
          splitted = true;
          break;
        }
      }
    }
    onChange(modState);
    return "handled";
  };

  /**
   * 最新のEditorStateに対してパース処理を実行し、変化があればeditorStateを更新する
   * @param currentState: 現在のEditorState
   * @param parseAll trueであれば全てのブロック(行)を対象とする。falseであれば更新差分のみ対象とする
   */
  const triggerParse = (currentState: EditorState, parseAll?: boolean) => {
    // 現在の選択状態（カーソル位置）を保存しておく
    const selection = currentState.getSelection();
    // パース処理を適用
    const findingsTextParser = new FindingsTextParser(
      editorState,
      findingsText,
      medicineDict,
      testitemDict,
      null,
      null
    );
    let { parsedState, parseUpdated, parseBlocks, parsed } =
      findingsTextParser.executeParseAndUpdate(currentState, parseAll);
    // 変更を検出した場合か、行が空になった場合に通知
    if (parseUpdated || parseBlocks.length === 0) {
      dispatch(updateParsed(parsed));
    }
    if (parsedState) {
      // カーソル位置を復元
      if (selection) {
        parsedState = EditorState.forceSelection(parsedState, selection);
      }
      // 変更を次のeditorStateとするために保存
      setTimeout(() => {
        if (parsedState) onChange(parsedState);
      }, 0);
    }
  };

  /**
   * エディターで現在選択されている語を返す
   * @returns 選択されている語。選択語がない場合はnull
   */
  const getSelectionWord = (): string | null => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    if (!selection) {
      return null;
    }
    const anchor = selection.getAnchorKey();
    const block = content.getBlockForKey(anchor);
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selText = block.getText().slice(start, end);
    if (selText.length <= 0 || selText.match(/^\s*$/)) {
      return null;
    }
    return selText;
  };

  /**
   * 選択語を辞書に登録する
   */

  const setDictItem = (dict: Dict, kind: DictKind) => ({
    dict: dict,
    kind: kind,
  });

  const saveNewDictItem = (dict: Dict, kind: DictKind) => {
    dispatch(addDictToIdbThunk(setDictItem(dict, kind)));
  };

  const replaceDictitem = async (
    dict: Dict,
    kind: DictKind,
    medicine: Dict | undefined,
    testitem: Dict | undefined
  ) => {
    if (checkReplaceMessage(medicine, testitem) === false) return false;
    await dispatch(
      deleteDictFromIdbThunk({
        word: (medicine && medicine.word) || (testitem && testitem.word) || "",
        kind: medicine ? MEDICINE : TESTITEM,
      })
    );
    saveNewDictItem(dict, kind);
    return true;
  };

  /**
   * 選択語を検査辞書に登録する
   */
  const onDictCandidateClicked = (kind: DictKind) => {
    // 選択語を認識する
    let selText = getSelectionWord();
    if (selText === null) {
      return;
    }
    selText = z2h(selText);
    const selTextH = z2h_kana(selText);
    const selTextZ = h2z_kana(selText);
    const aliases = [];
    if (selText !== selTextH) aliases.push(selTextH);
    if (selText !== selTextZ) {
      aliases.push(selText);
      selText = selTextZ;
    }
    // 重複を確認する
    const { findMedicine, findTestitem } = checkDup(
      emptyDict(selText, aliases),
      Object.values(medicineDict),
      Object.values(testitemDict)
    );
    if (!findMedicine && !findTestitem) {
      // 重複がない場合、辞書に単純登録する
      saveNewDictItem(emptyDict(selText, aliases), kind);
    } else {
      // 重複がある場合、ポップアップ確認を行い、削除後登録する。
      replaceDictitem(
        emptyDict(selText, aliases),
        kind,
        findMedicine,
        findTestitem
      );
    }
  };

  /**
   * 選択語をイベントとして認識する
   */
  const onEventWord = () => {
    // 選択語を認識する
    const selText = getSelectionWord();
    if (selText === null) {
      return;
    }

    // 選択語をイベントエンティティとして設定する
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      EVENT,
      "IMMUTABLE",
      {
        name: selText,
      }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    const newContent = Modifier.applyEntity(
      newState.getCurrentContent(),
      newState.getSelection(),
      entityKey
    );
    const modState = EditorState.push(newState, newContent, "apply-entity");

    // 変更を次のeditorStateとするために保存
    requestParseOnNextChange.current = ParseRequest.PARSE_ALL;
    onChange(modState);
  };

  const findDict = (word: string, kind: DictKind): Dict | undefined => {
    const dicts = kind === MEDICINE ? medicineDict : testitemDict;
    if (dicts[word]) return dicts[word];
    return Object.values(dicts).find((dict) => {
      return dict.aliases.indexOf(word) !== -1;
    });
  };
  /**
   * カーソルに続く位置にあるエンティティ（または辞書登録）を削除する
   */
  const onRemoveEntity = () => {
    // カーソル位置のブロックを認識する
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    if (!selection) {
      return null;
    }
    const anchor = selection.getAnchorKey();
    const block = content.getBlockForKey(anchor);

    // カーソル位置にあるエンティティを認識する
    const start = selection.getStartOffset();
    const entityKey = block.getEntityAt(start);
    if (!entityKey) {
      return;
    }
    const entity = content.getEntity(entityKey);

    // エンティティ削除、または辞書登録削除を実行する
    const etype = entity.getType();
    const dataName = z2h(entity.getData()["name"]);
    switch (etype) {
      case EVENT:
      case MUTE:
        // イベントまたはMUTEエンティティの場合は、エンティティ単体を削除する
        // 語の途中にカーソルがあることを考慮して現在のエンティティの先頭まで遡る
        let entityPos = start - 1;
        while (entityPos >= 0) {
          const ek = block.getEntityAt(entityPos);
          if (ek !== entityKey) {
            // 一つ前の文字まで遡っているので位置を戻して終了
            entityPos++;
            break;
          }
          entityPos--;
        }
        // この位置にあるエンティティを削除
        const newss = SelectionState.createEmpty(block.getKey()).merge({
          anchorOffset: entityPos,
          focusOffset: entityPos + dataName.length,
        });
        const newContent = Modifier.applyEntity(content, newss, null);
        const modState = EditorState.push(
          editorState,
          newContent,
          "apply-entity"
        );
        requestParseOnNextChange.current = ParseRequest.PARSE_ALL; // テキスト変更はないため全パース
        onChange(modState);
        break;
      case MEDICINE:
        const medicine = findDict(dataName, MEDICINE);
        if (medicine) {
          if (dataName === medicine.word) {
            if (
              medicine.aliases.length > 0 &&
              window.confirm(
                `${
                  medicine.word
                }を削除する場合、別名定義(${medicine.aliases.toString()})が同時に削除されます。\nよろしいですか？`
              ) === false
            )
              break;
            dispatch(
              deleteDictFromIdbThunk({ word: medicine.word, kind: MEDICINE })
            );
          } else {
            const aliases = medicine.aliases.filter(
              (alias) => alias !== dataName
            );
            dispatch(
              putDictToIdbThunk({
                dict: { ...medicine, aliases, save_date: dayjs().format() },
                kind: MEDICINE,
              })
            );
          }
        }
        break;
      case TESTITEM:
        const testitem = findDict(dataName, TESTITEM);
        if (testitem) {
          if (dataName === testitem.word) {
            if (
              testitem.aliases.length > 0 &&
              window.confirm(
                `${
                  testitem.word
                }を削除する場合、別名定義(${testitem.aliases.toString()})が同時に削除されます。\nよろしいですか？`
              ) === false
            )
              break;
            dispatch(
              deleteDictFromIdbThunk({ word: testitem.word, kind: TESTITEM })
            );
          } else {
            const aliases = testitem.aliases.filter(
              (alias) => alias !== dataName
            );
            dispatch(
              putDictToIdbThunk({
                dict: { ...testitem, aliases, save_date: dayjs().format() },
                kind: TESTITEM,
              })
            );
          }
        }
        break;
    }
  };

  /**
   * カーソルに続く位置にあるエンティティを除去し Mute エンティティに変更する
   */
  const onMute = () => {
    // カーソル位置のブロックを認識する
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    if (!selection) {
      return null;
    }
    const anchor = selection.getAnchorKey();
    const block = content.getBlockForKey(anchor);

    // カーソル位置にあるエンティティを認識する
    const start = selection.getStartOffset();
    const entityKey = block.getEntityAt(start);
    if (!entityKey) {
      return;
    }
    const entity = content.getEntity(entityKey);
    const dataName = entity.getData()["name"];

    // 語の途中にカーソルがあることを考慮して現在のエンティティの先頭まで遡る
    let entityPos = start - 1;
    while (entityPos >= 0) {
      const ek = block.getEntityAt(entityPos);
      if (ek !== entityKey) {
        // 一つ前の文字まで遡っているので位置を戻して終了
        entityPos++;
        break;
      }
      entityPos--;
    }

    // この位置にあるエンティティを削除
    let newss = SelectionState.createEmpty(block.getKey()).merge({
      anchorOffset: entityPos,
      focusOffset: entityPos + dataName.length,
    });
    let newContent = Modifier.applyEntity(content, newss, null);
    let modState = EditorState.push(editorState, newContent, "apply-entity");

    // この位置にmuteエンティティを設定
    const contentState = modState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      MUTE,
      "IMMUTABLE",
      {
        name: dataName,
      }
    );
    const muteKey = contentStateWithEntity.getLastCreatedEntityKey();
    modState = EditorState.set(modState, {
      currentContent: contentStateWithEntity,
    });
    newss = SelectionState.createEmpty(block.getKey()).merge({
      anchorOffset: entityPos,
      focusOffset: entityPos + dataName.length,
    });
    newContent = Modifier.applyEntity(content, newss, muteKey);
    modState = EditorState.push(editorState, newContent, "apply-entity");

    requestParseOnNextChange.current = ParseRequest.PARSE_ALL; // テキスト変更はないため全パース
    onChange(modState);
  };

  /**
   * 辞書の変更を検出する
   * @param prev 前の辞書
   * @param current 現在の辞書
   * @returns true: 変更あり
   */
  const detectDictChange = (
    prev: DictObj | null,
    current: DictObj
  ): boolean => {
    if (prev === null) {
      // 前の値が存在しない場合は変更あり
      return true;
    }

    // prev内の全てのkeyがcurrent内に存在するかを確認する
    const removed = Object.keys(prev).some(
      (key) =>
        current[key] === undefined ||
        current[key].aliases.toString() !== prev[key].aliases.toString()
    );
    if (removed) {
      // 辞書エントリーが削除されている場合は変更あり
      return true;
    }

    // current内の全てのkeyがprev内に存在するかを確認する
    const added = Object.keys(current).some(
      (key) =>
        prev[key] === undefined ||
        current[key].aliases.toString() !== prev[key].aliases.toString() ||
        current[key].useSingle !== prev[key].useSingle
    );
    if (added) {
      // 辞書エントリーが追加されている場合は変更あり
      return true;
    }

    // 何れにも該当しない場合は変更なし
    return false;
  };

  // 辞書の変更検出を行う
  const medicineChanged = detectDictChange(
    prevMedicineDict ? prevMedicineDict : null,
    medicineDict
  );
  const testitemChanged = detectDictChange(
    prevTestDict ? prevTestDict : null,
    testitemDict
  );
  if (medicineChanged || testitemChanged) {
    // 辞書の変更が検出されたので、レンダー終了後に全ての行を再パースする
    setTimeout(() => {
      requestParseOnNextChange.current = ParseRequest.PARSE_ALL;
      onChange(editorState);
    });
  }

  return (
    <>
      <div className="column ">
        <div className="box p-0">
          <div className="editor-wrapper" data-testid="editorWrapper">
            <Editor
              editorState={editorState}
              onChange={onChange}
              handleKeyCommand={handleKeyCommand}
              handleReturn={handleReturn}
              handlePastedText={handlePastedText}
              keyBindingFn={keyBindingFn}
            />
          </div>
        </div>
      </div>
      <div className="menu column  is-narrow" style={{ width: 80 }}>
        <aside className="box">
          <ul className="menu-list ">
            <button
              className="submenu"
              onClick={(e) => {
                onDictCandidateClicked(MEDICINE);
              }}
              data-testid="editorMedicine"
            >
              <p>
                <GiMedicines size={30} />
                <br />
                medicine
              </p>
            </button>
            <button
              className="submenu"
              onClick={(e) => {
                onDictCandidateClicked(TESTITEM);
              }}
              data-testid="editorTestitem"
            >
              <p>
                <VscGraphLine size={30} />
                <br />
                testitem
              </p>
            </button>
            <button
              className="submenu"
              onClick={onEventWord}
              data-testid="editorEvent"
            >
              <p>
                <BsCalendar2Event size={30} />
                <br />
                event
              </p>
            </button>
            <button
              className="submenu"
              onClick={onRemoveEntity}
              data-testid="editorRemove"
            >
              <p>
                <GoDiffRemoved size={30} />
                <br />
                remove
              </p>
            </button>
            <button
              className="submenu"
              onClick={onMute}
              data-testid="editorMute"
            >
              <p>
                <BsExclude size={30} />
                <br />
                mute
              </p>
            </button>
          </ul>
        </aside>
      </div>
    </>
  );
};
