import {
  CharacterMetadata,
  ContentBlock,
  convertFromRaw,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js";
import {
  ParsedData,
  FindingsText,
  PBlock,
  PEvent,
  PMedicine,
  PTest,
} from "../../app/appSlice";
import lodash from "lodash";
import { DateMatcher, normalizeDate } from "../../common/dateMatcher";
import {
  DictKind,
  EVENT,
  MEDICINE,
  MEDICINE_BGCOLOR_DEFAULT,
  TESTITEM,
  TIMESTAMP,
} from "../../app/define";
import { Dict, DictObj } from "../../app/dictSlice";
import * as fs from "fs";
import * as readline from "readline";
import dayjs from "dayjs";

enum TokenType {
  TOKEN_TIMESTAMP = "TIMESTAMP",
  TOKEN_ENTITY = "ENTITY",
  TOKEN_TEXT = "TEXT",
  TOKEN_MUTE = "MUTE",
}

interface EntityBody {
  type: string;
  data: any;
}
interface Token {
  /**
   * トークン種別
   */
  type: TokenType;
  /**
   * トークン文字列
   */
  text: string;
  /**
   * 継続行を含むブロック列のインデクス
   */
  blockNo: number;
  /**
   * ブロック内のトークン開始位置 (0-)
   */
  start: number;
  /**
   * トークンとして認識した文字列長 (textはトリミングされている場合がある)
   */
  len: number;
  /**
   * エンティティトークンの場合はエンティティ
   */
  entity?: EntityBody;
}

const reTm = DateMatcher.timestampMatchReg;
const CS_SPACE = [" ", "\t", "\r", "\n"];
const CS_ARROW = ["→", "⇒", "->", "=>"];
const CS_CONN = ["+"];
const CS_SPLIT = [",", "(", ")", "{", "}", "[", "]"];
const CS_STOP = [
  "中止",
  "休薬希望",
  "スキップ",
  "ｽｷｯﾌﾟ",
  "休薬",
  "OFF",
  "自己中断",
  "中断",
  "終了",
  "停止",
];
// 検査結果
const REG_TEST_AMOUNT =
  /^[^\d.-]*(\d*(?:\.(?:\d+)?)?)\s*(?:-\s*(\d*(?:\.(?:\d+)?)?)?)?/;
// 投薬間隔
const REG_PRES_INTERVAL = /((?:(\d+))?\/(\d+)?([dD日wW週mM月yY年]))\s*(.*)/;
// 投薬使用量
const REG_PRES_AMOUNT = /^[^\d.]*([\d]*[.]?[\d]*)\s*(\w+)?/;

const compileAuxTextSplitter = (): RegExp => {
  const escape = (pattern: string[]): string[] => {
    return pattern.map((str) =>
      str
        .replace("+", "\\+")
        .replace("(", "\\(")
        .replace(")", "\\)")
        .replace("[", "\\[")
        .replace("]", "\\]")
        .replace("{", "\\{")
        .replace("}", "\\}")
    );
  };
  const patterns = escape(
    CS_SPACE.concat(CS_SPACE, CS_ARROW, CS_CONN, CS_SPLIT)
  );
  return RegExp("^(" + patterns.join("|") + ")?(.*)$", "i");
};
const REG_AUX_TEXT_SPLITTER = compileAuxTextSplitter();

export const readTextFile = (filename: string): Promise<string[]> => {
  const stream = fs.createReadStream(filename);
  const reader = readline.createInterface({ input: stream });
  const lines: string[] = [];

  return new Promise<string[]>((resolve, reject) => {
    reader.on("line", (line) => {
      lines.push(line);
    });
    reader.on("close", () => {
      resolve(lines);
    });
  });
};

export const readJson = async (filename: string) => {
  const lines = await readTextFile(filename);
  try {
    return JSON.parse(lines.join(""));
  } catch (e) {
    console.log("JSON.parse error: " + filename);
    throw e;
  }
};

export const readText = async (filename: string) => {
  if (!fs.existsSync(filename)) return null;
  const lines = await readTextFile(filename);
  return lines.map((line) => line.trim()).join("\n") + "\n";
};

const kanaMapZ2h: { [key: string]: string } = {
  ガ: "ｶﾞ",
  ギ: "ｷﾞ",
  グ: "ｸﾞ",
  ゲ: "ｹﾞ",
  ゴ: "ｺﾞ",
  ザ: "ｻﾞ",
  ジ: "ｼﾞ",
  ズ: "ｽﾞ",
  ゼ: "ｾﾞ",
  ゾ: "ｿﾞ",
  ダ: "ﾀﾞ",
  ヂ: "ﾁﾞ",
  ヅ: "ﾂﾞ",
  デ: "ﾃﾞ",
  ド: "ﾄﾞ",
  バ: "ﾊﾞ",
  ビ: "ﾋﾞ",
  ブ: "ﾌﾞ",
  ベ: "ﾍﾞ",
  ボ: "ﾎﾞ",
  パ: "ﾊﾟ",
  ピ: "ﾋﾟ",
  プ: "ﾌﾟ",
  ペ: "ﾍﾟ",
  ポ: "ﾎﾟ",
  ヴ: "ｳﾞ",
  ヷ: "ﾜﾞ",
  ヺ: "ｦﾞ",
  ア: "ｱ",
  イ: "ｲ",
  ウ: "ｳ",
  エ: "ｴ",
  オ: "ｵ",
  カ: "ｶ",
  キ: "ｷ",
  ク: "ｸ",
  ケ: "ｹ",
  コ: "ｺ",
  サ: "ｻ",
  シ: "ｼ",
  ス: "ｽ",
  セ: "ｾ",
  ソ: "ｿ",
  タ: "ﾀ",
  チ: "ﾁ",
  ツ: "ﾂ",
  テ: "ﾃ",
  ト: "ﾄ",
  ナ: "ﾅ",
  ニ: "ﾆ",
  ヌ: "ﾇ",
  ネ: "ﾈ",
  ノ: "ﾉ",
  ハ: "ﾊ",
  ヒ: "ﾋ",
  フ: "ﾌ",
  ヘ: "ﾍ",
  ホ: "ﾎ",
  マ: "ﾏ",
  ミ: "ﾐ",
  ム: "ﾑ",
  メ: "ﾒ",
  モ: "ﾓ",
  ヤ: "ﾔ",
  ユ: "ﾕ",
  ヨ: "ﾖ",
  ラ: "ﾗ",
  リ: "ﾘ",
  ル: "ﾙ",
  レ: "ﾚ",
  ロ: "ﾛ",
  ワ: "ﾜ",
  ヲ: "ｦ",
  ン: "ﾝ",
  ァ: "ｧ",
  ィ: "ｨ",
  ゥ: "ｩ",
  ェ: "ｪ",
  ォ: "ｫ",
  ッ: "ｯ",
  ャ: "ｬ",
  ュ: "ｭ",
  ョ: "ｮ",
  "。": "｡",
  "、": "､",
  ー: "ｰ",
  "「": "｢",
  "」": "｣",
  "・": "･",
};
const regZ2h = new RegExp("(" + Object.keys(kanaMapZ2h).join("|") + ")", "g");

export const z2h_kana = (str: string) => {
  return str
    .replace(regZ2h, (s) => kanaMapZ2h[s])
    .replace(/゛/g, "ﾞ")
    .replace(/゜/g, "ﾟ");
};

const kanaMapH2z: { [key: string]: string } = {
  ｶﾞ: "ガ",
  ｷﾞ: "ギ",
  ｸﾞ: "グ",
  ｹﾞ: "ゲ",
  ｺﾞ: "ゴ",
  ｻﾞ: "ザ",
  ｼﾞ: "ジ",
  ｽﾞ: "ズ",
  ｾﾞ: "ゼ",
  ｿﾞ: "ゾ",
  ﾀﾞ: "ダ",
  ﾁﾞ: "ヂ",
  ﾂﾞ: "ヅ",
  ﾃﾞ: "デ",
  ﾄﾞ: "ド",
  ﾊﾞ: "バ",
  ﾋﾞ: "ビ",
  ﾌﾞ: "ブ",
  ﾍﾞ: "ベ",
  ﾎﾞ: "ボ",
  ﾊﾟ: "パ",
  ﾋﾟ: "ピ",
  ﾌﾟ: "プ",
  ﾍﾟ: "ペ",
  ﾎﾟ: "ポ",
  ｳﾞ: "ヴ",
  ﾜﾞ: "ヷ",
  ｦﾞ: "ヺ",
  ｱ: "ア",
  ｲ: "イ",
  ｳ: "ウ",
  ｴ: "エ",
  ｵ: "オ",
  ｶ: "カ",
  ｷ: "キ",
  ｸ: "ク",
  ｹ: "ケ",
  ｺ: "コ",
  ｻ: "サ",
  ｼ: "シ",
  ｽ: "ス",
  ｾ: "セ",
  ｿ: "ソ",
  ﾀ: "タ",
  ﾁ: "チ",
  ﾂ: "ツ",
  ﾃ: "テ",
  ﾄ: "ト",
  ﾅ: "ナ",
  ﾆ: "ニ",
  ﾇ: "ヌ",
  ﾈ: "ネ",
  ﾉ: "ノ",
  ﾊ: "ハ",
  ﾋ: "ヒ",
  ﾌ: "フ",
  ﾍ: "ヘ",
  ﾎ: "ホ",
  ﾏ: "マ",
  ﾐ: "ミ",
  ﾑ: "ム",
  ﾒ: "メ",
  ﾓ: "モ",
  ﾔ: "ヤ",
  ﾕ: "ユ",
  ﾖ: "ヨ",
  ﾗ: "ラ",
  ﾘ: "リ",
  ﾙ: "ル",
  ﾚ: "レ",
  ﾛ: "ロ",
  ﾜ: "ワ",
  ｦ: "ヲ",
  ﾝ: "ン",
  ｧ: "ァ",
  ｨ: "ィ",
  ｩ: "ゥ",
  ｪ: "ェ",
  ｫ: "ォ",
  ｯ: "ッ",
  ｬ: "ャ",
  ｭ: "ュ",
  ｮ: "ョ",
  "｡": "。",
  "､": "、",
  ｰ: "ー",
  "｢": "「",
  "｣": "」",
  "･": "・",
};
const regH2z = new RegExp("(" + Object.keys(kanaMapH2z).join("|") + ")", "g");

export const h2z_kana = (str: string) => {
  return str
    .replace(regH2z, function (s) {
      return kanaMapH2z[s];
    })
    .replace(/ﾞ/g, "゛")
    .replace(/ﾟ/g, "゜");
};

/**
 * 文字列中の全角英数字記号を半角に変換する
 * @param str 対象文字列
 * @returns 変換後文字列
 */
export const z2h = (str: string): string => {
  // 半角変換
  const halfVal = str.replace(/[！-～]/g, (src) => {
    // 文字コードをシフト
    return String.fromCharCode(src.charCodeAt(0) - 0xfee0);
  });

  // 文字コード変換外の文字を変換
  return halfVal
    .replace(/”/g, '"')
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
};

export const createState = (notes: string[]) => {
  const blocks = notes.map((note, idx) => ({
    key: idx.toString().padStart(6, "0"),
    text: note,
    type: "unstyled",
    depth: 0,
    entityRanges: [],
    inlineStyleRanges: [],
    data: {},
  }));
  return {
    initialState: EditorState.createWithContent(
      convertFromRaw({
        entityMap: {},
        blocks: blocks,
      })
    ),
    blocks: blocks,
  };
};

export const createDict = (dictnames: string[]) => {
  var dictObj: DictObj = {};
  dictnames.forEach((def) => {
    const [dictnameEntry, single, backgroundColor, groupName] = def.split(":");
    const dictname = dictnameEntry.split(",");
    dictObj[dictname[0]] = {
      word: dictname[0],
      aliases: dictname.slice(1),
      groupName: groupName,
      useSingle: single === undefined ? false : true,
      save_date: dayjs().format(),
      bgColorStyle: backgroundColor
        ? { backgroundColor: backgroundColor }
        : MEDICINE_BGCOLOR_DEFAULT,
    };
  });
  return dictObj;
};

export class FindingsTextParser {
  editorState: EditorState;
  findingsText: FindingsText | null;
  medicineDict: DictObj;
  medicinesWithAlias: Dict[];
  medicineAliasDict: { [alias: string]: Dict };
  testitemDict: DictObj;
  testitemsWithAlias: Dict[];
  testitemAliasDict: { [alias: string]: Dict };
  eventsForUnittest: [{ date: string; eventNames: string[] }] | [];
  mutesForUnittest:
    | [{ date: string; muteNames: [{ name: string; start: number }] }]
    | [];

  constructor(
    editorState: EditorState,
    findingsText: FindingsText | null,
    medicineDict: DictObj,
    testitemDict: DictObj,
    eventsForUnittest: [{ date: string; eventNames: string[] }] | null,
    mutesForUnittest:
      | [{ date: string; muteNames: [{ name: string; start: number }] }]
      | null
  ) {
    this.editorState = editorState;
    this.findingsText = findingsText;
    this.medicineDict = medicineDict;
    this.testitemDict = testitemDict;
    this.eventsForUnittest = eventsForUnittest == null ? [] : eventsForUnittest;
    this.mutesForUnittest = mutesForUnittest == null ? [] : mutesForUnittest;
    const reduceFunc = (memo: { [alias: string]: Dict }, dict: Dict) => {
      if (dict.aliases.length > 0) {
        memo = dict.aliases.reduce((memo2, alias) => {
          memo2[alias] = dict;
          return memo2;
        }, memo);
      }
      return memo;
    };
    this.medicineAliasDict = Object.values(medicineDict).reduce(reduceFunc, {});
    this.testitemAliasDict = Object.values(testitemDict).reduce(reduceFunc, {});
    this.medicinesWithAlias = this.makeDictsWithAlias(this.medicineDict);
    this.testitemsWithAlias = this.makeDictsWithAlias(this.testitemDict);
  }
  makeDictsWithAlias(dict: DictObj) {
    const values = Object.values(dict).reduce((memo, value) => {
      memo.push(value);
      memo = memo.concat(
        value.aliases.map((alias) => {
          return { ...value, word: alias, aliases: [] };
        })
      );
      return memo;
    }, [] as Dict[]);
    // const values = Object.values(dict);
    values.sort((a, b) => b.word.length - a.word.length);
    return values;
  }
  /**
   * ブロック列(一行とみなされる)をトークナイズする
   * @param state: EditorState
   * @param blocks ブロック配列
   * @return トークン列
   */
  tokenizeBlock(state: EditorState, blocks: ContentBlock[]): Token[] {
    const contentState = state.getCurrentContent();
    const tokens: Token[] = [];
    // 全てのブロックからトークン列を生成する
    let tm = false;
    let blockNo = 0;
    for (const block of blocks) {
      const source = block.getText();
      let cursor = 0;
      let match: RegExpExecArray | null;
      const dates: RegExpExecArray[] = [];
      reTm.lastIndex = 0;
      while ((match = reTm.exec(source)) !== null) {
        dates.push(match);
      }
      if (!tm) {
        tm = true;
        if (dates.length === 0) {
          // 通常行はタイムスタンプ前提なのでありえないケース
          console.error("No timestamp found at beginning of block:", source);
          return [];
        }
        const text = dates[0][0].trim();
        const isColonDate = text[0] === ":";
        const len = dates[0][0].length;
        tokens.push({
          type: TokenType.TOKEN_TIMESTAMP,
          blockNo: blockNo,
          text: isColonDate ? text.slice(1) : text,
          start: isColonDate ? source.indexOf(text) + 1 : cursor,
          len: isColonDate ? len - 2 : len,
        });
        cursor += len;
        dates.shift();
      }

      // タイムスタンプ以降をトークナイズ
      while (cursor < source.length) {
        const entityKey = block.getEntityAt(cursor);
        if (entityKey) {
          // エンティティトークン
          const entity = contentState.getEntity(entityKey);
          // エンティティの表現と、エンティティとして設定されている部分文字列が一致していない
          // （エンディティが保持しているのは辞書要素、エンティティ化されているのはそのエリアスのような）
          // ことを想定して、エンティティ化されている部分の長さを都度求める
          let forward = cursor + 1;
          while (forward < source.length) {
            const fkey = block.getEntityAt(forward);
            if (!fkey || fkey !== entityKey) {
              // エンティティが存在しないか、異なるエンティティを検出した場合はここでエンティティ終了
              break;
            }
            forward++;
          }
          const entstr = source.substring(cursor, forward);
          tokens.push({
            type: TokenType.TOKEN_ENTITY,
            text: entstr,
            len: entstr.length,
            blockNo: blockNo,
            start: cursor,
            entity: {
              type: entity.getType(),
              data: entity.getData(),
            },
          });
          cursor = forward;
        } else if (dates.length > 0 && dates[0].index === cursor) {
          //一行複数日付の２つ目以降。先頭に ":" 文字を考慮して設定
          const len = dates[0][0].length;
          tokens.push({
            type: TokenType.TOKEN_TIMESTAMP,
            text: dates[0][0].slice(1).trim(),
            blockNo: blockNo,
            start: cursor + 1,
            len: len - 1,
          });
          cursor += len;
          dates.shift();
        } else {
          // 非エンティティトークン
          // エンティティまたは行端まで
          let forward = cursor + 1;
          while (forward < source.length) {
            if (
              block.getEntityAt(forward) ||
              (dates.length > 0 && dates[0].index === forward)
            ) {
              // ここで非エンティティ終了
              break;
            }
            forward++;
          }
          const normstr = source.substring(cursor, forward);
          tokens.push({
            type: TokenType.TOKEN_TEXT,
            text: normstr,
            blockNo: blockNo,
            start: cursor,
            len: normstr.length,
          });
          cursor = forward;
        }
      }
      blockNo++;
    }
    return tokens;
  }

  /**
   * ブロック内の辞書エントリーをパース、適用する
   * @param estate EditorState
   * @param blocks 対象ブロック(継続行含む)
   * @param pblock ParseBlock
   * @param kind 辞書タイプ
   * @param dict 辞書
   * @returns [変更有無,変更後のEditorState]
   */
  parseBlockDict = (
    estate: EditorState,
    blocks: ContentBlock[],
    pblock: PBlock,
    kind: DictKind
  ): [boolean, EditorState] => {
    let modified = false;
    let modState = estate;
    let contentState = modState.getCurrentContent();
    const entities = contentState.getAllEntities();

    const findEntity = (itemName: string): string | undefined => {
      let found: string | undefined;
      entities.forEach((entity, key) => {
        if (!found) {
          if (entity?.getType() === kind) {
            const data = entity.getData();
            if (data && data["name"] && z2h(data["name"]) === itemName) {
              found = key;
            }
          }
        }
      });
      return found;
    };

    const values =
      kind === MEDICINE ? this.medicinesWithAlias : this.testitemsWithAlias;
    for (const item of values) {
      // 語が一つでも含まれている場合に限り実行
      if (!z2h(pblock.text).includes(item.word)) {
        continue;
      }
      contentState = modState.getCurrentContent();
      // この語に対応するentityを作成、または既存のentityを再利用
      let entityKey = findEntity(item.word);
      if (!entityKey) {
        // Entityを作成する
        const contentStateWithEntity = contentState.createEntity(
          kind,
          "IMMUTABLE",
          {
            name: item.word,
          }
        );
        entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        modState = EditorState.set(modState, {
          currentContent: contentStateWithEntity,
        });
      }

      // 継続行を含む全てのブロックに対してEntity設定
      [modified, modState] = this.parseBlockCore(
        modified,
        kind,
        item.word,
        entityKey,
        blocks,
        modState,
        true
      );
    }

    // 継続行を含む全てのブロックに設定されている kindのエンティティから既に辞書から
    // 取り除かれているエンティティを削除する
    const deleteFilterFn = (character: CharacterMetadata) => {
      const contentState = modState.getCurrentContent();
      const entityKey = character.getEntity();
      if (entityKey == null) return false;
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() === kind) {
        const name = entity.getData()["name"];
        const dict =
          kind === MEDICINE ? this.medicinesWithAlias : this.testitemsWithAlias;
        if (dict.find((d) => d.word === name) === undefined) {
          // 辞書に存在しないため削除対象
          return true;
        }
      }
      return false;
    };
    const genDeleteCallbackFn = (block: ContentBlock) => {
      return (start: number, end: number) => {
        const contentState = modState.getCurrentContent();
        // この位置のエンティティを削除する
        const newss = SelectionState.createEmpty(block.getKey()).merge({
          anchorOffset: start,
          focusOffset: end,
        });
        const newContent = Modifier.applyEntity(contentState, newss, null);
        // 上位関数内のmodSateとmodifiedを書き換える
        modState = EditorState.push(modState, newContent, "apply-entity");
        modified = true;
      };
    };
    for (const block of blocks) {
      // kindに一致して、辞書に存在しないエンティティを抽出してエンティティを削除する
      // eslint-disable-next-line no-loop-func
      block.findEntityRanges(deleteFilterFn, genDeleteCallbackFn(block));
    }

    return [modified, modState];
  };

  /**
   * ブロック内の辞書エントリーをパース、適用する
   * @param estate EditorState
   * @param blocks 対象ブロック(継続行含む)
   * @param pblock ParseBlock
   * @returns [変更有無,変更後のEditorState]
   */
  parseBlockEvent = (
    estate: EditorState,
    blocks: ContentBlock[],
    pblock: PBlock
  ): [boolean, EditorState] => {
    const regexEvent = /\[\[[^[\]]+\]\]/gi;
    let modified = false;
    let modState = estate;
    let contentState = modState.getCurrentContent();
    const entities = contentState.getAllEntities();
    const findEntity = (itemName: string): string | undefined => {
      let found: string | undefined;
      entities.forEach((entity, key) => {
        if (!found) {
          if (entity?.getType() === EVENT) {
            const data = entity.getData();
            if (data && data["name"] && z2h(data["name"]) === itemName) {
              found = key;
            }
          }
        }
      });
      return found;
    };

    let match;
    while ((match = regexEvent.exec(pblock.text)) !== null) {
      const name = match[0];
      contentState = modState.getCurrentContent();
      // この語に対応するentityを作成、または既存のentityを再利用
      let entityKey = findEntity(name);
      if (!entityKey) {
        // Entityを作成する
        const contentStateWithEntity = contentState.createEntity(
          EVENT,
          "IMMUTABLE",
          {
            name: name,
          }
        );
        entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        modState = EditorState.set(modState, {
          currentContent: contentStateWithEntity,
        });
      }

      // 継続行を含む全てのブロックに対してEntity設定
      [modified, modState] = this.parseBlockCore(
        modified,
        EVENT,
        name,
        entityKey,
        blocks,
        modState,
        false
      );
    }
    return [modified, modState];
  };

  parseBlockCore = (
    modified: boolean,
    kind: string,
    name: string,
    entityKey: string,
    blocks: ContentBlock[],
    modState: EditorState,
    z2hnormalize: boolean
  ): [boolean, EditorState] => {
    // 継続行を含む全てのブロックに対してEntity設定
    for (const block of blocks) {
      // 最新のブロックを最新のEditorStateから取り出す
      const modBlock = modState
        .getCurrentContent()
        .getBlockForKey(block.getKey());
      const text = modBlock.getText();
      const itemLen = name.length;
      // 全ての語を処理する
      let offset = 0;
      let pos = -1;
      const normalizeText = z2hnormalize ? z2h(text) : text;
      while ((pos = normalizeText.indexOf(name, offset)) >= 0) {
        const existEntityKey = modBlock.getEntityAt(pos);
        let createNewEntity = false;
        if (existEntityKey) {
          // 既存のエンティティが検索語より短い語であった場合には、そのエンティティを削除して
          // 新たに作成
          const existEntity = modState
            .getCurrentContent()
            .getEntity(existEntityKey);
          const existName = existEntity.getData()["name"];
          if (
            existEntity.getType() === kind &&
            existName.length < name.length
          ) {
            const newss = SelectionState.createEmpty(modBlock.getKey()).merge({
              anchorOffset: pos,
              focusOffset: pos + itemLen,
            });
            const newContent = Modifier.applyEntity(
              modState.getCurrentContent(),
              newss,
              null
            );
            modState = EditorState.push(
              this.editorState,
              newContent,
              "apply-entity"
            );
            createNewEntity = true;
          }
        } else {
          // エンティティが存在しない場合は作成
          createNewEntity = true;
        }
        if (createNewEntity) {
          // entity設定がこの語に設定されていない場合に限りEntityを設定
          const newss = SelectionState.createEmpty(modBlock.getKey()).merge({
            anchorOffset: pos,
            focusOffset: pos + itemLen,
          });
          const ssContent = Modifier.applyEntity(
            modState.getCurrentContent(),
            newss,
            entityKey
          );
          modState = EditorState.push(modState, ssContent, "apply-entity");
          modified = true;
        }
        offset = pos + itemLen;
      }
    }
    return [modified, modState];
  };
  /**
   * タイムスタンプをTIMESTAMPエンティティに設定する
   * @param estate EditorState
   * @param blocks エンティティ設定ブロック群
   * @param tmToken タイムスタンプトークン
   * @returns TIMESTAMPエンティティを新たに作成した場合は変更されたEditorState。作成しなかった場合はnull
   */
  assignTimestampEntity(
    estate: EditorState,
    blocks: ContentBlock[],
    tmToken: Token
  ): EditorState | null {
    let content = estate.getCurrentContent();

    // 設定済みエンティティを取得する
    const block = blocks[tmToken.blockNo];
    const entityKey = block.getEntityAt(tmToken.start);
    if (entityKey) {
      // 既にエンティティが存在する場合は削除する
      const newss = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: tmToken.start,
        focusOffset: tmToken.start + tmToken.len,
      });
      content = Modifier.applyEntity(content, newss, null);
      estate = EditorState.push(this.editorState, content, "apply-entity");
    }

    // タイムスタンプエンティティを設定する
    const contentStateWithEntity = content.createEntity(TIMESTAMP, "MUTABLE", {
      name: tmToken.text,
    });
    const tmKey = contentStateWithEntity.getLastCreatedEntityKey();
    estate = EditorState.set(estate, {
      currentContent: contentStateWithEntity,
    });
    const newss = SelectionState.createEmpty(block.getKey()).merge({
      anchorOffset: tmToken.start,
      focusOffset: tmToken.start + tmToken.len,
    });
    content = Modifier.applyEntity(content, newss, tmKey);
    estate = EditorState.push(this.editorState, content, "apply-entity");
    return estate;
  }

  /**
   * 付帯情報のテキストを区切りになる文字列群を分離点としてトークナイズする
   * @param text 付帯情報テキスト
   * @returns トークン列
   */
  tokenizeAuxText(text: string): string[] {
    // いくつかのパターンを分離点としてトークン分解する
    const tokens = [];
    let queue: string[] = [];
    let remaining = text;
    while (remaining.length > 0) {
      const match = remaining.match(REG_AUX_TEXT_SPLITTER);
      if (match !== null && match[1]) {
        // 分離点に一致する文字列群が先頭にあり
        if (queue.length > 0) {
          // 未処理文字のキューに文字が蓄積されていた場合は、それをトークンとする
          tokens.push(queue.join(""));
          queue = [];
        }
        // 分離文字をトークンとする
        tokens.push(match[1]);
        remaining = match[2]; // 残りの文字列をトークナイズ
      } else {
        // 分離点に一致する文字列群からは始まらないので、先頭文字を未処理文字キューに追加
        queue.push(remaining.substr(0, 1));
        remaining = remaining.substr(1); // 残りの文字をトークナイズ
      }
    }
    if (queue.length > 0) {
      // 未処理文字のキューに文字が蓄積されていた場合は、それを最後のトークンとする
      tokens.push(queue.join(""));
      queue = [];
    }
    return tokens;
  }

  /**
   * 薬剤の付帯情報をパースする
   * @param text 薬剤付帯情報が記述されたテキスト
   * @param pmedicine 付帯情報の格納先
   * @returns 付帯情報のパースに成功した場合はtrue
   */
  parseMedicineText(text: string, pmedicine: PMedicine) {
    // 付帯情報の文字列をトークン分解する
    const tokens = this.tokenizeAuxText(text);
    // 付帯情報の先頭にある空白文字をスキップする
    while (CS_SPACE.includes(tokens[0])) {
      tokens.shift();
    }

    // 終了文字が先頭になった場合は、この薬剤終了
    const z2htext = z2h(text).toUpperCase();
    for (const stopKeyword of CS_STOP) {
      if (z2htext.indexOf(stopKeyword) >= 0) {
        pmedicine.amount = "0";
        return;
      }
    }

    // 終了ではない
    let detailTokens = tokens;
    const arrowPos = tokens.reduce((memo, token, index) => {
      if (CS_ARROW.includes(token)) memo = index;
      return memo;
    }, -1);
    if (arrowPos >= 0) {
      // 矢印文字が含まれていた場合は、それ以降を使用量として採用する
      detailTokens = tokens.slice(arrowPos + 1);
    }

    // 使用量とみなせる部分を文字列として再スキャンする
    const detailStr =
      detailTokens.join("").trim() === "" && arrowPos >= 0
        ? "0"
        : detailTokens.join("");
    const matchInterval = detailStr.match(REG_PRES_INTERVAL);
    let times =
      matchInterval && matchInterval.length > 2 && matchInterval[2]
        ? matchInterval[2]
        : "";
    const period =
      matchInterval && matchInterval.length > 3 && matchInterval[3]
        ? matchInterval[3]
        : "";
    const unit =
      matchInterval && matchInterval.length > 4 && matchInterval[4]
        ? matchInterval[4]
        : "";
    let amountStr =
      matchInterval && matchInterval[1].length > 0
        ? detailStr.slice(0, detailStr.indexOf(matchInterval[1]))
        : detailStr;
    if (amountStr === "") {
      amountStr = times;
      times = "";
    }
    const matchAmount = amountStr.match(REG_PRES_AMOUNT);
    if (!matchAmount) {
      // 用量と単位が空の用法として認識する
      pmedicine.amount = "";
    } else {
      pmedicine.amount = matchAmount[1] || "";
      const interval = matchInterval ? times + "/" + period + unit : "";
      if (interval !== "") {
        pmedicine.interval = interval;
        if (matchInterval) {
          pmedicine.convertRate = this.calcConvertRate(times, period, unit);
        }
      }
    }
    pmedicine.label =
      (pmedicine.unitedName || pmedicine.name) +
      (pmedicine.amount === "0"
        ? ""
        : " " +
          (pmedicine.amount || "") +
          (pmedicine.interval ? " " + pmedicine.interval : ""));
    return;
  }

  /**
   * 使用量の変換乗数を計算する。
   * @param times 回数
   * @param period 期間
   * @param unit 期間単位
   */
  calcConvertRate(times: string, period: string, unit: string) {
    const unitNames: { [key: string]: number } = {
      d: 1,
      D: 1,
      日: 1,
      w: 7,
      W: 7,
      週: 7,
      m: 30,
      M: 30,
      月: 30,
      y: 365,
      Y: 365,
      年: 365,
    };
    const numTimes = parseInt(times || "1");
    const numPeriod = parseInt(period || "1");
    return Math.round((10000 * numTimes) / numPeriod / unitNames[unit]) / 10000;
  }

  /**
   * 薬剤と付帯情報をParseする
   * @param tokens 初期トークナイズされたトークン列
   * @param pblock パース結果格納先
   */
  parseMedicines(tokens: Token[], pblock: PBlock) {
    const modTokens: Token[] = [];
    let prevType: TokenType = TokenType.TOKEN_TIMESTAMP;
    let currentEntity: Token | null = null;
    let bExit = false;
    for (const [i, token] of tokens.entries()) {
      if (
        prevType === TokenType.TOKEN_ENTITY &&
        token.type === TokenType.TOKEN_TEXT &&
        token.text.trim() === ","
      ) {
        //後続する "," ENTITY は読み飛ばし、その後のテキストを二重化する。
        for (let j = i + 1; j < tokens.length && bExit === false; j++) {
          switch (tokens[j].type) {
            case TokenType.TOKEN_TIMESTAMP:
              bExit = true;
              break;
            case TokenType.TOKEN_ENTITY:
              continue;
            case TokenType.TOKEN_TEXT:
              if (tokens[j].text.trim() === ",") {
                continue;
              } else {
                modTokens.push(tokens[j]);
                bExit = true;
                break;
              }
          }
        }
        bExit = false;
      } else if (
        //主語省略補填
        prevType === TokenType.TOKEN_TIMESTAMP &&
        token.type === TokenType.TOKEN_TEXT &&
        token.text.match(REG_PRES_AMOUNT)
      ) {
        if (currentEntity) modTokens.push(currentEntity);
        modTokens.push(token);
      } else {
        modTokens.push(token);
      }
      prevType = token.type;
      if (token.type === TokenType.TOKEN_ENTITY) currentEntity = token;
    }

    pblock.medicines = [];
    pblock.errormessage = [];

    // 全ての薬剤トークンに続くテキストを付帯情報としてパースする
    let medicines: PMedicine[] | null = null;
    const medicinesList: PMedicine[][] = [];
    for (let i = 0; i < modTokens.length; i++) {
      const head = modTokens[i];
      switch (head.type) {
        case TokenType.TOKEN_TIMESTAMP:
          if (medicines !== null) medicinesList.push(medicines);
          medicines = [];
          break;
        case TokenType.TOKEN_ENTITY:
          if (medicines && head.entity?.type === MEDICINE) {
            const name = head.entity.data["name"];
            const unitedName =
              this.medicineAliasDict &&
              this.medicineAliasDict[name] &&
              this.medicineAliasDict[name].word;
            const pmedicine: PMedicine = {
              name: name,
              label: name || unitedName,
              amount: "",
              unitedName: unitedName,
              //trueの時のみ設定する。
              useSingle:
                this.medicineDict[name]?.useSingle === true ||
                this.medicineAliasDict[name]?.useSingle === true
                  ? true
                  : undefined,
              groupName:
                this.medicineDict[name]?.groupName ||
                this.medicineAliasDict[name]?.groupName,
              backgroundColor:
                this.medicineDict[name]?.bgColorStyle.backgroundColor ||
                this.medicineAliasDict[name]?.bgColorStyle.backgroundColor ||
                MEDICINE_BGCOLOR_DEFAULT.backgroundColor,
            };
            //後続文字列なし時
            if (i + 1 >= modTokens.length) {
              medicines.push(pmedicine);
              continue;
            }
            //後続文字列あり時
            if (modTokens[i + 1].type === TokenType.TOKEN_TEXT) {
              const text = z2h(modTokens[i + 1].text);
              this.parseMedicineText(text, pmedicine);
              i++;
            }
            medicines.push(pmedicine);
          }
          break;
      }
    }
    if (medicines !== null) medicinesList.push(medicines);
    pblock.medicines = medicinesList;
  }
  setMuteType(tokens: Token[]): Token[] {
    if (!this.mutesForUnittest || this.mutesForUnittest.length === 0)
      return tokens;
    this.mutesForUnittest.forEach((mute) => {
      tokens.forEach((token, i) => {
        if (token.type === TIMESTAMP && token.text === mute.date) {
          for (let j = i + 1; j < tokens.length; j++) {
            if (tokens[j].type === TokenType.TOKEN_TIMESTAMP) continue;
            if (tokens[j].type === TokenType.TOKEN_ENTITY) {
              for (const muteName of mute.muteNames) {
                if (
                  tokens[j].text === muteName.name &&
                  tokens[j].start === muteName.start
                ) {
                  tokens[j].type = TokenType.TOKEN_MUTE;
                }
              }
            }
          }
        }
      });
    });
    return tokens;
  }

  /**
   * ブロックをParseする
   * @param latestState 最新のEditorState
   * @param block パース対象ブロック
   * @param continueBlocks 継続業としてあつかうブロックの配列
   * @param pblock パース結果格納先
   * @returns [EditorStateに変更ありの場合true、latestStateまたは変更後のEditorState]
   */
  parseBlock(
    latestState: EditorState,
    block: ContentBlock,
    continueBlocks: ContentBlock[],
    pblock: PBlock
  ): [boolean, EditorState] {
    let blocks = [block].concat(continueBlocks || []);
    let modState = latestState;
    let modified = false;

    const getModifiedBlocks = (state: EditorState, blocks: ContentBlock[]) => {
      return blocks.map((block) =>
        state.getCurrentContent().getBlockForKey(block.getKey())
      );
    };
    // 薬剤名の検出を行う
    let medicineModified = false;
    [medicineModified, modState] = this.parseBlockDict(
      modState,
      blocks,
      pblock,
      MEDICINE
    );
    if (medicineModified) {
      modified = true;
      blocks = getModifiedBlocks(modState, blocks);
    }
    // 検査名の検出を行う
    let testModified = false;
    [testModified, modState] = this.parseBlockDict(
      modState,
      blocks,
      pblock,
      TESTITEM
    );
    if (testModified) {
      modified = true;
      blocks = getModifiedBlocks(modState, blocks);
    }
    // [[イベント]]の検出を行う
    let eventModified = false;
    [eventModified, modState] = this.parseBlockEvent(modState, blocks, pblock);
    if (eventModified) {
      modified = true;
      blocks = getModifiedBlocks(modState, blocks);
    }
    // トークナイズ処理を行う
    const tokens = this.tokenizeBlock(modState, blocks);
    if (tokens.length > 0) {
      // タイムスタンプ（認識したものそのまま）を格納する
      // タイムスタンプ=>実時間は文脈依存であるため臨床経過図記述生成時に実時間化する
      this.setMuteType(tokens);
      for (const token of tokens) {
        if (token.type === TokenType.TOKEN_TIMESTAMP) {
          let time, timeUntil;
          [time, timeUntil] = normalizeDate(z2h(token.text), reTm);
          if (time && pblock.time.indexOf(time) === -1) {
            pblock.time.push(time);
            pblock.timeUntil.push(timeUntil || "");
          }
          // タイムスタンプをTIMESTAMPエンティティ化する
          const tmState = this.assignTimestampEntity(modState, blocks, token);
          if (tmState !== null) {
            modState = tmState;
            modified = true;
          }
        }
      }
      // 薬剤と薬剤付帯情報を検出する
      this.parseMedicines(tokens, pblock);
      // 検査付帯情報を検出する
      this.parseTests(tokens, pblock);
      // イベントを検出する
      this.parseEvents(tokens, pblock);

      //UNITTEST時に画面クリックからのevent登録を模倣
      //[[xxx]] イベントは動的に登録されるため dict.jsonに設定不要
      if (this.eventsForUnittest != null && this.eventsForUnittest.length > 0) {
        for (const [i, time] of pblock.time.entries()) {
          const event = this.eventsForUnittest.find((event) => {
            return time === event.date;
          });
          if (!event) continue;
          pblock.events[i] = pblock.events[i].concat(
            event.eventNames.map((name) => ({ label: name }))
          );
        }
      }
    }
    return [modified, modState];
  }

  /**
   * 検査の付帯情報をパースする
   * @param text 検査付帯情報が記述されたテキスト
   * @param ptest 付帯情報の格納先
   * @returns 付帯情報のパースに成功した場合はtrue
   */
  parseTestText(text: string, ptest: PTest): boolean {
    ptest.value = [];
    for (const splitText of text.trim().split(":")[0].split("/")) {
      const match = splitText.match(REG_TEST_AMOUNT);
      if (!match) return false;
      if (match[2]) {
        ptest.value.push(
          Math.max(parseFloat(match[1]), parseFloat(match[2])).toString()
        );
        if (!ptest.valueMin) ptest.valueMin = [];
        ptest.valueMin.push(
          Math.min(parseFloat(match[1]), parseFloat(match[2])).toString()
        );
      } else {
        ptest.value.push(
          isNaN(parseFloat(match[1])) ? "" : parseFloat(match[1]).toString()
        );
      }
    }
    return true;
  }

  /**
   * 検査と付帯情報をParseする
   * @param tokens 初期トークナイズされたトークン列
   * @param pblock パース結果格納先
   */
  parseTests(tokens: Token[], pblock: PBlock) {
    const modTokens: Token[] = [];
    let prevType: TokenType = TokenType.TOKEN_TIMESTAMP;
    let currentEntity: Token | null = null;
    for (const token of tokens) {
      if (
        //主語省略補填
        prevType === TokenType.TOKEN_TIMESTAMP &&
        token.type === TokenType.TOKEN_TEXT &&
        token.text.match(REG_TEST_AMOUNT)
      ) {
        if (currentEntity) modTokens.push(currentEntity);
        modTokens.push(token);
      } else {
        modTokens.push(token);
      }
      prevType = token.type;
      if (token.type === TokenType.TOKEN_ENTITY) currentEntity = token;
    }

    pblock.tests = [];
    pblock.errormessage = [];

    let tests: PTest[] | null = null;
    const testsList: PTest[][] = [];
    for (let cursor = 0; cursor < modTokens.length; cursor++) {
      const head = modTokens[cursor];
      switch (head.type) {
        case TokenType.TOKEN_TIMESTAMP:
          if (tests !== null) testsList.push(tests);
          tests = [];
          break;
        case TokenType.TOKEN_ENTITY:
          if (tests && head.entity?.type === TESTITEM) {
            const name = head.entity.data["name"];
            const unitedName =
              this.testitemAliasDict &&
              this.testitemAliasDict[name] &&
              this.testitemAliasDict[name].word;
            const ptest: PTest = {
              name: name,
              label: unitedName || name,
              unitedName: unitedName,
            };
            //後続文字列なし時
            if (cursor + 1 >= modTokens.length) {
              tests.push(ptest);
              continue;
            }
            //後続文字列あり時
            if (modTokens[cursor + 1].type === TokenType.TOKEN_TEXT) {
              const text = z2h(modTokens[cursor + 1].text);
              if (this.parseTestText(text, ptest)) {
                tests.push(ptest);
              } else {
                pblock.errormessage.push("Failed parseTestText:", text);
                console.error("Failed parseTestText:", text);
              }
              cursor++;
            }
          }
          break;
      }
    }
    if (tests !== null) testsList.push(tests);
    pblock.tests = testsList;
  }

  /**
   * イベント情報をParseする
   * @param tokens 初期トークナイズされたトークン列
   * @param pblock パース結果格納先
   */
  parseEvents(tokens: Token[], pblock: PBlock) {
    pblock.events = [];

    let events: PEvent[] | null = null;
    const eventsList: PEvent[][] = [];
    for (const token of tokens) {
      switch (token.type) {
        case TokenType.TOKEN_TIMESTAMP:
          if (events !== null) eventsList.push(events);
          events = [];
          break;
        case TokenType.TOKEN_ENTITY:
          if (events && token.entity?.type === EVENT) {
            // [[xxx]] の xxxを抽出する。
            const match = /(?:\[\[)?([^[\]]*)(?:\]\])?/.exec(
              token.entity.data["name"]
            );
            events.push({
              label: match && match[1] ? match[1] : token.entity.data["name"],
            });
          }
          break;
      }
    }
    if (events != null) eventsList.push(events);
    pblock.events = eventsList;
  }

  /**
   * テキストのパースと構文認識を行う。認識に伴う文字修飾も実行する。
   * @param currentState: 現在のEditorState
   * @param parseAll trueであれば全てのブロック(行)を対象とする。falseであれば更新差分のみ対象とする
   * @returns エディタの状態に変更があればEditorState、そうでなければnull
   */
  executeParseAndUpdate(
    currentState: EditorState,
    parseAll?: boolean
  ): {
    parsedState: EditorState | null;
    parseUpdated: boolean;
    parseBlocks: ContentBlock[];
    parsed: ParsedData;
  } {
    // Note: parevParsedに破壊的な操作を行うことがあるため Clone を参照する
    const prevParsed = this.findingsText
      ? this.findingsText.parsed
        ? lodash.cloneDeep(this.findingsText.parsed)
        : ({} as ParsedData)
      : ({} as ParsedData);
    const parsed = {} as ParsedData;

    // 継続行、コメント行を検出してブロックから取り除いていく
    const contPattern = /^\s+(.*)$/;
    const continueBlocks: { [key: string]: ContentBlock[] } = {}; // keyで示されるblockに継続する行
    const parseBlocks: ContentBlock[] = []; // parse対象ブロック。継続行は取り除かれている
    let lastStdBlockKey: string | null = null;
    for (const block of currentState.getCurrentContent().getBlocksAsArray()) {
      const key = block.getKey();
      const text = block.getText();
      const contMatch = text.match(contPattern);
      if (contMatch) {
        // 空白文字で始まり
        //  a) 空白後が日付文字列で始まる場合は通常行
        //  b) それ以外は継続行
        reTm.lastIndex = 0;
        const match = reTm.exec(contMatch[1]);
        if (match && match[0][0] !== ":") {
          // 通常行として扱う
          lastStdBlockKey = key;
          parseBlocks.push(block);
        } else {
          // 継続行として扱う
          if (lastStdBlockKey !== null) {
            if (continueBlocks[lastStdBlockKey] === undefined) {
              continueBlocks[lastStdBlockKey] = [];
            }
            continueBlocks[lastStdBlockKey].push(block);
          } else {
            // 先頭行の特例通常行
            lastStdBlockKey = key;
            parseBlocks.push(block);
          }
        }
      } else {
        // a) 行頭が日付として認識された場合は通常行
        // b) それ以外はコメント行 (無視)
        reTm.lastIndex = 0;
        const match = reTm.exec(text);
        if (match) {
          // 通常行
          lastStdBlockKey = key;
          parseBlocks.push(block);
        }
      }
    }

    // 全ての行を解析する
    let modState = currentState;
    let stateUpdated = false;
    let parseUpdated = false;
    if (parseBlocks.length !== Object.keys(prevParsed).length) {
      parseUpdated = true;
    }
    for (const block of parseBlocks) {
      let doParse = false;
      const key = block.getKey();
      let text = block.getText();
      if (Array.isArray(continueBlocks[key])) {
        // 継続行ありの場合は継続行を一つのテキストとして連接する
        text += "\n" + continueBlocks[key].map((cb) => cb.getText()).join("\n");
      }
      let pblock: PBlock;
      if (prevParsed[key]) {
        // この行は既存
        if (text !== prevParsed[key].text) {
          // テキスト変更を検出したら、この行を解析
          pblock = {
            text: text,
            medicines: [],
            tests: [],
            events: [],
            errormessage: [],
            time: [],
            timeUntil: [],
          };
          doParse = true;
        } else {
          pblock = prevParsed[key];
        }
      } else {
        // この行は新規
        pblock = {
          text: text,
          medicines: [],
          tests: [],
          events: [],
          errormessage: [],
          time: [],
          timeUntil: [],
        };
        doParse = true;
      }
      if (doParse || parseAll) {
        parseUpdated = true;
        // このブロック（と継続ブロック）に対して解析を実行
        let supdate = false;
        [supdate, modState] = this.parseBlock(
          modState,
          block,
          continueBlocks[key],
          pblock
        );
        if (supdate) {
          stateUpdated = supdate;
        }
      }
      parsed[key] = pblock;
    }

    return {
      parsedState: stateUpdated ? modState : null,
      parseUpdated: parseUpdated,
      parseBlocks: parseBlocks,
      parsed: parsed,
    };
  }
}
