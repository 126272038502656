import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import "draft-js/dist/Draft.css";
import Main from "./pages/main";
import Open from "./pages/open";
import Save from "./pages/save";
import Dict from "./pages/dict";
import Manage from "./pages/manage";
import Stat from "./pages/stat";

function App() {
  return (
    <>
      <Router>
        <Route exact path="/" component={Main} />
        <Route path="/open" component={Open} />
        <Route path="/save" component={Save} />
        <Route path="/dict" component={Dict} />
        <Route path="/manage" component={Manage} />
        <Route path="/stat" component={Stat} />
      </Router>
    </>
  );
}

export default App;
