import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./mystyle.scss";
import Dexie from "dexie";
import { exportDB, importDB } from "dexie-export-import";
import dayjs from "dayjs";
import { dbName, MEDICINE, TESTITEM } from "../app/define";
import { openDB } from "../components/IDB/IndexDb";
import { addDictEntries, deleteDictAll } from "../app/dictSlice";

const Manage = () => {
  const dispatch = useAppDispatch();

  const [statusMessage, setStatusMessage] = useState<string>("");
  const [verno, setVerno] = useState<number>(0);
  const [databaseName, setDatabaseName] = useState<string>("");
  const [tableCount, setTableCount] = useState<number>(0);
  const download = (blob: Blob) => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      var dataURI = this.result?.toString() || "";
      const link = document.createElement("a");
      link.href = dataURI;
      const filename = `doc2chart.${dayjs().format("YYYYMMDDHHmmss")}.d2c`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    fileReader.readAsDataURL(blob);
  };

  useEffect(() => {
    loadDatabaseInfo();
  }, [verno, databaseName, tableCount]);

  const loadDatabaseInfo = async () => {
    const db = await openDB();
    setVerno(db.verno);
    setDatabaseName(db.name);
    setTableCount(db.tables.length);
  };
  const exportDatabase = async () => {
    const db = await openDB();
    const blob = await exportDB(db);
    download(blob);
    db.close();
    setStatusMessage(`エクスポート処理が完了しました。`);
    return;
  };
  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };
  const progressCallback = ({ totalRows, completedRows }: any) => {
    setStatusMessage(
      `Progress: ${completedRows} of ${totalRows} rows completed`
    );
    return true;
  };
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (
      !file ||
      !file.name.startsWith("doc2chart") ||
      !file.name.endsWith(".json")
    )
      throw new Error(`doc2chartファイルをドロップしてください。`);
    importBundle(new Blob([file], { type: file.type }), file.name);
  };

  const importBundle = async (file: Blob, filename: string) => {
    try {
      await Dexie.delete(dbName);
      setStatusMessage("Importing " + filename);
      const importDb = await importDB(file, {
        progressCallback,
      });
      importDb.close();
      const db = await openDB();
      const med = await db.MEDICINE.toArray();
      const test = await db.TESTITEM.toArray();
      dispatch(deleteDictAll);
      dispatch(addDictEntries({ dicts: med, kind: MEDICINE }));
      dispatch(addDictEntries({ dicts: test, kind: TESTITEM }));
      setStatusMessage(`${filename} のインポート処理が完了しました。`);
      loadDatabaseInfo();
    } catch (error) {
      console.error("" + error);
    }
  };
  window.doc2chartFunc.ImportBundle = (json: string, filename: string) => {
    importBundle(new Blob([json], { type: "application/json" }), filename);
  };

  return (
    <>
      <main key={"manage"}>
        <nav
          className="navbar is-light is-mobile"
          role="navigation"
          aria-label="main navigation mobile"
        >
          <div id="menubar" style={{ height: "70px" }}>
            <div className="navbar-start " id="navbar-size">
              <p className="navbar-item is-size-7 has-text-centered">
                <Link to="/">
                  <AiOutlineArrowLeft size={30} />
                  <br />
                  戻る
                </Link>
              </p>
            </div>
          </div>
        </nav>
        <div>
          <table className="tablestyle">
            <thead>
              <tr>
                <th>key</th>
                <th>name</th>
                <th>value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DB</td>
                <td>Version</td>
                <td key={"TD.verno"}>{verno}</td>
              </tr>
              <tr>
                <td>DB</td>
                <td>Name</td>
                <td key={"TD.databaseName"}>{databaseName}</td>
              </tr>
              <tr>
                <td>DB</td>
                <td>Table Count</td>
                <td key={"TD.tableCount"}>{tableCount}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ paddingTop: "4px" }}>{statusMessage}</div>
      </main>
    </>
  );
};
export default Manage;
