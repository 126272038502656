import React from "react";
import { CompositeDecorator, ContentBlock, ContentState } from "draft-js";
import { EVENT, MEDICINE, MUTE, TESTITEM, TIMESTAMP } from "../../app/define";

const styles = {
  medicine: {
    color: "#3b5998",
    textDecoration: "underline",
    backgroundColor: "#ffc0c0",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    marginRight: 1,
    marginLeft: 1,
  },
  testitem: {
    color: "#3b5998",
    textDecoration: "underline",
    backgroundColor: "#e0e0ff",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    marginRight: 1,
    marginLeft: 1,
  },
  event: {
    color: "#3b5998",
    textDecoration: "underline",
    backgroundColor: "#d0ffd0",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    marginRight: 1,
    marginLeft: 1,
  },
  mute: {
    color: "inherit",
    textDecoration: "underline dotted",
    backgroundColor: "inherit",
  },
  timestamp: {
    color: "inherit",
    fontWeight: 700,
    backgroundColor: "inherit",
  },
};

const findMedicineEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === MEDICINE
    );
  }, callback);
};
const Medicine = (props: any) => {
  return (
    <span style={styles.medicine} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

const findTestitemEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === TESTITEM
    );
  }, callback);
};
const Testitem = (props: any) => {
  return (
    <span style={styles.testitem} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

const findEventEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === EVENT
    );
  }, callback);
};
const Event = (props: any) => {
  return (
    <span style={styles.event} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

const findMuteEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === MUTE
    );
  }, callback);
};
const Mute = (props: any) => {
  return (
    <span style={styles.mute} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

const findTimestampEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === TIMESTAMP
    );
  }, callback);
};
const Timestamp = (props: any) => {
  return (
    <span style={styles.timestamp} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

// 表示上の修飾を行うDecoratorを定義
const decorator = new CompositeDecorator([
  {
    strategy: findMedicineEntities,
    component: Medicine,
  },
  {
    strategy: findTestitemEntities,
    component: Testitem,
  },
  {
    strategy: findEventEntities,
    component: Event,
  },
  {
    strategy: findMuteEntities,
    component: Mute,
  },
  {
    strategy: findTimestampEntities,
    component: Timestamp,
  },
]);

export default decorator;
