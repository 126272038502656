import React, { useEffect, useState } from "react";
import { CompactPicker, ColorResult } from "react-color";

import { Link } from "react-router-dom";
import {
  AiOutlineArrowLeft,
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineClose,
  AiOutlineSave,
} from "react-icons/ai";
import {
  ASC,
  DESC,
  DictKind,
  MEDICINE,
  MEDICINE_BGCOLOR_DEFAULT,
  SortDirection,
  TESTITEM,
} from "../app/define";
import {
  Dict,
  selectMedicineDict,
  selectTestDict,
  selectInitialized,
  loadDictFromIdbThunk,
  addDictToIdbThunk,
  putDictToIdbThunk,
  deleteDictFromIdbThunk,
} from "../app/dictSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { IconType } from "react-icons/lib";
import "./mystyle.scss";
import { z2h } from "../components/noteEditor/findingsTextParser";
import { checkDup, checkReplaceMessage, emptyDict } from "../common/dictUtil";

const ALIAS_SPLITTER = ",";
interface SortStatus {
  type: SortDirection;
  icon: IconType;
}

const dict_list: DictKind[] = [MEDICINE, TESTITEM];

//タブ表示切り替え（初期は薬剤辞書）
export function SetTab() {
  const [active, setActive] = useState<DictKind>(MEDICINE);

  function DispSwitch(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string
  ) {
    //タブ切り替え
    if (value === e.currentTarget.innerText)
      setActive(e.currentTarget.innerText as DictKind);
    //一覧、入力欄切り替え
    dict_list.forEach((e) => {
      let t = document.getElementById(e + "set");
      if (t !== null && t !== undefined) {
        if (e === value) {
          t!.style.removeProperty("display");
        } else if (e !== value) {
          t!.style.display = "none";
        }
      }
    });
  }

  return (
    <>
      {dict_list.map((value, idx) => {
        return (
          <li
            className={value === active ? "is-active" : ""}
            key={"dict_list" + idx}
          >
            <button
              className={value === active ? "tabbuttonactive" : "tabbutton"}
              onClick={(e) => DispSwitch(e, value)}
            >
              {value}
            </button>
          </li>
        );
      })}
    </>
  );
}

const Opendict = () => {
  const initialized = useAppSelector(selectInitialized);
  const dispatch = useAppDispatch();
  if (initialized === false) {
    dispatch(loadDictFromIdbThunk());
  }

  const medicines = useAppSelector(selectMedicineDict);
  const testitems = useAppSelector(selectTestDict);

  const [medicineList, setMedicineList] = useState<Dict[]>([]);
  const [testitemList, setTestitemList] = useState<Dict[]>([]);
  const [newMedicine, setNewMedicine] = useState<Dict>(emptyDict(""));
  const [newTestitem, setNewTestitem] = useState<Dict>(emptyDict(""));
  const setting: SortStatus = { type: ASC, icon: AiFillCaretUp };
  const [sorttypeMedicine, setSorttypeMedicine] = useState<SortStatus>(setting);
  const [sorttypeTestitem, setSorttypeTestitem] = useState<SortStatus>(setting);
  const [color, setColor] = useState<string>(
    MEDICINE_BGCOLOR_DEFAULT.backgroundColor
  );
  const [colorDisplay, setColorDisplay] = useState<boolean>(false);
  const [popover, setPopover] = useState<React.CSSProperties>({
    position: "fixed",
    zIndex: 50,
    top: "0px",
    right: "0px",
    display: "flex",
  });
  const [dictInColorPick, setDictInColorPick] = useState<number>(-1);
  const cover: React.CSSProperties = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  interface Func {
    [kind: string]: {
      caption: string;
      dictList: Dict[];
      setDictList: React.Dispatch<React.SetStateAction<Dict[]>>;
      newDict: Dict;
      setNewDict: React.Dispatch<React.SetStateAction<Dict>>;
      sorttypeDict: SortStatus;
      setSorttypeDict: React.Dispatch<React.SetStateAction<SortStatus>>;
    };
  }
  const F: Func = {
    MEDICINE: {
      caption: "薬剤",
      dictList: medicineList,
      setDictList: setMedicineList,
      newDict: newMedicine,
      setNewDict: setNewMedicine,
      sorttypeDict: sorttypeMedicine,
      setSorttypeDict: setSorttypeMedicine,
    },
    TESTITEM: {
      caption: "検査",
      dictList: testitemList,
      setDictList: setTestitemList,
      newDict: newTestitem,
      setNewDict: setNewTestitem,
      sorttypeDict: sorttypeTestitem,
      setSorttypeDict: setSorttypeTestitem,
    },
  };

  //薬剤辞書のソート

  //検査辞書のソート

  useEffect(() => {
    setMedicineList(
      Object.values(medicines)
        .map((medicine) => medicine)
        .slice()
        .sort(sortFunc(sorttypeMedicine.type))
    );
    setTestitemList(
      Object.values(testitems)
        .map((testitem) => testitem)
        .slice()
        .sort(sortFunc(sorttypeTestitem.type))
    );
  }, [medicines, sorttypeMedicine.type, sorttypeTestitem.type, testitems]);

  function SendDelete(word: string, kind: DictKind, index: number) {
    if (window.confirm(`「${word}」を削除します。\nよろしいですか？`)) {
      const newDictList = [...F[kind].dictList];
      newDictList.splice(index, 1);
      F[kind].setDictList(newDictList);
      dispatch(deleteDictFromIdbThunk({ word: word, kind }));
    }
  }

  const sortFunc = (order: SortDirection) => (d1: Dict, d2: Dict) =>
    order === ASC ? (d1.word > d2.word ? 1 : -1) : d1.word > d2.word ? -1 : 1;

  const saveNewDictItem = (dict: Dict, kind: DictKind) => {
    dispatch(addDictToIdbThunk({ dict, kind }));
    const newDictList = [...F[kind].dictList];
    newDictList.push(dict);
    newDictList.sort(sortFunc(sorttypeMedicine.type));
    F[kind].setDictList(newDictList);
  };

  // 同名の項目が存在した場合、片方の辞書から削除し、登録を行う。
  const replaceDictitem = async (
    dict: Dict,
    kind: DictKind,
    medicine?: Dict,
    testitem?: Dict
  ) => {
    if (checkReplaceMessage(medicine, testitem) === false) return false;
    await dispatch(
      deleteDictFromIdbThunk({
        word: (medicine && medicine.word) || (testitem && testitem.word) || "",
        kind: medicine ? MEDICINE : TESTITEM,
      })
    );
    saveNewDictItem(dict, kind);
    return true;
  };

  async function saveButtonClicked(kind: DictKind) {
    let bResult = true;
    const { findMedicine, findTestitem } = checkDup(
      F[kind].newDict,
      medicineList,
      testitemList
    );
    if (!findMedicine && !findTestitem) {
      saveNewDictItem(F[kind].newDict, kind);
    } else {
      bResult = await replaceDictitem(
        F[kind].newDict,
        kind,
        findMedicine,
        findTestitem
      );
    }
    if (bResult) F[kind].setNewDict(emptyDict(""));
  }

  function onChangeUseSingle(checked: boolean, kind: DictKind, index: number) {
    const newDictList = [...F[kind].dictList];
    newDictList[index] = { ...newDictList[index], useSingle: checked };
    F[kind].setDictList(newDictList);
    dispatch(putDictToIdbThunk({ dict: newDictList[index], kind }));
  }

  function dataSort(kind: DictKind) {
    F[kind].setDictList([
      ...F[kind].dictList.sort(sortFunc(F[kind].sorttypeDict.type)),
    ]);
    F[kind].setSorttypeDict({
      ...F[kind].sorttypeDict,
      type: F[kind].sorttypeDict.type === ASC ? DESC : ASC,
      icon: F[kind].sorttypeDict.type === ASC ? AiFillCaretDown : AiFillCaretUp,
    });
  }

  const checkDupNewDict = (dict: Dict) => {
    const s = new Set(dict.aliases.map((e) => z2h(e)));
    s.add(z2h(dict.word));
    return s.size !== dict.aliases.length + 1;
  };

  const onCloseMethod = (e: React.MouseEvent<HTMLDivElement>) => {
    setColorDisplay(false);
  };

  const onChangeColor = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setColor(color.hex);
    setColorDisplay(false);
    if (dictInColorPick === -1) {
      F[MEDICINE].newDict.bgColorStyle = {
        backgroundColor: color.hex,
      };
    } else {
      const newDictList = [...F[MEDICINE].dictList];
      newDictList[dictInColorPick] = {
        ...newDictList[dictInColorPick],
        bgColorStyle: {
          backgroundColor: color.hex,
        },
      };
      F[MEDICINE].setDictList(newDictList);
      dispatch(
        putDictToIdbThunk({
          dict: newDictList[dictInColorPick],
          kind: MEDICINE,
        })
      );
    }
  };

  const showColorPicker = (e: React.MouseEvent<HTMLSpanElement>) => {
    const id = (e.target as any).id.split("_")[1];
    setColor(
      (F[MEDICINE].dictList[id].bgColorStyle?.backgroundColor as string) ||
        MEDICINE_BGCOLOR_DEFAULT.backgroundColor
    );
    setPopover({
      ...popover,
      top: Math.min(e.clientY, window.innerHeight - 95),
      left: e.clientX - 245 - 4,
    });
    setDictInColorPick(id);
    setColorDisplay(true);
  };

  const showColorPickerNew = (e: React.MouseEvent<HTMLSpanElement>) => {
    setColor(
      (F[MEDICINE].newDict.bgColorStyle?.backgroundColor as string) ||
        MEDICINE_BGCOLOR_DEFAULT.backgroundColor
    );
    setPopover({
      ...popover,
      top: Math.min(e.clientY, window.innerHeight - 95),
      left: e.clientX - 245 - 4,
    });
    setDictInColorPick(-1);
    setColorDisplay(true);
  };

  const modifyEntity = (
    e: React.MouseEvent<HTMLButtonElement>,
    kind: DictKind
  ) => {
    const id = parseInt((e.target as any).closest("tr").id.split("_")[3]);
    F[kind].setNewDict({ ...F[kind].dictList[id] });
  };
  return (
    <>
      <main>
        <nav
          className="navbar is-light is-mobile"
          role="navigation"
          aria-label="main navigation mobile"
        >
          <div id="menubar" style={{ height: "70px" }}>
            <div className="navbar-start " id="navbar-size">
              <p className="navbar-item is-size-7 has-text-centered">
                <Link to="/">
                  <AiOutlineArrowLeft size={30} />
                  <br />
                  戻る
                </Link>
              </p>
            </div>
          </div>
        </nav>
        <div className="columns is-marginless is-flex-tablet-only">
          <div className="column ">
            <div className="box has-text-centered table-container ">
              <div className="tabs is-centered">
                <ul>
                  <SetTab />
                </ul>
              </div>
              {dict_list.map((kind, idx) => {
                return (
                  <div
                    id={kind + "set"}
                    style={kind === TESTITEM ? { display: "none" } : {}}
                    key={"dictlist" + idx}
                  >
                    <table className="table is-bordered is-narrow is-hoverable is-fullwidth is-striped has-text-centered tablesorter ">
                      <thead>
                        <tr key={"dictlist_tr_" + idx}>
                          {/* 登録単語列 */}
                          <th
                            style={{
                              textAlign: "left",
                              borderRight: "none",
                              width: "10em",
                            }}
                          >
                            {`登録${F[kind].caption}`}
                            <button
                              className="link-button"
                              style={{ border: "none" }}
                              onClick={(e) => {
                                dataSort(kind);
                              }}
                            >
                              {kind === MEDICINE ? (
                                <sorttypeMedicine.icon
                                  style={{ verticalAlign: "text-top" }}
                                />
                              ) : (
                                <sorttypeTestitem.icon
                                  style={{ verticalAlign: "text-top" }}
                                />
                              )}
                            </button>
                          </th>
                          {/* 別名列 */}
                          <th
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {`${F[kind].caption}別名`}
                          </th>
                          {/* 薬剤群名称*/}
                          {kind === MEDICINE && (
                            <th
                              style={{
                                width: "10em",
                                textAlign: "center",
                              }}
                            >
                              薬剤群名称
                            </th>
                          )}
                          {/* 単独チェックボックス（薬剤のみ）*/}
                          {kind === MEDICINE && (
                            <th
                              style={{
                                width: "6em",
                                textAlign: "center",
                              }}
                            >
                              単独薬剤
                            </th>
                          )}
                          {/* 背景色（薬剤のみ）*/}
                          {kind === MEDICINE && (
                            <th
                              style={{
                                width: "6em",
                                textAlign: "center",
                              }}
                            >
                              背景色
                            </th>
                          )}
                          {/* 削除列 */}
                          <th
                            style={{
                              width: "4em",
                              textAlign: "center",
                            }}
                          >
                            削除
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className="is-scrollable"
                        key={"dictlist_tbody" + idx}
                      >
                        {F[kind].dictList.map(
                          ({ word, aliases, groupName }, i) => (
                            <tr
                              id={`dictlist_tr1_${idx}_${i}`}
                              key={`dictlist_tr1_${idx}_${i}`}
                            >
                              {/* 登録単語列 */}
                              <td style={{ textAlign: "left" }}>
                                <button
                                  className="iconbutton"
                                  onClick={(e) => {
                                    modifyEntity(e, kind);
                                  }}
                                >
                                  <span style={{ fontSize: "16px" }}>
                                    {word}
                                  </span>
                                </button>
                              </td>
                              {/* 別名列 */}
                              <td style={{ textAlign: "left" }}>
                                {aliases.join(ALIAS_SPLITTER)}
                              </td>
                              {/* 薬剤群名称*/}
                              {kind === MEDICINE && (
                                <td style={{ textAlign: "left" }}>
                                  {groupName}
                                </td>
                              )}
                              {/* 単独チェックボックス（薬剤のみ）*/}
                              {kind === MEDICINE && (
                                <td>
                                  <input
                                    type="checkbox"
                                    key={`useSingle_${i}`}
                                    style={{ marginRight: "0.2em" }}
                                    checked={F[kind].dictList[i].useSingle}
                                    onChange={(e) =>
                                      onChangeUseSingle(
                                        e.target.checked,
                                        kind,
                                        i
                                      )
                                    }
                                  />
                                </td>
                              )}
                              {/* 背景色（薬剤のみ）*/}
                              {kind === MEDICINE && (
                                <td>
                                  <span
                                    key={`medicineBackColor_${i}_${word}`}
                                    id={`medicineBackColor_${i}_${word}`}
                                    onClick={showColorPicker}
                                    className="medicineBackColor"
                                    style={F[kind].dictList[i].bgColorStyle}
                                  ></span>
                                </td>
                              )}
                              {/* 削除列 */}
                              <td>
                                <button
                                  className="iconbutton"
                                  onClick={(e) => SendDelete(word, kind, i)}
                                >
                                  <AiOutlineClose
                                    size={25}
                                    style={{ verticalAlign: "sub" }}
                                  />
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>

                    <div className="has-text-left">
                      <label className="label">追加単語</label>
                    </div>

                    <table className="table is-bordered is-narrow is-fullwidth is-striped ">
                      <tbody>
                        <tr>
                          <td style={{ padding: 0, width: "10em" }}>
                            <input
                              type="text"
                              id={`new_additionalText_${kind}`}
                              name={`new_additionalText_${kind}`}
                              placeholder={`${kind} word`}
                              className="input"
                              onChange={(e) =>
                                F[kind].setNewDict({
                                  ...F[kind].newDict,
                                  word: e.target.value,
                                })
                              }
                              value={F[kind].newDict.word}
                            ></input>
                          </td>
                          <td style={{ padding: 0 }}>
                            <input
                              type="text"
                              id={`new_additionalAlias_${kind}`}
                              name={`new_additionalAlias_${kind}`}
                              disabled={F[kind].newDict.word.length === 0}
                              className="input"
                              placeholder={`${kind} aliases (e.g. A,B,C, ...)`}
                              onChange={(e) =>
                                F[kind].setNewDict({
                                  ...F[kind].newDict,
                                  aliases: e.target.value.split(","),
                                })
                              }
                              value={F[kind].newDict.aliases.join(",")}
                            ></input>
                          </td>
                          {/* 薬剤群名称*/}
                          {kind === MEDICINE && (
                            <td style={{ padding: 0, width: "10em" }}>
                              <input
                                type="text"
                                id={`new_gruopName_${kind}`}
                                name={`new_groupName_${kind}`}
                                disabled={F[kind].newDict.word.length === 0}
                                className="input"
                                placeholder={`Group name`}
                                onChange={(e) =>
                                  F[kind].setNewDict({
                                    ...F[kind].newDict,
                                    groupName: e.target.value,
                                  })
                                }
                                value={F[kind].newDict.groupName}
                              ></input>
                            </td>
                          )}
                          {/* 単独チェックボックス（薬剤のみ）*/}
                          {kind === MEDICINE && (
                            <td
                              style={{
                                padding: 0,
                                width: "6em",
                                verticalAlign: "middle",
                              }}
                            >
                              <input
                                type="checkbox"
                                disabled={F[kind].newDict.word.length === 0}
                                key={`new_useSingle`}
                                checked={F[kind].newDict.useSingle}
                                onChange={(e) =>
                                  F[kind].setNewDict({
                                    ...F[kind].newDict,
                                    useSingle: e.target.checked,
                                  })
                                }
                              />
                            </td>
                          )}
                          {/* 背景色（薬剤のみ）*/}
                          {kind === MEDICINE && (
                            <td
                              style={{
                                width: "6em",
                                paddingTop: "0.35em",
                              }}
                            >
                              <span
                                key={`new_medicineBackColor`}
                                id={`new_medicineBackColor`}
                                onClick={showColorPickerNew}
                                className="medicineBackColor"
                                style={F[kind].newDict.bgColorStyle}
                              ></span>
                            </td>
                          )}
                          <td
                            style={{
                              padding: 0,
                              width: "4em",
                              verticalAlign: "middle",
                            }}
                          >
                            <button
                              disabled={
                                F[kind].newDict.word.length === 0 ||
                                checkDupNewDict(F[kind].newDict)
                              }
                              className="iconbutton"
                              onClick={(e) => {
                                saveButtonClicked(kind);
                              }}
                            >
                              <AiOutlineSave size={25} />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {colorDisplay ? (
                      <div style={popover}>
                        <div style={cover} onClick={onCloseMethod} />
                        <CompactPicker color={color} onChange={onChangeColor} />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Opendict;
