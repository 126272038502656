import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { DictKind, MEDICINE, TESTITEM } from "./define";
import {
  deleteDictFromIdb,
  getDictFromIdb,
  addDictToIdb,
  putDictToIdb,
} from "../components/IDB/IndexDb";

/**
 * 辞書の要素
 */
export interface Dict {
  /**
   * 名称
   */
  word: string;
  /**
   * 別名リスト
   */
  aliases: string[];
  /**
   * 薬剤群名称
   */
  groupName: string;
  /**
   * 登録時刻
   */
  save_date: string;
  /**
   * スイッチ対象単独薬剤
   */
  useSingle: boolean;
  /**
   * 連番Ï
   */
  No?: number;
  /**
   * 背景色
   */
  bgColorStyle: React.CSSProperties;
}

export interface DictObj {
  [key: string]: Dict;
}

export interface State {
  initialized: boolean;
  /**
   * 薬剤名辞書
   */
  MEDICINE: DictObj;

  /**
   * 検査名辞書
   */
  TESTITEM: DictObj;
}

export const initialState: State = {
  initialized: false,
  MEDICINE: {},
  TESTITEM: {},
};

export const loadDictFromIdbThunk = createAsyncThunk<{
  MEDICINE: Dict[];
  TESTITEM: Dict[];
}>("dict/loadDictFromIdbThunk", async () => {
  return {
    MEDICINE: await getDictFromIdb(MEDICINE),
    TESTITEM: await getDictFromIdb(TESTITEM),
  };
});

export const addDictToIdbThunk = createAsyncThunk<
  { dict: Dict; kind: DictKind },
  { dict: Dict; kind: DictKind }
>("dict/addDictToIdbThunk", async (params) => {
  await addDictToIdb(params.dict, params.kind);
  return {
    dict: params.dict,
    kind: params.kind,
  };
});

export const putDictToIdbThunk = createAsyncThunk<
  { dict: Dict; kind: DictKind },
  { dict: Dict; kind: DictKind }
>("dict/putDictToIdbThunk", async (params) => {
  await putDictToIdb(params.dict, params.kind);
  return {
    dict: params.dict,
    kind: params.kind,
  };
});

export const deleteDictFromIdbThunk = createAsyncThunk<
  { word: string; kind: DictKind },
  { word: string; kind: DictKind }
>("dict/deleteDictFromIdbThunk", async (params) => {
  await deleteDictFromIdb(params.word, params.kind);
  return { word: params.word, kind: params.kind };
});

export const dictSlice = createSlice({
  name: "dict",
  initialState,
  reducers: {
    addDictEntries: (
      state,
      action: PayloadAction<{ dicts: Dict[]; kind: DictKind }>
    ) => {
      action.payload.dicts.forEach((dict) => {
        state[action.payload.kind][dict.word] = dict;
      });
    },
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    deleteDictAll: (state, action: PayloadAction<void>) => {
      state.MEDICINE = {};
      state.TESTITEM = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDictFromIdbThunk.pending, (state, action) => {
      state.initialized = true;
    });
    builder.addCase(loadDictFromIdbThunk.fulfilled, (state, action) => {
      action.payload.MEDICINE.forEach((medicine) => {
        state.MEDICINE[medicine.word] = medicine;
      });
      action.payload.TESTITEM.forEach((testitem) => {
        state.TESTITEM[testitem.word] = testitem;
      });
    });
    builder.addCase(addDictToIdbThunk.fulfilled, (state, action) => {
      state[action.payload.kind][action.payload.dict.word] =
        action.payload.dict;
    });
    builder.addCase(putDictToIdbThunk.fulfilled, (state, action) => {
      state[action.payload.kind][action.payload.dict.word] =
        action.payload.dict;
    });
    builder.addCase(deleteDictFromIdbThunk.fulfilled, (state, action) => {
      delete state[action.payload.kind][action.payload.word];
    });
  },
});

// Exports actions
export const { addDictEntries, setInitialized, deleteDictAll } =
  dictSlice.actions;

// Exports selectors
export const selectInitialized = (state: RootState) => state.dict.initialized;
export const selectMedicineDict = (state: RootState) => state.dict.MEDICINE;
export const selectTestDict = (state: RootState) => state.dict.TESTITEM;

// Exports reducer
export default dictSlice.reducer;
