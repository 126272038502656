import dayjs from "dayjs";
import { MEDICINE_BGCOLOR_DEFAULT } from "../app/define";
import { Dict } from "../app/dictSlice";
import { z2h } from "../components/noteEditor/findingsTextParser";

export const checkDup = (
  dict: Dict,
  medicineList: Dict[],
  testitemList: Dict[]
) => {
  const trans = (str: string) => z2h(str);
  const words: string[] = [];
  words.push(trans(dict.word));
  words.push(...dict.aliases.map(trans));
  const comparator = (m: Dict) =>
    words.indexOf(trans(m.word)) !== -1 ||
    m.aliases.find((m) => words.indexOf(trans(m)) !== -1);
  return {
    findMedicine: medicineList.find(comparator),
    findTestitem: testitemList.find(comparator),
  };
};

export const emptyDict = (word: string, aliases?: string[]): Dict => ({
  word: word,
  aliases: aliases || [],
  groupName: "",
  useSingle: false,
  save_date: dayjs().format(),
  bgColorStyle: MEDICINE_BGCOLOR_DEFAULT,
});

export const checkReplaceMessage = (medicine?: Dict, testitem?: Dict) => {
  if (
    medicine &&
    !window.confirm(
      `薬剤辞書に同名の項目が存在します。\n　登録薬剤 : ${
        medicine.word
      }　薬剤別名 : ${medicine.aliases.join(
        ","
      )}\nを削除後登録を行います。よろしいですか？`
    )
  )
    return false;

  if (
    testitem &&
    !window.confirm(
      `検査辞書に同名の項目が存在します。\n　登録検査 : ${
        testitem.word
      }　検査別名 : ${(testitem.aliases || []).join(
        ","
      )}\nを削除後登録を行います。よろしいですか？`
    )
  )
    return false;

  return true;
};

export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);
