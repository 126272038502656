import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RawDraftContentState, EditorState } from "draft-js";
import { RootState } from "./store";
import chartGenerator from "../common/chartGenerator";
import { SizeCalc, SizeCalcCore } from "../components/drawChart/Charttypes";
import {
  addFindingsToIdb,
  Inputdata,
  putFindingsToIdb,
} from "../components/IDB/IndexDb";
import { ModalOpenClose } from "../pages/main";

/**
 * 臨床経過図記述
 */
export interface Box {
  from: string;
  to: string;
  isContinue: boolean;
  height: number;
  name: string;
  label: string;
  useSingle?: boolean;
  groupName: string;
  backgroundColor: string;
}
export interface Line {
  label: string;
  values: {
    time: string;
    value: number;
  }[][];
}
export interface Event {
  time: string;
  name: string;
}

export interface Chart {
  time_range: {
    from: string;
    to: string;
  };
  stack_graph: {
    boxes: Box[];
  };
  line_graph: {
    lines: Line[];
  };
  event_graph: {
    events: Event[];
  };
  error_message: string[];
}

/**
 * 所見サマリエディタのRawData
 */
export type FindingsEditorRawData = RawDraftContentState;
export type FindingsEditorState = EditorState;
export type FindingsEditorSetState = Function;

/**
 * 所見サマリ解析データ
 */
export interface PMedicine {
  /**
   * 薬剤名
   */
  name: string;

  /**
   * 表示ラベル
   */
  label: string;

  /**
   * 処方量
   */
  amount?: string;

  /**
   * 間隔
   */
  interval?: string;

  /**
   * 変換レート
   */
  convertRate?: number;

  /**
   * 代表名称
   */
  unitedName: string;

  /**
   * スイッチ薬剤
   */
  useSingle?: boolean;

  /**
   * グループ名称
   */
  groupName: string;

  /**
   * 背景色
   */
  backgroundColor: string;
}

export interface PTest {
  /**
   * 検査名
   */
  name: string;

  /**
   * 表示ラベル
   */
  label: string;

  /**
   * 検査結果
   */
  value?: string[];
  valueMin?: string[];

  /**
   * 単位
   */
  unit?: string;

  /**
   * 代表名称
   */
  unitedName: string;
}

export interface PEvent {
  /**
   * イベント表示ラベル
   */
  label: string;
}

export interface PBlock {
  /**
   * ブロックで認識されたタイムスタンプ
   */
  time: string[];

  /**
   * ブロックで認識された終了タイムスタンプ
   */
  timeUntil: string[];

  /**
   * このブロックのtext。継続行は改行文字で連結されている
   */
  text: string;

  /**
   * 検出された薬剤情報の配列
   */
  medicines: PMedicine[][];

  /**
   * 検出された検査情報の配列
   */
  tests: PTest[][];

  /**
   * 検出されたイベント情報の配列
   */
  events: PEvent[][];

  /**
   * 各行のエラーメッセージ
   */
  errormessage?: string[];
}

export interface ParsedData {
  /**
   * Draft blockのkeyをキーとして、該当するブロックの解析済みデータを値とするオブジェクト
   */
  [key: string]: PBlock;
}

/**
 * 所見サマリテキストstate
 */
export interface FindingsText {
  /**
   * 所見サマリNo
   */
  No: string;

  /**
   * 所見サマリタイトル
   */
  title: string;

  /**
   * メモ
   */
  memo: string;

  /**
   * 所見サマリエディタのRawData
   */
  text?: FindingsEditorRawData | null;

  /**
   * text blockごとの解析済みデータ
   */
  parsed?: ParsedData | null;

  /**
   * 保存日時 (ISO8601)
   */
  saveTime?: string;
}

export interface CheckLine {
  setNo: number;
  labelname: string;
  flg: boolean;
}

export interface CheckLineSetNo {
  index: number;
  setNo: number;
}

export interface CheckListFlg {
  index: number;
  flg: boolean;
}
export interface State {
  /**
   * 所見サマリテキストデータ
   */
  findingsText: FindingsText | null;

  /**
   * 臨床経過図記述
   */
  chartData: Chart | null;

  /**
   * 臨床経過図サイズ
   */
  chartSize: SizeCalc;

  /**
   * 検査結果チェック
   */
  checkLines: CheckLine[];
}

export const initialState: State = {
  findingsText: null,
  chartData: null,
  chartSize: {
    screenWidth: -1,
    width: -1,
    height: -1,
    contentWidth: -1,
    contentHeight: -1,
    zoomRate: 1,
  },
  checkLines: [],
};

export const addFindingsToIdbThunk = createAsyncThunk<FindingsText, Inputdata>(
  "app/addFindingsToIdbThunk",
  async (checkdata) => {
    const newFindingsText = await addFindingsToIdb(checkdata);
    return newFindingsText;
  }
);

export const putFindingsToIdbThunk = createAsyncThunk<FindingsText, Inputdata>(
  "app/putFindingsToIdbThunk",
  async (checkdata) => {
    const newFindingsText = await putFindingsToIdb(checkdata);
    return newFindingsText;
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateText: (
      state,
      action: PayloadAction<FindingsEditorRawData | null>
    ) => {
      const findingsText: FindingsText = state.findingsText || {
        No: "",
        title: "<Untitled>",
        memo: "",
      };
      state.findingsText = {
        ...findingsText,
        text: action.payload,
      };
    },
    updateParsed: (state, action: PayloadAction<ParsedData | null>) => {
      // chartDataを生成する
      const findingsText: FindingsText = state.findingsText || {
        No: "",
        title: "<Untitled>",
        memo: "",
      };
      const updatingFindingsText: FindingsText = {
        ...findingsText,
        parsed: action.payload,
      };
      if (action.payload === null) {
        state.chartSize.zoomRate = 1;
      }
      let cc = state.chartData;
      // Note: 以下の行をコメントアウトすればchartDataは更新されなくなる
      cc = chartGenerator.generate(updatingFindingsText);
      state.chartData = cc;
      state.findingsText = updatingFindingsText;
    },
    updateFindingsText: (state, action: PayloadAction<FindingsText | null>) => {
      state.findingsText = action.payload;
    },
    setChartSize: (state, action: PayloadAction<SizeCalcCore>) => {
      state.chartSize.contentHeight = action.payload.contentHeight;
      state.chartSize.contentWidth = action.payload.contentWidth;
      state.chartSize.zoomRate = action.payload.zoomRate;
      state.chartSize.screenWidth = action.payload.contentWidth - 12 * 2;
      state.chartSize.width =
        action.payload.contentWidth * action.payload.zoomRate - 12 * 2;
      state.chartSize.height = action.payload.contentHeight;
    },
    setCheckLineFlg: (state, action: PayloadAction<CheckListFlg>) => {
      let checkLine = state.checkLines.find(
        (checkLine) => checkLine.setNo === action.payload.index
      );
      if (checkLine) checkLine.flg = action.payload.flg;
    },
    mergeCheckLines: (state, action: PayloadAction<CheckLine[]>) => {
      action.payload.forEach((y, i, arr) => {
        const index = state.checkLines.findIndex(
          (e) => e.labelname === y.labelname
        );
        if (index === -1) {
          //新しく追加された時
          state.checkLines.push({
            setNo: y.setNo,
            labelname: y.labelname,
            flg: true,
          });
        } else {
          //既にセット済みの時
          if (index !== -1) {
            state.checkLines[index].setNo = i;
          }
        }
      });
      //削除処理を行う
      state.checkLines.forEach((x, i, arr) => {
        //新しく追加された時
        if (
          action.payload.findIndex((e) => e.labelname === x.labelname) === -1
        ) {
          let newChk = [...state.checkLines];
          newChk.splice(i, 1);
          state.checkLines = newChk;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addFindingsToIdbThunk.fulfilled, (state, action) => {
      state.findingsText = action.payload;
      setTimeout(ModalOpenClose("updatesuccess"), 0);
    });
    builder.addCase(putFindingsToIdbThunk.fulfilled, (state, action) => {
      state.findingsText = action.payload;
      setTimeout(ModalOpenClose("updatesuccess"), 0);
    });
  },
});

// Exports actions
export const {
  updateFindingsText,
  updateParsed,
  updateText,
  setChartSize,
  setCheckLineFlg,
  mergeCheckLines,
} = appSlice.actions;

// Exports selectors
export const selectFindingsText = (state: RootState) => state.app.findingsText;
export const selectChartData = (state: RootState) => state.app.chartData;
export const selectChartSize = (state: RootState) => state.app.chartSize;
export const selectCheckLines = (state: RootState) => state.app.checkLines;

// Exports reducer
export default appSlice.reducer;
