import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  updateFindingsText,
  updateParsed,
  selectFindingsText,
  putFindingsToIdbThunk,
  selectChartData,
} from "../app/appSlice";
import { loadDictFromIdbThunk, selectInitialized } from "../app/dictSlice";
import GraphArea from "../components/drawChart/drawMain";
import { NoteEditor } from "../components/noteEditor/NoteEditor";
import FindingsTextCopy from "../pages/textCopy";
import generateCSV from "./generateCSV";
import dayjs from "dayjs";
import lodash from "lodash";
import "bulma/css/bulma.min.css";
import "./mystyle.scss";
import { Inputdata, openDB } from "../components/IDB/IndexDb";
import { exportDB } from "dexie-export-import";

export function ModalOpenClose(elementId: string) {
  return ModalOpenCloseCore;
  function ModalOpenCloseCore() {
    function clickAction() {
      d!.classList.remove("is-active");
      d!.classList.remove("fade");
      d!.style.removeProperty("display");
    }
    function fadeOut() {
      d!.classList.toggle("fade");
      setTimeout(clickAction, 500);
    }
    const d = document.getElementById(elementId);
    if (!d) {
      console.error("updatesuccess node is not found.");
      return;
    }
    if (d!.className === "modal") {
      d!.style.display = "table-header-group";
      d!.classList.add("is-active");
    }
    setTimeout(fadeOut, 500);
    return;
  }
}

export function isMobile() {
  return (window as any).flutter_inappwebview;
}

declare global {
  interface Window {
    doc2chartFunc: {
      ClickNewText: Function;
      ChangeScreenOpen: Function;
      ChangeScreenDict: Function;
      ChangeScreenStat: Function;
      ChangeScreenManage: Function;
      ChangeScreenSave: Function;
      ClickUpdateTextData: Function;
      ClickFindingsTextCopy: Function;
      ClickGenerateCSV: Function;
      ClickDownloadSVG: Function;
      ClickDownloadBundle: Function;
      DisplayElement: Function;
      ImportBundle: Function;
    };
  }
}
const Main = () => {
  const dispatch = useAppDispatch();
  const findingsText = useAppSelector(selectFindingsText);
  const chartData = useAppSelector(selectChartData);
  const initialized = useAppSelector(selectInitialized);
  const history = useHistory();
  if (initialized === false) {
    dispatch(loadDictFromIdbThunk());
  }

  function ClickNewText() {
    dispatch(
      updateFindingsText({ No: "", title: "<Untitled>", memo: "", text: null })
    );
    dispatch(updateParsed(null));
  }

  function ChangeScreen(url: string) {
    history.push(url);
  }

  function ClickUpdateTextData() {
    const ft = lodash.cloneDeep(findingsText);
    if (ft === null) {
      return;
    }
    const checkdata: Inputdata = {
      No: Number(ft.No),
      title: ft.title,
      text: ft,
      memo: ft.memo,
      save_date: dayjs().format("YYYYMMDDTHHmmss+0900"),
      create_date: dayjs().format("YYYYMMDDTHHmmss+0900"),
      chartData: chartData,
    };
    dispatch(putFindingsToIdbThunk(checkdata));
  }

  function ClickFindingsTextCopy() {
    return FindingsTextCopy(findingsText!);
  }

  function ClickGenerateCSV() {
    return generateCSV(chartData);
  }

  function DisplayElement(id: string, bShow: boolean) {
    const div = document.getElementById(id);
    if (div?.style) {
      div.style.display = bShow ? "" : "none";
    }
  }
  const download = (blob: Blob) => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      var dataURI = this.result?.toString() || "";
      const link = document.createElement("a");
      link.href = dataURI;
      const filename = `doc2chart.${dayjs().format("YYYYMMDDHHmmss")}.d2c`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    fileReader.readAsDataURL(blob);
  };

  window.doc2chartFunc = {
    ClickNewText: () => {
      ClickNewText();
    },
    ChangeScreenOpen: () => {
      ChangeScreen("/open");
    },
    ChangeScreenDict: () => {
      ChangeScreen("/dict");
    },
    ChangeScreenStat: () => {
      ChangeScreen("/stat");
    },
    ChangeScreenManage: () => {
      ChangeScreen("/manage");
    },
    ChangeScreenSave: () => {
      ChangeScreen("/save");
    },
    ClickUpdateTextData: () => {
      ClickUpdateTextData();
    },
    ClickFindingsTextCopy: () => {
      ClickFindingsTextCopy();
    },
    ClickGenerateCSV: () => {
      ClickGenerateCSV();
    },
    ClickDownloadSVG: () => {},
    ClickDownloadBundle: () => {
      const func = async () => {
        const db = await openDB();
        const blob = await exportDB(db);
        download(blob);
        db.close();
      };
      func();
    },
    DisplayElement: (id: string, bShow: boolean) => {
      DisplayElement(id, bShow);
    },
    ImportBundle: (json: string, filename: string) => {},
  };

  return (
    <>
      <main>
        <div id="noteEditor" className="columns is-marginless is-mobile">
          <NoteEditor />
        </div>
        <div id="graphArea">
          <GraphArea />
        </div>

        <div className="modal" id="updatesuccess">
          <div
            className="modal-content"
            style={{
              fontSize: 12,
              height: 100,
              width: 100,
            }}
          >
            <div className="box has-text-centered has-text-weight-semibold">
              保存完了
            </div>
          </div>
        </div>

        <div className="modal" id="copysuccess">
          <div
            className="modal-content"
            style={{
              fontSize: 12,
              height: 100,
              width: 100,
            }}
          >
            <div className="box has-text-centered has-text-weight-semibold">
              コピー完了
            </div>
          </div>
        </div>

        <div className="modal" id="svggenerated">
          <div
            className="modal-content"
            style={{
              fontSize: 12,
              height: 100,
              width: 300,
            }}
          >
            <div className="box has-text-centered has-text-weight-semibold">
              ファイルアプリへのSVG出力が完了しました。
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Main;
