import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { Bar, Line } from "react-chartjs-2";
import { getTab10Color75 } from "../components/drawChart/drawMain";
import { getFindingsFromIdb } from "../components/IDB/IndexDb";
import { Outputdata } from "../components/IDB/IndexDb";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  ChartjsPluginStacked100,
  Title,
  Tooltip,
  Legend
);

const barOptions = {
  plugins: {
    stacked100: { enable: true },
    title: {
      display: true,
      text: "疾患活動性の変化",
    },
  },
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const lineOptions = {
  scales: {
    yAxis: {
      min: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "薬剤使用頻度の変化",
    },
  },
};

interface BarData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    stack: string;
  }[];
}
interface LineData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }[];
}

const Stat = () => {
  const [barData, setBarData] = useState<BarData>({
    labels: [],
    datasets: [],
  });
  const [lineData, setLineData] = useState<LineData>({
    labels: [],
    datasets: [],
  });
  const groupDas28Crp = (findings: Outputdata[]) => {
    return findings.reduce((memo, finding) => {
      const lines = finding.chartData?.line_graph.lines || [];
      const das28crps = lines.find((f) => f.label === "DAS28CRP");
      if (!das28crps) return memo;
      const das28crpByYear = das28crps.values[0].reduce((memo, das28crp) => {
        const year = das28crp.time.slice(0, 4);
        const value = das28crp.value;
        if (memo[year]) {
          if (memo[year] < value) memo[year] = value;
        } else {
          memo[year] = value;
        }
        return memo;
      }, {} as { [year: string]: number });
      for (const [year, value] of Object.entries(das28crpByYear)) {
        const stage =
          value < 2.3
            ? "寛解"
            : value < 2.7
            ? "低疾患活動性"
            : value <= 4.1
            ? "中等度疾患活動性"
            : "高疾患活動性";
        if (!memo[year])
          memo[year] = {
            寛解: 0,
            低疾患活動性: 0,
            中等度疾患活動性: 0,
            高疾患活動性: 0,
          };
        memo[year][stage]++;
      }
      return memo;
    }, {} as { [year: string]: { 寛解: number; 低疾患活動性: number; 中等度疾患活動性: number; 高疾患活動性: number } });
  };

  const groupMedicines = (findings: Outputdata[]) => {
    const template = findings.reduce((memo, finding) => {
      const boxes = finding.chartData?.stack_graph.boxes || [];
      return boxes.reduce((memo, box) => {
        if (!memo[box.groupName]) memo[box.groupName] = 0;
        return memo;
      }, memo);
    }, {} as { [group: string]: number });
    return findings.reduce((memo, finding) => {
      const boxes = finding.chartData?.stack_graph.boxes || [];
      const medicineByYear = boxes.reduce((memo, box) => {
        if (box.height === 0) return memo;
        const from = parseInt(box.from.slice(0, 4));
        const to = parseInt(dayjs(box.to).add(-1, "day").format("YYYY"));
        const group = box.groupName;
        for (let year = from; year <= to; year++) {
          if (!memo[year.toString()]) memo[year.toString()] = {};
          if (!memo[year.toString()][group]) memo[year.toString()][group] = 1;
        }
        return memo;
      }, {} as { [year: string]: { [group: string]: number } });
      for (const [year, value] of Object.entries(medicineByYear)) {
        for (const [group] of Object.entries(value)) {
          if (!memo[year]) memo[year] = { ...template };
          if (!memo[year][group]) memo[year][group] = 0;
          memo[year][group]++;
        }
      }
      return memo;
    }, {} as { [year: string]: { [group: string]: number } });
  };

  const rotate = (byYear: { [year: string]: { [group: string]: number } }) => {
    return Object.entries(byYear)
      .sort((a, b) => (a[0] > b[0] ? 1 : -1))
      .reduce((memo, year) => {
        const groups = year[1];
        memo = Object.entries(groups).reduce((memo, group) => {
          const name = group[0];
          const count = group[1];
          if (!memo[name]) memo[name] = [];
          memo[name].push(count);
          return memo;
        }, memo);
        return memo;
      }, {} as { [key: string]: number[] });
  };

  useEffect(() => {
    (async () => {
      const findings = await getFindingsFromIdb();
      const das28CrpStat = groupDas28Crp(findings);
      const medicineStat = groupMedicines(findings);
      const years = Object.keys(medicineStat).sort();
      const das28Crp = rotate(das28CrpStat);
      const medicine = rotate(medicineStat);
      setBarData({
        labels: years,
        datasets: Object.entries(das28Crp).map((m, index) => ({
          label: m[0],
          data: m[1],
          backgroundColor: getTab10Color75(index),
          stack: "Stack 0",
        })),
      });
      setLineData({
        labels: years,
        datasets: Object.entries(medicine).map((m, index) => ({
          label: m[0],
          data: m[1],
          borderColor: getTab10Color75(index),
          backgroundColor: getTab10Color75(index),
        })),
      });
    })();
  }, []);

  return (
    <>
      <main>
        <nav
          className="navbar is-light is-mobile"
          role="navigation"
          aria-label="main navigation mobile"
          style={{ minWidth: 748 }}
        >
          <div id="menubar" style={{ height: "70px" }}>
            <div className="navbar-start " id="navbar-size">
              <p className="navbar-item is-size-7 has-text-centered">
                <Link to="/">
                  <AiOutlineArrowLeft size={30} />
                  <br />
                  戻る
                </Link>
              </p>
            </div>
          </div>
        </nav>
        <div
          className="columns is-marginless is-flex-tablet-only"
          style={{ minWidth: 748 }}
        >
          <div className="column">
            <div className="box table-container ">
              <div className="rows">
                <div className="row">
                  <Bar options={barOptions} data={barData} />
                </div>
                <div className="row">
                  <Line options={lineOptions} data={lineData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Stat;
